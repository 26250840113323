import React, { useEffect } from 'react';
import { ProfileForm } from '../components/Auth/ProfileForm';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from '../components/Auth/AuthLayout';
import * as Store from '../store';
const Login: React.FC = () => {
  const dispatch = Store.useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLoginSuccess = (values: { email: string; password: string }) => {
    dispatch(Store.auth.login({ email: values.email, password: values.password })).then(() => {
      navigate('/dashboard');
    });
  };

  useEffect(() => {
    return () => {
      const dataVersion = localStorage.getItem('dataVersion');
      if (dataVersion === null || dataVersion !== '4') {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('dataVersion', '4');
        window.location.reload();
      }
    };
  }, []);

  return (
    <AuthLayout>
      <h2 className="text-center text-3xl font-extrabold text-gray-900">{t('auth.login.title')}</h2>
      <ProfileForm onSubmitSuccess={handleLoginSuccess} isLoginMode={true} />
      <div className="mt-4 text-center">
        <p className="text-sm text-gray-600">
          {t('auth.login.noAccount')}{' '}
          <Link to="/register" className="font-medium text-orange-500 hover:text-orange-600">
            {t('auth.login.registerLink')}
          </Link>
        </p>
      </div>
    </AuthLayout>
  );
};

export default Login;
