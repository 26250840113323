import React from 'react';
import { Copy } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

interface CopiableCellProps {
  value: string;
}

export const CopiableCell: React.FC<CopiableCellProps> = ({ value }) => {
  const { t } = useTranslation();

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    toast.success(t('common.copied'));
  };

  return (
    <div className="flex items-center gap-2">
      <span>{value}</span>
      <button onClick={handleCopy} className="text-gray-400 hover:text-gray-600">
        <Copy size={14} />
      </button>
    </div>
  );
};
