import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';
import { TUser, User } from '../../types/user';
import * as apiClient from '../../apiClient';
import { clearToken, setToken } from '../../services/token.service';
import toast from 'react-hot-toast';

export const reducerName = 'auth';

const authSlice = createSlice({
  name: reducerName,
  initialState: {
    isAuthenticated: false,
    user: null as TUser | null,
    token: null as string | null,
    isLoading: false,
  },
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      clearToken();
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      setToken(action.payload.token);
    },
    reset: () => ({
      isAuthenticated: false,
      user: null,
      token: null,
      isLoading: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      console.log('login.fulfilled', action.payload);
      state.user = action.payload.data;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.isLoading = false;
      setToken(action.payload.token);
    });
    builder.addCase(login.rejected, (state, action) => {
      console.log('login.rejected', action.error.message);
      toast.error(action.error.message as string);
      state.isLoading = false;
    });
    builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload.data as TUser;
      state.isLoading = false;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = { ...state.user, ...action.payload };
    });
  },
});

export const login = createAsyncThunk(
  'login',
  async (user: { email: string; password: string }) => {
    const response = await apiClient.auth.login(user);
    return response;
  }
);

export const register = createAsyncThunk(
  'register',
  async (user: { email: string; password: string }) => {
    const response = await apiClient.auth.signup(user);
    return response;
  }
);

export const fetchCurrentUser = createAsyncThunk('fetchCurrentUser', async () => {
  const response = await apiClient.auth.getCurrentUser();
  return response;
});

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async ({ userId, userData }: { userId: string; userData: Partial<User> }) => {
    const response = await apiClient.auth.updateUser(userId, userData);
    return response.data;
  }
);

export const selectAuth = (state: RootState) => state.auth;

export const selectUser = createSelector([selectAuth], (auth) => auth?.user);

export const selectToken = createSelector([selectAuth], (auth) => auth?.token);

export const selectIsAuthenticated = createSelector([selectAuth], (auth) => auth?.isAuthenticated);

export const { logout, reset, setUser } = authSlice.actions;
export const reducer = authSlice.reducer;
