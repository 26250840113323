import { z } from 'zod';

export const SupplierStatus = {
  ACTIVE: 'ACTIVE',
  PROCESSING: 'PROCESSING',
  INACTIVE: 'INACTIVE',
  WAITING: 'WAITING',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
} as const;

export const SupplierType = {
  SUPPLIER: 'supplier',
  PROFESSIONAL: 'professional',
} as const;

export const supplierSchema = z.object({
  _id: z.string(),
  taskId: z.string().optional(),
  affilizId: z.string().optional(),
  name: z.string(),
  country: z.enum(['FR', 'DE', 'IT', 'ES', 'UK']),
  type: z.enum([SupplierType.SUPPLIER, SupplierType.PROFESSIONAL]),
  numberOfProducts: z.number(),
  status: z.enum([
    SupplierStatus.ACTIVE,
    SupplierStatus.PROCESSING,
    SupplierStatus.INACTIVE,
    SupplierStatus.WAITING,
    SupplierStatus.FINISHED,
    SupplierStatus.ERROR,
  ]),
  octoParseStatus: z.string().optional(),
  lastUpdateDate: z.date(),
  lastOctoParseStatusUpdate: z.date().optional(),
  outDateInterval: z.number().optional(),
  hasAffiliation: z.boolean(),
  isActive: z.boolean(),
});

export type Supplier = z.infer<typeof supplierSchema>;

export const COUNTRY_FLAGS = {
  DE: '🇩🇪',
  AT: '🇦🇹',
  BE: '🇧🇪',
  BG: '🇧🇬',
  CY: '🇨🇾',
  HR: '🇭🇷',
  DK: '🇩🇰',
  ES: '🇪🇸',
  EE: '🇪🇪',
  FI: '🇫🇮',
  FR: '🇫🇷',
  GR: '🇬🇷',
  HU: '🇭🇺',
  IE: '🇮🇪',
  IT: '🇮🇹',
  LV: '🇱🇻',
  LT: '🇱🇹',
  LU: '🇱🇺',
  MT: '🇲🇹',
  NL: '🇳🇱',
  PL: '🇵🇱',
  PT: '🇵🇹',
  CZ: '🇨🇿',
  RO: '🇷🇴',
  UK: '🇬🇧',
  SK: '🇸🇰',
  SI: '🇸🇮',
  SE: '🇸🇪',
  CH: '🇨🇭',
};

export const COUNTRY_NAMES = {
  DE: 'Allemagne',
  AT: 'Autriche',
  BE: 'Belgique',
  BG: 'Bulgarie',
  CY: 'Chypre',
  HR: 'Croatie',
  DK: 'Danemark',
  ES: 'Espagne',
  EE: 'Estonie',
  FI: 'Finlande',
  FR: 'France',
  GR: 'Grèce',
  HU: 'Hongrie',
  IE: 'Irlande',
  IT: 'Italie',
  LV: 'Lettonie',
  LT: 'Lituanie',
  LU: 'Luxembourg',
  MT: 'Malte',
  NL: 'Pays-Bas',
  PL: 'Pologne',
  PT: 'Portugal',
  CZ: 'République Tchèque',
  RO: 'Roumanie',
  UK: 'Royaume-Uni',
  SK: 'Slovaquie',
  SI: 'Slovénie',
  SE: 'Suède',
  CH: 'Suisse',
};

export const STATUS_CLASSNAMES: Record<string, string> = {
  ACTIVE: 'bg-green-100 text-green-800',
  PROCESSING: 'bg-yellow-100 text-yellow-800',
  INACTIVE: 'bg-red-100 text-red-800',
  WAITING: 'bg-blue-100 text-blue-800',
  FINISHED: 'bg-purple-100 text-purple-800',
  ERROR: 'bg-gray-100 text-gray-800',
};
