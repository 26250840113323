import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import {  MapPin } from 'lucide-react';

import { MarketplaceSelector } from '../Marketplace/MarketplaceSelector';


export const MarketplaceTooltip: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState<{ top: number; left: number } | null>(null);

  const handleMouseEnter = (e: React.MouseEvent) => {
    const { top, left, width } = e.currentTarget.getBoundingClientRect();
    setPosition({ top: top + window.scrollY, left: left + window.scrollX + width / 2 });
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MapPin size={20} className="ml-2" />
      {isOpen && position &&
        ReactDOM.createPortal(
          <div
            className="rounded-lg bg-white p-3 text-black shadow-lg"
            style={{
              position: 'fixed',
              top: `${position.top + 20}px`,
              left: `${position.left}px`,
              zIndex: 9999,
              whiteSpace: 'nowrap',
               minWidth: '300px'
            }}
          >
            <MarketplaceSelector />
          </div>,
          document.body
        )}
    </div>
  );
};