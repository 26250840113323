import React from 'react';
import { ProductCount} from './ProductCount'
interface PaginationProps {
  meta: {
    total: number;        // Nombre total de produits
    perPage: number;      // Nombre d'éléments par page
    currentPage: number;  // Page actuelle
    lastPage: number;     // Dernière page
    hasNextPage: boolean;
    hasPrevPage: boolean;
  };

  //Fonction de rappel pour changer la page.
  onPageChange: (page: number) => void;
  isLoading: boolean;
}

// C'est  les informations de pagination provenant de l'API,
export const TablePagination: React.FC<PaginationProps> = ({
  meta,
  onPageChange,
  isLoading,
}) => {
  const { total, perPage, currentPage, lastPage, hasNextPage, hasPrevPage } = meta;

  // Calcul des indices de début et de fin
  const startIndex = (currentPage - 1) * perPage + 1;
  const endIndex = Math.min(currentPage * perPage, total);

  // Fonction pour gérer le changement de page ici 
  const handlePageClick = async (page: number) => {
    if (page === currentPage || isLoading) return;
    onPageChange(page);
  };

  // Fonction pour gérer le précédent et suivant aussi 
  const handlePrevNext = async (direction: 'prev' | 'next') => {
    const newPage = direction === 'next' ? currentPage + 1 : currentPage - 1;
    if (newPage < 1 || newPage > lastPage) return;
    handlePageClick(newPage);
  };

  // Fonction pour afficher les pages avec des ellipses(logique de rendu des numéros de pages )
  const renderPageNumbers = () => {
    const delta = 2;
    const range: number[] = [];
    const rangeWithDots: (number | string)[] = [];

    for (let i = Math.max(1, currentPage - delta); i <= Math.min(lastPage, currentPage + delta); i++) {
      range.push(i);
    }

    // Ajoutons des points de suspension si nécessaire
    let previousPage: number | undefined;
    for (const page of range) {
      if (previousPage && page - previousPage > 1) {
        rangeWithDots.push('...');
      }
      rangeWithDots.push(page);
      previousPage = page;
    }

    return rangeWithDots.map((page, index) => (
      page === '...' ? (
        <span key={index} className="text-gray-500">...</span>
      ) : (
        <button
          key={page}
          onClick={() => handlePageClick(page as number)}
          className={`px-4 py-2 rounded-md border ${
            page === currentPage ? 'bg-orange-500 text-white' : 'bg-white text-black-500'
          }`}
        >
          {page}
        </button>
      )
    ));
  };

  return (
    <div className="flex items-center justify-between px-4 py-3 border-t bg-white">
      {/* Section info */}
      <ProductCount  
      total = {total} 
      endIndex = {endIndex} 
      startIndex =  {startIndex}
      currentPage = { currentPage}
      
      />

      {/* Navigation */}
      <div className="flex items-center gap-2">
        {/* Bouton précédent */}
        <button
          onClick={() => handlePrevNext('prev')}
          disabled={currentPage < 1 || isLoading}
          className={`px-4 py-2 ${hasPrevPage ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-500'} rounded-md ${currentPage === 1 || isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Précédent
        </button>

        {/* Liste des pages */}
        <div className="flex gap-2">
          {renderPageNumbers()}
        </div>

        {/* Bouton suivant */}
        <button
          onClick={() => handlePrevNext('next')}
          disabled={currentPage === lastPage || isLoading}
          className={`px-4 py-2 ${hasNextPage ? 'bg-orange-500 text-white' : 'bg-gray-200 text-gray-500'} rounded-md ${currentPage === lastPage || isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Suivant
        </button>
      </div>
    </div>
  );
};


