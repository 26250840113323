import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBar } from './SearchBar';
import { ProductList } from '../ProductList/ProductList';
import { PageLayout } from '../common/PageLayout';
import { PageHeader } from '../common/PageHeader';
import * as apiClient from '../../apiClient';
import { toast } from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { formatFilterStateToSearchConditions } from '../../utils/filter';
import * as Store from '../../store';

export const ReverseSearch: React.FC = () => {
  const { t } = useTranslation();
  const [conditions, setConditions] = useState<Record<string, unknown>>({});
  const selectedMarketPlaces = Store.useAppSelector(
    (state) => state.userSettings.selectedMarketplaces
  );
  const [searchParams] = useSearchParams();
  const asin = searchParams.get('asin');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    docs: [],
    meta: {
      hasNextPage: false,
      hasPrevPage: false,
      limit: 0,
      nextPage: 0,
      page: 0,
      pagingCounter: 0,
      prevPage: 0,
      totalDocs: 0,
      totalPages: 0,
    },
  });

  const handleSubmit = (values: Record<string, unknown>) => {
    setConditions(values);
    paginateSearch(1, values);
  };

  const paginateSearch = useCallback(
    async (page: number, poConditions?: Record<string, unknown>) => {
      if (!conditions && !poConditions) return;
      setLoading(true);
      try {
        const res = await apiClient.products.findByConditions('search', {
          conditions: poConditions || conditions,
          paginationOption: { page },
          group: {
            _id: '$asin',
            profit: { $first: '$profit' }, // Preserve the first profit value
            lastUpdateDate: { $first: '$lastUpdateDate' }, // Preserve the first last update date
          },
          sort: {
            profit: -1,
            lastUpdateDate: -1,
          },
        });
        setData(res.data);
      } catch (err: unknown) {
        toast.error(t('error.somethingWentWrong') + ': ' + (err as Error).message);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    if (asin) {
      const cond = formatFilterStateToSearchConditions({
        state: {
          asinList: [asin],
          amazonCountry: selectedMarketPlaces,
        },
      });
      setConditions(cond);
      paginateSearch(1, cond);
    }
  }, [asin, paginateSearch, selectedMarketPlaces]);
  return (
    <PageLayout
      header={
        <PageHeader title={t('search.title')} description={t('search.description')}>
          <SearchBar onSubmit={handleSubmit} />
        </PageHeader>
      }
    >
      <ProductList docs={data.docs} meta={data.meta} conditions={conditions} isLoading={loading} />
    </PageLayout>
  );
};
