import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/UserContext';
import { format } from 'date-fns';

export const ProfileForm: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  if (!user) return null;

  return (
    <div className="mx-auto max-w-4xl">
      <div className="rounded-lg bg-white shadow-sm">
        <div className="border-b border-gray-100 p-6">
          <h1 className="text-2xl font-semibold">{t('user.profile')}</h1>
          <p className="text-gray-600">{t('user.profileDescription')}</p>
        </div>

        <div className="space-y-8 p-6">
          {/* Personal Information */}
          <section>
            <h2 className="mb-4 text-lg font-medium">{t('user.personalInfo')}</h2>
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.firstName')}
                </label>
                <input
                  type="text"
                  defaultValue={user.firstName}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
              </div>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.lastName')}
                </label>
                <input
                  type="text"
                  defaultValue={user.lastName}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
              </div>
              <div className="col-span-2">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.email')}
                </label>
                <input
                  type="email"
                  defaultValue={user.email}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
              </div>
            </div>
          </section>

          {/* Company Details */}
          <section>
            <h2 className="mb-4 text-lg font-medium">{t('user.companyInfo')}</h2>
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.companyName')}
                </label>
                <input
                  type="text"
                  defaultValue={user.companyName}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
              </div>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.companyId')}
                </label>
                <input
                  type="text"
                  defaultValue={user.companyId}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
              </div>
            </div>
          </section>

          {/* Contact Information */}
          <section>
            <h2 className="mb-4 text-lg font-medium">{t('user.contactInfo')}</h2>
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.phoneNumber')}
                </label>
                <input
                  type="tel"
                  defaultValue={user.phoneNumber}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
              </div>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.country')}
                </label>
                <input
                  type="text"
                  defaultValue={user.country}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
              </div>
              <div className="col-span-2">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.address')}
                </label>
                <input
                  type="text"
                  defaultValue={user.address}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
              </div>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.city')}
                </label>
                <input
                  type="text"
                  defaultValue={user.city}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
              </div>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.zipCode')}
                </label>
                <input
                  type="text"
                  defaultValue={user.zipCode}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
              </div>
            </div>
          </section>

          {/* Subscription Details */}
          <section>
            <h2 className="mb-4 text-lg font-medium">{t('user.subscriptionInfo')}</h2>
            <div className="grid grid-cols-2 gap-6">
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.subscriptionPlan')}
                </label>
                <input
                  type="text"
                  value={user.plan}
                  disabled
                  className="w-full rounded-lg border border-gray-300 bg-gray-50 px-3 py-2"
                />
              </div>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.subscriptionStatus')}
                </label>
                <input
                  type="text"
                  disabled
                  value={user.planStatus}
                  readOnly
                  className="w-full rounded-lg border border-gray-300 bg-gray-50 px-3 py-2"
                />
              </div>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.subscriptionStart')}
                </label>
                <input
                  type="text"
                  disabled
                  value={format(user.planStartAt, 'PP')}
                  readOnly
                  className="w-full rounded-lg border border-gray-300 bg-gray-50 px-3 py-2"
                />
              </div>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('user.subscriptionEnd')}
                </label>
                <input
                  type="text"
                  disabled
                  value={format(user.planEndAt, 'PP')}
                  readOnly
                  className="w-full rounded-lg border border-gray-300 bg-gray-50 px-3 py-2"
                />
              </div>
            </div>
          </section>
        </div>

        <div className="flex justify-end gap-3 border-t border-gray-100 bg-gray-50 p-6">
          <button className="rounded-lg px-4 py-2 text-gray-600 hover:bg-gray-100">
            {t('common.cancel')}
          </button>
          <button className="rounded-lg bg-orange-500 px-4 py-2 text-white hover:bg-orange-600">
            {t('common.save')}
          </button>
        </div>
      </div>
    </div>
  );
};
