import React, { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { User } from '../../types/user';
import styles from './AccountPage.module.css';
import { PersonalInformation } from './Profile/PersonalInformation';
import { SubscriptionManagement } from './Subscription/SubscriptionManagement';
import { InvoicesList } from './Billing/InvoicesList';
import * as Store from '../../store';
import { useTranslation } from 'react-i18next';
const AccountPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const { currentUser } = useOutletContext<{
    currentUser: User;
  }>();
  const dispatch = Store.useDispatch();
  const [activeTab, setActiveTab] = useState('personal');
  const onUpdateProfile = (userData: Partial<User>) => {
    dispatch(Store.auth.updateUser({ userId: currentUser.id, userData }));
  };

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  return (
    <div className={styles.accountPage}>
      <h1>{t('account.title')}</h1>
      <p className={styles.subtitle}>{t('account.subtitle')}</p>

      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${activeTab === 'personal' ? styles.active : ''}`}
          onClick={() => setActiveTab('personal')}
        >
          {t('account.tabs.personal')}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'subscription' ? styles.active : ''}`}
          onClick={() => setActiveTab('subscription')}
        >
          {t('account.tabs.subscription')}
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'invoices' ? styles.active : ''}`}
          onClick={() => setActiveTab('invoices')}
        >
          {t('account.tabs.invoices')}
        </button>
      </div>

      {activeTab === 'personal' && (
        <PersonalInformation user={currentUser} onUpdateProfile={onUpdateProfile} />
      )}
      {activeTab === 'subscription' && <SubscriptionManagement user={currentUser} />}
      {activeTab === 'invoices' && <InvoicesList />}
    </div>
  );
};

export default AccountPage;
