import React from 'react';
import { useTranslation } from 'react-i18next';

interface DetailItemProps {
  label: string;
  value: string | number;
  valueClassName?: string;
}

const DetailItem: React.FC<DetailItemProps> = ({
  label,
  value,
  valueClassName = 'font-medium',
}) => (
  <div className="flex items-center justify-between border-b border-gray-100 py-2">
    <span className="text-gray-600">{label}</span>
    <span className={valueClassName}>{value}</span>
  </div>
);

interface DetailSectionProps {
  title: string;
  items: Array<{
    label: string;
    value: string | number;
    valueClassName?: string;
  }>;
}

export const DetailSection: React.FC<DetailSectionProps> = ({ title, items }) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-lg bg-white p-6 shadow-sm">
      <h2 className="mb-4 text-lg font-semibold">{title}</h2>
      <div className="space-y-4">
        {items.map((item, index) => (
          <DetailItem
            key={index}
            label={t(item.label)}
            value={item.value}
            valueClassName={item.valueClassName}
          />
        ))}
      </div>
    </div>
  );
};
