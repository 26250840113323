import React, { useEffect, useState } from 'react';
import { User } from '../../../types/user';
import { subscription } from '../../../apiClient';
import { TSubscription } from '../../../apiClient/subscription/subscription';
import { PriceTable } from '../../PriceTable/PriceTable';
import toast from 'react-hot-toast';
import styles from '../AccountPage.module.css';

interface SubscriptionManagementProps {
  user: User;
}

export const SubscriptionManagement: React.FC<SubscriptionManagementProps> = ({ user }) => {
  const params = new URLSearchParams(window.location.search);
  const redirectStatus = params.get('redirect_status');
  const [planUrl, setPlanUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    if (user?.stripeCustomerId && user?.planStatus === 'active') {
      subscription
        .get('link')
        .then((response) => {
          const data = response?.data as TSubscription;
          if (data?.url) {
            setPlanUrl(data.url);
          }
        })
        .catch((error: Error) => {
          console.error('Failed to fetch subscription link:', error);
          setError(error.message || 'Failed to load subscription information');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (!redirectStatus) return;

    if (redirectStatus === 'succeeded') {
      toast.success('Plan updated successfully!');
    } else {
      toast.error('Error updating plan!');
    }
  }, [redirectStatus]);

  return (
    <div className={styles.section}>
      <div className="flex flex-col items-center py-10 text-center">
        {error ? (
          <div className="text-red-500">{error}</div>
        ) : (
          <>
            <h2 className="mb-4 text-xl font-bold">
              Votre abonnement actuel est :{' '}
              <span className="color-orange font-bold">{user.plan}</span>
            </h2>
            {isLoading ? (
              <button
                className="mx-auto cursor-not-allowed rounded bg-gray-300 px-4 py-2 font-bold text-gray-500"
                disabled
              >
                Chargement...
              </button>
            ) : planUrl ? (
              <button
                className="mx-auto rounded bg-orange-500 px-4 py-2 font-bold text-white hover:bg-orange-600"
                onClick={() => window.open(planUrl, '_blank', 'noopener,noreferrer')}
              >
                Modifier mon abonnement
              </button>
            ) : null}
          </>
        )}
      </div>
      <PriceTable />
    </div>
  );
};
