import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import {
  ICellRendererParams,
  ColDef,
  ValueFormatterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import { TScan } from '../../apiClient/scans/scans';
import * as Store from '../../store';
import { StatusCell } from './StatusCell/StatusCell';
import { ActionCell } from './ActionCell/ActionCell';
import { ScanPreviewModal } from './ScanPreviewModal';
import { EmptyState } from './EmptyState';
import { EXPORT_FIELDS } from './constants';
import { useSocketConnection } from '../../hooks/useSocketConnection';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { IdentifierBadge } from '../ProductList/IdentifierBadge';

interface ScanTableProps {
  scans: TScan[];
  onFileLoaded: (data: string[][], file?: File) => void;
}

const MarketPlaceFlags: Record<string, string> = {
  ALL: '🌎',
  US: '🇺🇸',
  FR: '🇫🇷',
  ES: '🇪🇸',
  IT: '🇮🇹',
  DE: '🇩🇪',
} as const;
interface PreviewData {
  [key: string]: string | number;
}

export const ScanTable: React.FC<ScanTableProps> = ({ onFileLoaded, scans }) => {
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const dispatch = Store.useDispatch();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewData, setPreviewData] = useState<PreviewData[]>([]);
  const socketIO = useSocketConnection();

  const handlePreview = useCallback(
    async (scanId: string) => {
      try {
        const data = await dispatch(Store.scans.previewScan({ fileId: scanId }));
        setPreviewData(data.payload as PreviewData[]);
        setIsPreviewModalOpen(true);
      } catch (error) {
        console.error('Preview error:', error);
      }
    },
    [dispatch]
  );

  const columnDefs = useMemo<ColDef<TScan>[]>(
    () => [
      {
        headerName: t('scans.name'),
        field: 'name',
        editable: true,
        valueFormatter: (params: ValueFormatterParams<TScan>) => {
          return params.data?.name || '';
        },
        valueSetter: (params: ValueSetterParams<TScan>) => {
          if (params.newValue !== params.oldValue) {
            dispatch(
              Store.scans.updateScan({ id: params.data._id, data: { name: params.newValue } })
            );
          }
          return false;
        },
      },
      {
        headerName: t('scans.date'),
        field: 'dateOfCreation',
      },
      {
        headerName: t('scans.scannedProducts'),
        field: 'numberOfProducts',
      },
      {
        headerName: t('scans.processedProducts'),
        field: 'numberOfProductsProcessed',
        valueFormatter: (params: ValueFormatterParams<TScan>) => {
          return params.value ? params.value.toString() : '0';
        },
      },
      {
        headerName: t('common.marketplace'),
        field: 'marketPlaceIds',
        cellRenderer: (params: ICellRendererParams<TScan>) => {
          const marketplaceIds = params.value ? params.value : ['ALL'];
          return marketplaceIds.map((marketplaceId: string) => MarketPlaceFlags[marketplaceId]);
        },
      },
      {
        headerName: t('suppliers.status'),
        field: 'status',
        cellRenderer: (params: ICellRendererParams<TScan>) => {
          return socketIO ? <StatusCell params={params} socketIO={socketIO} /> : null;
        },
      },
      {
        headerName: 'Actions',
        width: 100,
        cellRenderer: (params: ICellRendererParams<TScan>) => (
          <ActionCell params={params} onPreview={handlePreview} />
        ),
        sortable: false,
        filter: false,
      },
    ],
    [t, dispatch, socketIO, handlePreview]
  );

  const previewColumnDefs = useMemo(
    () => [
      {
        field: 'AMAZON',
        headerName: 'AMAZON',
        sortable: true,
        filter: true,
        cellRenderer: (params: ICellRendererParams<PreviewData>) => {
          return (
            <div className="flex items-center gap-2">
              <a href={params.data?.['Lien Amazon'] as string} target="_blank">
                <img
                  alt="Amazon Seller"
                  width="20px"
                  height="20px"
                  src="https://upload.wikimedia.org/wikipedia/commons/4/4a/Amazon_icon.svg"
                />
              </a>
            </div>
          );
        },
      },
      ...Object.entries(EXPORT_FIELDS).map(
        ([key, label]) =>
          ({
            field: key,
            headerName: label,
            sortable: true,
            filter: true,
            cellRenderer: (params: ICellRendererParams<PreviewData>) => {
              if (key === 'ASIN') {
                return <IdentifierBadge type="ASIN" value={params.data?.[key] as string} />;
              } else if (key === 'EAN') {
                return <IdentifierBadge type="EAN" value={params.data?.[key] as string} />;
              } else if (key === 'Lien Amazon') {
                return (
                  <a href={params.data?.[key] as string} target="_blank">
                    {params.data?.[key]}
                  </a>
                );
              } else if (key === 'Image Amazon') {
                return (
                  <img
                    src={params.data?.[key] as string}
                    alt="Amazon Image"
                    className="h-10 w-10"
                  />
                );
              }
              return params.value;
            },
          }) as ColDef<PreviewData>
      ),
    ],
    []
  );

  return (
    <div className="h-[600px] w-full">
      {scans.length === 0 ? (
        <EmptyState onFileLoaded={onFileLoaded} />
      ) : (
        <>
          <div className="ag-theme-alpine h-full">
            <AgGridReact<TScan>
              ref={gridRef}
              rowData={scans}
              columnDefs={columnDefs}
              defaultColDef={{
                sortable: true,
                resizable: true,
              }}
              pagination={true}
              paginationPageSize={50}
            />
          </div>

          <ScanPreviewModal
            isOpen={isPreviewModalOpen}
            onClose={() => setIsPreviewModalOpen(false)}
            data={previewData}
            columnDefs={previewColumnDefs}
          />
        </>
      )}
    </div>
  );
};
