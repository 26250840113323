import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Search } from 'lucide-react';
import { format } from 'date-fns';
import { useAppSelector } from '../store';
import * as Store from '../store';
import { TSavedSearch } from '../apiClient/userSettings/userSettings';
import { PageHeader } from '../components/common/PageHeader';

export const SavedSearchesPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const savedSearches = useAppSelector(Store.userSettings.selectors.selectSavedSearches);
  const savedSearchIds = useAppSelector(Store.userSettings.selectors.selectSavedSearchIds);

  const handleSearchClick = (search: TSavedSearch) => {
    navigate('/', { state: { searchQuery: search.conditions } });
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8 flex items-center gap-4">
        <Search className="h-8 w-8 text-gray-400" />
        <PageHeader
          title={t('dashboard.savedSearches.title')}
          description={t('dashboard.savedSearches.description')}
        />
      </div>

      <div className="rounded-lg border border-gray-100 bg-white shadow-sm">
        <div className="divide-y divide-gray-100">
          {savedSearchIds.length === 0 ? (
            <div className="p-6 text-center text-gray-500">{t('savedSearches.noData')}</div>
          ) : (
            savedSearchIds.map((searchId) => {
              const search = savedSearches[searchId];
              if (!search) return null;
              return (
                <button
                  key={search._id}
                  onClick={() => handleSearchClick(search)}
                  className="group flex w-full items-center justify-between p-6 text-left transition-colors hover:bg-gray-50"
                >
                  <div>
                    <div className="mb-2 text-lg font-medium transition-colors group-hover:text-orange-500">
                      {search.name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {t('common.created')}: {format(new Date(search?.dateOfCreation), 'PPp')}
                    </div>
                  </div>
                </button>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default SavedSearchesPage;
