import React from 'react';
import { useTranslation } from 'react-i18next';

const languages = [
  { code: 'fr', name: 'Français', flag: '🇫🇷' },
  { code: 'en', name: 'English', flag: '🇬🇧' },
];

export const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const currentLanguage = languages.find((lang) => lang.code === i18n.language) || languages[0];
  const otherLanguage = languages.find((lang) => lang.code !== i18n.language) || languages[1];

  const toggleLanguage = () => {
    i18n.changeLanguage(otherLanguage.code);
  };

  return (
    <button
      onClick={toggleLanguage}
      className="fixed right-4 top-4 flex items-center gap-2 rounded-full border border-gray-100 bg-white px-4 py-2 shadow-sm transition-all duration-200 hover:shadow-md"
    >
      <span className="text-lg">{currentLanguage.flag}</span>
      <span className="text-sm font-medium text-gray-600">{currentLanguage.name}</span>
    </button>
  );
};
