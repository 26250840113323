import React from 'react';
import { useTranslation } from 'react-i18next';

interface ActionCellProps {
  data: {
    _id: string;
    octoParseStatus: string;
    status: string;
    isActive: boolean;
  };
  onProcess: (id: string) => void;
  onToggleActive: (id: string) => void;
}

export const ActionCell: React.FC<ActionCellProps> = ({ data, onProcess, onToggleActive }) => {
  const { t } = useTranslation();
  const canProcess = data.octoParseStatus === 'READY_TO_PROCESS';

  return (
    <div className="mr-4 flex items-center gap-2">
      <button
        type="button"
        disabled={!canProcess}
        onClick={() => onProcess(data._id)}
        className="rounded bg-yellow-500 px-3 py-1 text-sm text-white hover:bg-yellow-600 disabled:cursor-not-allowed disabled:opacity-50"
      >
        {t('suppliers.sendToProcess')}
      </button>
      <button
        type="button"
        onClick={() => onToggleActive(data._id)}
        className={`rounded px-3 py-1 text-sm text-white ${
          data.isActive ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
        }`}
      >
        {data.isActive ? t('suppliers.deactivate') : t('suppliers.activate')}
      </button>
    </div>
  );
};
