import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreVertical, Trash2, Search, Plus } from 'lucide-react';
import * as Store from '../../store';
import { TSavedSearch } from '../../apiClient/userSettings/userSettings';
import { toast } from 'react-hot-toast';

interface SavedSearchesMenuProps {
  onApplySearch: (conditions: Record<string, unknown>) => void;
  onAddSavedSearch: () => void;
}

export const SavedSearchesMenu: React.FC<SavedSearchesMenuProps> = ({
  onApplySearch,
  onAddSavedSearch,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [showSavedSearches, setShowSavedSearches] = useState(false);
  const dispatch = Store.useDispatch();
  const savedSearches = Store.useAppSelector(Store.userSettings.selectors.selectSavedSearches);
  const savedSearchList = Object.values(savedSearches || {}) as TSavedSearch[];

  const handleDelete = (searchId: string) => {
    dispatch(Store.userSettings.deleteSavedSearch(searchId));
  };

  const handleApplySearch = (conditions: Record<string, unknown>) => {
    try {
      onApplySearch(conditions);
      setIsOpen(false);
      setShowSavedSearches(false);
    } catch (error) {
      toast.error(t('error.somethingWentWrong') + ': ' + (error as Error).message);
    }
  };

  return (
    <div className="relative">
      <button onClick={() => setIsOpen(!isOpen)} className="rounded-full p-2 hover:bg-gray-100">
        <MoreVertical size={20} />
      </button>

      {isOpen && (
        <div className="absolute right-0 z-50 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            <button
              className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
              onClick={() => setShowSavedSearches(true)}
            >
              <Search size={16} />
              {t('savedSearches.load')}
            </button>
            <button
              className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
              onClick={onAddSavedSearch}
            >
              <Plus size={16} />
              {t('savedSearches.new')}
            </button>
          </div>
        </div>
      )}

      {showSavedSearches && (
        <div className="absolute right-0 z-50 mt-2 w-64 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {savedSearchList.length > 0 ? (
              savedSearchList.map((search) => (
                <div
                  key={search._id}
                  className="group flex items-center justify-between px-4 py-2 hover:bg-gray-100"
                >
                  <button
                    className="flex-1 text-left text-sm text-gray-700"
                    onClick={() => handleApplySearch(search.conditions)}
                  >
                    {search.name}
                  </button>
                  <button
                    onClick={() => handleDelete(search._id)}
                    className="text-gray-400 opacity-0 transition-opacity hover:text-red-500 group-hover:opacity-100"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              ))
            ) : (
              <div className="px-4 py-2 text-sm text-gray-500">{t('savedSearches.noData')}</div>
            )}
          </div>
        </div>
      )}

      {(isOpen || showSavedSearches) && (
        <div
          className="fixed inset-0 z-40"
          onClick={() => {
            setIsOpen(false);
            setShowSavedSearches(false);
          }}
        />
      )}
    </div>
  );
};
