import React, { useState } from 'react';
import ReactDOM from 'react-dom';

interface TooltipProps {
  children: React.ReactNode;
  tooltipText: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ children, tooltipText }) => {
  const [isSelected, setIsSelected] = useState(false);
  const [position, setPosition] = useState<{ top: number; left: number } | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = (e: React.MouseEvent) => {
    setIsSelected(true);
    const { top, left, width } = e.currentTarget.getBoundingClientRect();
    setPosition({ top: top + window.scrollY, left: left + window.scrollX + width / 2 });
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSelected(false);
    setIsOpen(false);
  };

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}

      {isOpen && position && isSelected &&
        ReactDOM.createPortal(
          <div
            className="rounded-lg bg-white p-3 text-black shadow-lg"
            style={{
              position: 'fixed',
              top: `${position.top + 20}px`,
              left: `${position.left}px`,
              zIndex: 9999,
              whiteSpace: 'nowrap', 
              color :'orange'
            }}
          >
            <span className="text-sm">{tooltipText}</span>
          </div>,
          document.body
        )}
    </div>
  );
};
