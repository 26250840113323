import React from 'react';
import { useTranslation } from 'react-i18next';

interface SearchButtonProps {
  onClick: () => void;
}

export const SearchButton: React.FC<SearchButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={onClick}
      className="rounded-lg bg-orange-500 px-6 py-2 text-white transition-colors hover:bg-orange-600"
    >
      {t('search.searchButton')}
    </button>
  );
};
