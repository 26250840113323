import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { List, ArrowRight } from 'lucide-react';
import * as Store from '../../store';
import { TScan } from '../../apiClient/scans/scans';

export const RecentScans: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const scans = Store.useAppSelector(Store.scans.selectors.selectScanCollection) as TScan[];

  const handleScanClick = () => {
    navigate('/scan-list');
  };

  return (
    <div className="rounded-lg border border-gray-100 bg-white shadow-sm">
      <div className="border-b border-gray-100 p-6">
        <div className="mb-2 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <List size={20} className="text-gray-400" />
            <h2 className="text-lg font-medium">{t('dashboard.recentScans.title')}</h2>
          </div>
          <button className="flex items-center gap-1 text-sm text-orange-500 hover:text-orange-600">
            {t('common.viewAll')} <ArrowRight size={16} />
          </button>
        </div>
        <p className="text-sm text-gray-500">{t('dashboard.recentScans.description')}</p>
      </div>
      <div className="divide-y divide-gray-100">
        {scans.slice(0, 5).map((scan) => (
          <button
            key={scan._id}
            onClick={handleScanClick}
            className="group flex w-full items-center justify-between p-4 text-left transition-colors hover:bg-gray-50"
          >
            <div>
              <div className="mb-1 font-medium transition-colors group-hover:text-orange-500">
                {scan.name}
              </div>
              <div className="text-sm text-gray-500">
                {t('dashboard.recentScans.lines', { count: scan.numberOfProducts })} •
                {t('dashboard.recentScans.matches', { count: scan.numberOfProductsProcessed })}
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div
                className={`rounded-full px-2 py-1 text-xs ${
                  scan.status === 'completed'
                    ? 'bg-green-50 text-green-600'
                    : 'bg-yellow-50 text-yellow-600'
                }`}
              >
                {t(`scans.status.${scan.status}`)}
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
