import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { API_URL } from '../constants/constants';

export const useSocketConnection = () => {
  const socketIO = useRef<Socket>();

  useEffect(() => {
    socketIO.current = io((API_URL as string).replace('http', 'ws').replace('https', 'ws'), {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 3,
      transports: ['websocket'],
    });

    socketIO.current.connect();
    socketIO.current.on('connect', () => {
      console.log('Connected');
    });

    return () => {
      socketIO?.current?.disconnect();
    };
  }, []);

  return socketIO.current;
};
