import React from 'react';

interface PageLayoutProps {
  header: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ header, children, className = '' }) => {
  return (
    <div className={`space-y-6 ${className}`}>
      <div className="rounded-lg bg-white shadow-sm">
        {header}
        <div className="p-6">{children}</div>
      </div>
    </div>
  );
};
