import React from 'react';
import { SearchBar } from './SearchBar';
import { FilterButton } from './FilterButton';
import { SearchButton } from './SearchButton';

interface SearchToolbarProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
  onFilterClick: () => void;
  onSearch: () => void;
  placeholder?: string;
}

export const SearchToolbar: React.FC<SearchToolbarProps> = ({
  searchValue,
  onSearchChange,
  onFilterClick,
  onSearch,
  placeholder,
}) => {
  return (
    <div className="flex items-center gap-4">
      <SearchBar value={searchValue} onChange={onSearchChange} placeholder={placeholder} />
      <FilterButton onClick={onFilterClick} />
      <SearchButton onClick={onSearch} />
    </div>
  );
};
