import { TSupplier } from '../apiClient/suppliers/suppliers';

export const TVA_RATES = {
  DE: 19,
  AT: 20,
  BE: 21,
  BG: 20,
  CY: 19,
  HR: 25,
  DK: 25,
  ES: 21,
  EE: 20,
  FI: 24,
  FR: 20,
  GR: 24,
  HU: 27,
  IE: 23,
  IT: 22,
  LV: 21,
  LT: 21,
  LU: 16,
  MT: 18,
  NL: 21,
  PL: 23,
  PT: 23,
  CZ: 21,
  RO: 19,
  UK: 20,
  SK: 20,
  SI: 22,
  SE: 25,
  CH: 7.7,
};

const QOGITA_ID = '65bd5600bc6de92a651f048b';

interface PriceCheck {
  price: number | undefined;
  withVAT?: boolean;
  checkPositive?: boolean;
}

export function findMostCommonPriceIndex(
  products: Array<{ supplierPrice: number; lastUpdateDate: Date }>
) {
  // 1. Build frequency map
  // Find the most recent product
  let mostRecentIndex = 0;
  let mostRecentDate = products[0]?.lastUpdateDate || new Date(0);

  for (let i = 1; i < products.length; i++) {
    const currentDate = products[i].lastUpdateDate || new Date(0);
    if (currentDate > mostRecentDate) {
      mostRecentDate = currentDate;
      mostRecentIndex = i;
    }
  }

  return mostRecentIndex;
}
export const getSupplierPriceTTC = ({
  supplier,
  supplierPrice,
  supplierPriceTTC,
  supplierPriceHT,
}: {
  supplier: TSupplier;
  supplierPrice: number;
  supplierPriceTTC?: number;
  supplierPriceHT?: number;
}) => {
  if (!supplier) return undefined;
  const vatRate = TVA_RATES[(supplier?.country || 'FR') as keyof typeof TVA_RATES] / 100;
  const isPro = supplier.type === 'pro' || supplier._id.toString() === QOGITA_ID;

  // For pro suppliers, we don't need to check if price > 0
  const priceChecks: PriceCheck[] = isPro
    ? [
        { price: supplierPriceTTC, checkPositive: true },
        { price: supplierPrice, withVAT: true, checkPositive: true },
        { price: supplierPriceHT, withVAT: true, checkPositive: true },
      ]
    : [
        { price: supplierPriceTTC, checkPositive: true },
        { price: supplierPrice, checkPositive: true },
        { price: supplierPriceHT, withVAT: true, checkPositive: true },
      ];
  for (const { price, withVAT, checkPositive } of priceChecks) {
    if (!price || (checkPositive && price <= 0)) continue;
    return withVAT ? price + price * vatRate : price;
  }
  return undefined;
};

export const getSupplierPriceHT = ({
  supplier,
  supplierPrice,
  supplierPriceTTC,
  supplierPriceHT,
}: {
  supplier: TSupplier;
  supplierPrice: number;
  supplierPriceTTC?: number;
  supplierPriceHT?: number;
}) => {
  if (!supplier) return undefined;
  const vatRate = TVA_RATES[(supplier?.country as keyof typeof TVA_RATES) || 'FR'] / 100;
  const isPro = supplier?.type === 'pro';

  const priceChecks: PriceCheck[] = [
    { price: supplierPriceHT, checkPositive: true },
    {
      price: supplierPrice,
      withVAT: !isPro,
      checkPositive: true,
    },
    {
      price: supplierPriceTTC,
      withVAT: true,
      checkPositive: true,
    },
  ];

  for (const { price, withVAT, checkPositive } of priceChecks) {
    if (!price || (checkPositive && price <= 0)) continue;
    return withVAT ? price / (1 + vatRate) : price;
  }
  return undefined;
};
