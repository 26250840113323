import React from 'react';

interface ProfitBadgeProps {
  value: number;
  percentage: number;
}

export const ProfitBadge: React.FC<ProfitBadgeProps> = ({ value, percentage }) => {
  let bgColor = 'bg-green-50';
  let textColor = 'text-green-600';

  if (percentage < 0) {
    bgColor = 'bg-red-50';
    textColor = 'text-red-600';
  } else if (percentage < 10) {
    bgColor = 'bg-yellow-50';
    textColor = 'text-yellow-600';
  }
  return (
    <div className="flex items-center gap-4">
      <div
        className={`inline-flex overflow-hidden rounded-full ${bgColor} border border-opacity-10`}
      >
        <span className={`${textColor} border-r border-opacity-10 px-2 py-0.5 text-xs`}>
          {value.toFixed(2)}€
        </span>
        <span className={`${textColor} px-2 py-0.5 text-xs`}>{percentage.toFixed(2)}%</span>
      </div>
    </div>
  );
};
