import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as auth from './auth/auth';
import * as suppliers from './suppliers/suppliers';
import * as scans from './scans/scans';
import { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';
import * as userSettings from './userSettings/userSettings';
import * as users from './users/users';

const rootReducer = combineReducers({
  [auth.reducerName]: auth.reducer,
  [suppliers.reducerName]: suppliers.reducer,
  [scans.reducerName]: scans.reducer,
  [userSettings.reducerName]: userSettings.reducer,
  [users.reducerName]: users.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  version: 3,
  whitelist: [
    auth.reducerName,
    suppliers.reducerName,
    scans.reducerName,
    userSettings.reducerName,
    users.reducerName,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export { auth, suppliers, scans, userSettings, users };

export const useDispatch = () => store.dispatch;
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
