import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '../components/common/PageLayout';
import { PageHeader } from '../components/common/PageHeader';
import * as Store from '../store';
import { useProductSearch } from '../hooks/useProductSearch';
import { formatFilterStateToSearchConditions } from '../utils/filter';
import { ProductList, TGridData } from '../components/ProductList/ProductList';

export const SavedAsinsPage: React.FC = () => {
  const { t } = useTranslation();
  const savedAsins = Store.useAppSelector(Store.userSettings.selectors.selectSavedAsins);
  const { data, handleSubmit } = useProductSearch();
  const prevAsinsRef = useRef<string[]>([]);
  const selectedMarketplaces = Store.useAppSelector(
    Store.userSettings.selectors.selectSelectedMarketplaces
  );
  useEffect(() => {
    const savedAsinsString = JSON.stringify(savedAsins);
    const prevAsinsString = JSON.stringify(prevAsinsRef.current);

    if (savedAsins.length > 0 && prevAsinsString !== savedAsinsString) {
      const searchConditions = formatFilterStateToSearchConditions({
        asinList: savedAsins,
        amazonCountry: selectedMarketplaces,
      });
      handleSubmit(searchConditions);
      prevAsinsRef.current = [...savedAsins];
    }
  }, [handleSubmit, savedAsins, selectedMarketplaces]);

  const docs = data.docs.reduce((acc, curr) => {
    acc.push({
      ...curr,
      products: curr.products
        .sort((a, b) => a.supplierPrice - b.supplierPrice || b.profit - a.profit)
        .slice(0, 5),
    });
    return acc;
  }, [] as TGridData[]);
  console.log({ docs });
  return (
    <PageLayout
      header={
        <PageHeader title={t('savedAsins.title')} description={t('savedAsins.description')} />
      }
    >
      <div className="ag-theme-alpine h-[calc(100vh-12rem)]">
        <h4>{t('savedAsins.title')}</h4>
        <ProductList docs={docs} meta={data.meta} conditions={{}} isLoading={false} />
      </div>
    </PageLayout>
  );
};
