import React, { useState, useEffect } from 'react';
import { Range } from 'react-range';

const RangeInput: React.FC<{
  label: string;
  min: number;
  max: number;
  step: number;
  tooltip?: string;
  onChange?: (values: number[]) => void;
  value?: [number, number];
}> = ({ label, min, max, step, tooltip, onChange, value }) => {
  const [values, setValues] = useState<[number, number]>(value || [min, max]);

  useEffect(() => {
    if (value) {
      setValues(value);
    }
  }, [value]);

  const [error, setError] = useState<string | null>(null);

  const [activeThumb, setActiveThumb] = useState<0 | 1 | null>(null);

  const handleThumbFocus = (index: 0 | 1) => {
    setActiveThumb(index);
  };

  const handleThumbBlur = () => {
    setActiveThumb(null);
  };

  const handleChange = (newValues: number[]) => {
    const clampedValues = [
      Math.max(min, Math.min(newValues[0], max)), // Limiter la valeur min
      Math.min(max, Math.max(newValues[1], min)), // Limiter la valeur max
    ] as [number, number];

    // On va  Vérifier si la nouvelle plage est valide
    if (clampedValues[0] > clampedValues[1]) {
      setError('La valeur minimale ne peut pas être supérieure à la valeur maximale');
    } else {
      setError(null);
    }

    setValues(clampedValues);
    onChange?.(clampedValues);
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between">
        <label className="flex items-center gap-1 text-sm font-medium text-gray-700">
          {label}
          {tooltip && (
            <span title={tooltip} className="text-gray-400">
              ?
            </span>
          )}
        </label>
      </div>
      <div className="flex flex-col items-start space-y-4">
        <Range
          values={[values[0], values[1]]}
          step={step}
          min={min}
          max={max}
          onChange={handleChange}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '6px',
                width: '100%',
                borderRadius: '4px',
                borderColor: 'red',
                backgroundColor: '#D5D9E2',
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '20px',
                width: '20px',
                backgroundColor: activeThumb === index ? 'orange' : '#fff',
                border: '1px solid white',
                borderRadius: '50%',
                boxShadow: '0px 2px 6px rgba(0,0,0,0.2)',
              }}
              onFocus={() => handleThumbFocus(index as 0 | 1)}
              onBlur={handleThumbBlur}
            />
          )}
        />
        <div className="flex w-full items-center justify-between">
          <input
            type="number"
            value={values[0]}
            onChange={(e) =>
              handleChange([Math.max(min, Math.min(Number(e.target.value), values[1])), values[1]])
            }
            className="w-20 rounded border border-gray-300 px-2 py-1"
          />
          <span className="px-2">-</span>
          <input
            type="number"
            value={values[1]}
            onChange={(e) =>
              handleChange([values[0], Math.min(max, Math.max(Number(e.target.value), values[0]))])
            }
            className="w-20 rounded border border-gray-300 px-2 py-1"
          />
        </div>
        {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
      </div>
    </div>
  );
};

export { RangeInput };
