export const en = {
  validation: {
    required: 'Field required',
    email: 'Invalid email',
    password: 'Invalid password',
    phone: 'Invalid phone number',
    min: 'Minimum of {{min}} characters',
    max: 'Maximum of {{max}} characters',
    minLength: 'Minimum of {{min}} characters',
    maxLength: 'Maximum of {{max}} characters',
    minValue: 'Minimum of {{min}}',
    maxValue: 'Maximum of {{max}}',
    invalid: 'Invalid format',
  },
  common: {
    search: 'Search...',
    previous: 'Previous',
    next: 'Next',
    cancel: 'Cancel',
    validate: 'Validate',
    save: 'Save',
    apply: 'Apply',
    reset: 'Reset',
    category: 'Category',
    marketplace: 'Marketplace',
    vatExcl: 'VAT excl',
    vatIncl: 'VAT incl',
    buy: 'Buy',
    keywords: 'Keywords...',
    otherTitles: 'Other Titles',
    export: 'Export',
    copied: 'Copied to clipboard',
    yes: 'Yes',
    no: 'No',
    error: 'An error occurred',
    title: 'Title',
    price: 'Price',
    id: 'ID',
    viewAll: 'View all',
    dataCleared: 'Data cleared successfully',
    inStock: 'In Stock',
    outOfStock: 'Out of Stock',
  },
  auth: {
    title: {
      1: 'Maximize',
      2: 'your profits',
      3: 'with',
    },
    description:
      'Find the most profitable products to resell on Amazon with our powerful search engine.',
    steps: {
      profile: 'Profile',
      seller: 'Amazon Seller',
      subscription: 'Subscription',
    },
    login: {
      title: 'Connexion',
      noAccount: "Vous n'avez pas de compte ?",
      registerLink: "S'inscrire",
      submit: 'Se connecter',
      forgotPassword: 'Forgot your password?',
      resetLink: 'Reset it here',
    },
    register: {
      title: 'Créer un compte',
      hasAccount: 'Vous avez déjà un compte ?',
      loginLink: 'Se connecter',
      submit: "S'inscrire",
    },
    profile: {
      title: 'Your Profile',
      lastName: 'Last Name',
      firstName: 'First Name',
      email: 'Email',
      phone: 'Phone Number',
      lastNamePlaceholder: 'Enter your last name',
      firstNamePlaceholder: 'Enter your first name',
      emailPlaceholder: 'Enter your email',
      phonePlaceholder: 'Enter your phone number',
    },
    seller: {
      title: 'Connect Your Amazon Seller Account',
      description: 'Connect your Amazon Seller account to start importing your products.',
      connectButton: 'Connect with Amazon',
      noAccount: "Don't have an Amazon Seller account?",
      createAccount: 'Create one now',
      yourSellerAccountIsConnected: 'Your seller account is connected',
    },
    forgotPassword: {
      submit: 'Send Reset Link',
      emailSent: 'Password reset instructions have been sent to your email',
      backToLogin: 'Remember your password?',
      loginLink: 'Login here',
    },
    resetPassword: {
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      submit: 'Reset Password',
      success: 'Your password has been reset successfully',
      invalidTokenOrId: 'Invalid or expired reset link',
    },
    verifyEmail: {
      verifying: 'Verifying your email...',
      wait: 'Please wait while we verify your email',
      success: 'Email verified successfully',
      error: 'Error verifying email',
      invalidToken: 'Invalid or expired verification link',
    },
  },
  user: {
    profile: 'Profile',
    editProfile: 'Edit Profile',
    userList: 'User List',
    switchBackToAdmin: 'Switch Back to Admin',
    adminMessages: 'Admin Messages',
    logout: 'Logout',
    profileDescription: 'Manage your personal information and preferences',
    personalInfo: 'Personal Information',
    companyInfo: 'Company Information',
    contactInfo: 'Contact Information',
    subscriptionInfo: 'Subscription Information',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Address',
    companyName: 'Company Name',
    companyId: 'Company ID',
    phoneNumber: 'Phone Number',
    address: 'Address',
    city: 'City',
    zipCode: 'ZIP Code',
    country: 'Country',
    stripeCustomerId: 'Stripe Customer ID',
    subscriptionPlan: 'Subscription Plan',
    subscriptionStatus: 'Status',
    subscriptionStart: 'Start Date',
    subscriptionEnd: 'End Date',
    roles: 'Roles',
    createdAt: 'Created At',
    searchUsers: 'Search users...',
    userListDescription: 'Manage user accounts and permissions',
  },
  search: {
    title: 'Find Products',
    description: 'Over 150 million products at your fingertips, updated daily.',
    include: 'Include',
    exclude: 'Exclude',
    searchButton: 'Search',
    found: 'products found',
  },
  filters: {
    title: 'Filters',
    profit: {
      title: 'Profit',
      price: 'Price',
      roiUnit: 'ROI per unit',
      netRevenue: 'Net revenue after all fees',
      salesPerMonth: 'Sales per month',
      profitUnit: 'Profit per unit',
      profitPerMonth: 'Monthly profit',
      supplierPrice: 'Supplier price',
      amazonPrice: 'Amazon price',
    },
    suppliers: {
      title: 'Suppliers',
      type: 'Type',
      name: 'Name',
      price: 'Price',
      selectType: 'Select type',
      selectSupplier: 'Select supplier',
      supplier: 'Merchant sites',
      professional: 'Professional sites',
      selectAtLeastOneSupplier: 'Please select at least one supplier',
      selectAtMostThreeSuppliers: 'Please select at most three suppliers',
    },
    isGrouped: 'Grouped Products',
    isAmazonFulfilled: 'Amazon Fulfilled Products',
  },
  products: {
    title: 'Title',
    price: 'Price',
    supplierPrices: 'Supplier Prices',
    profitMargin: 'Profit Margin',
    monthlySales: 'Monthly Sales',
    monthlyProfit: 'Monthly Profit',
    keepa: 'Keepa',
    export: 'Export',
    found: 'products found',
    comparePrices: 'Compare Prices',
    marketplace: 'Marketplace',
    resultTable: {
      amazonTitle: 'Amazon Title',
      amazonPrice: 'Amazon Price',
      supplierTitle: 'Supplier Title',
      supplierPrice: 'Supplier Price',
      profitMargin: 'Profit Margin',
      monthlySales: 'Monthly Sales',
      monthlyProfit: 'Monthly Profit',
      keepa: 'Keepa',
      profit: 'Profit',
      estimatedSales: 'Sale(s)/month',
      estimatedProfit: 'Profit/month',
      estimatedROI: 'ROI/month',
      estimatedROIUnit: 'ROI/unit',
      estimatedNetRevenue: 'Net revenue/month',
      estimatedNetRevenueUnit: 'Net revenue/unit',
      image: 'Image',
      profitUnit: 'Unit profit',
      starred: 'Favorites',
    },
  },
  suppliers: {
    title: 'Suppliers',
    description: 'Manage your suppliers and their products.',
    name: 'Name',
    country: 'Country',
    type: 'Type',
    category: 'Category',
    productCount: 'Products',
    lastUpdate: 'Last Update',
    found: 'suppliers found',
    affiliated: 'Affiliated Supplier',
    status: 'Status',
    actions: 'Actions',
    sendToProcess: 'Send to Process',
    activate: 'Activate',
    deactivate: 'Deactivate',
    processingStarted: 'Processing started',
    statusUpdated: 'Status updated',
    updated: 'Supplier updated',
  },
  affiliz: {
    title: 'Affiliz Suppliers',
    description: 'Manage your Affiliz supplier connections',
    selectCountry: 'Select country...',
    selectType: 'Select type...',
    professional: 'Professional',
    merchant: 'Merchant',
    add: 'Add',
  },
  countries: {
    france: 'France',
    uk: 'United Kingdom',
    italy: 'Italy',
    spain: 'Spain',
    germany: 'Germany',
  },
  dashboard: {
    title: 'Dashboard',
    description: 'View your recent activity and important metrics',
    metrics: {
      totalProducts: 'Total Products',
      activeSearches: 'Active Searches',
      recentScans: 'Recent Scans',
      potentialProfit: 'Potential Profit',
    },
    recentProducts: {
      title: 'Recent Products',
      description: 'Recently viewed products',
      viewAll: 'View All',
    },
    savedSearches: {
      title: 'Saved Searches',
      description: 'Your recent saved product searches',
      viewAll: 'View All',
      results: 'results',
      potentialProfit: 'Potential profit',
      new: 'New search',
      load: 'Load search',
    },
    recentScans: {
      title: 'Recent Scans',
      description: 'Your recent product scans',
      viewAll: 'View All',
      lines: 'lines',
      matches: 'matches',
      potentialProfit: 'Potential profit',
    },
    savedProducts: {
      title: 'Saved Products',
      description: 'Your favorite products and their price variations',
      viewAll: 'View All',
    },
  },
  sidebar: {
    chromeExtension: 'Chrome Extension',
    chromeDescription: 'Find profitable products faster with our Chrome extension.',
    installExtension: 'Install Extension',
    dashboard: 'Dashboard',
    findProduct: 'Find Product',
    reverseSearch: 'Reverse Search',
    scanList: 'Scan List',
    suppliers: 'Suppliers',
    affilizSuppliers: 'Affiliz Suppliers',
    support: 'Support',
    videos: 'Videos',
    videosDescription: 'Learn how to maximize your profits with our video tutorials.',
    watchVideos: 'Watch Videos',
    activity: 'ACTIVITY',
    products: 'PRODUCTS',
    contact: 'CONTACT',
  },
  dropzone: {
    error: 'Error when loading the file',
    fileLoaded: 'File loaded successfully',
    fileLoadedError: 'Error when loading the file',
    fileLoadedErrorMissingQuotes: 'Error when loading the file: MissingQuotes',
    fileLoadedErrorUndetectableDelimiter: 'Error when loading the file: UndetectableDelimiter',
    fileLoadedErrorTooFewFields: 'Error when loading the file: TooFewFields',
    fileLoadedErrorTooManyFields: 'Error when loading the file: TooManyFields',
    fileLoadedErrorInvalidQuotes: 'Error when loading the file: InvalidQuotes',
    MissingQuotes: 'Missing Quotes',
    UndetectableDelimiter: 'Undetectable Delimiter',
    TooFewFields: 'Too Few Fields',
    TooManyFields: 'Too Many Fields',
    InvalidQuotes: 'Invalid Quotes',
    atIndex: 'at index',
    dragInactive: 'Drag and drop your file here or click to upload',
    dragActive: 'Drop the file here or click to upload',
    browse: 'Browse',
    supportedFormats: 'Supported formats: CSV, XLSX, XLS',
  },
  scans: {
    name: 'Name',
    marketplace: 'Marketplace',
    date: 'Date',
    actions: 'Actions',
    processedProducts: 'Processed Products',
    scannedProducts: 'Scanned Products',
    title: 'Scan List',
    description: 'Manage your scan list',
    addScan: 'Add a scan',
    affectedMarketplaces: 'Affected Marketplaces',
    selectMarketplace: 'Select Marketplaces',
    mapping: {
      title: 'Mapping des colonnes',
      description:
        'Sélectionnez les colonnes de votre fichier qui correspondent aux champs de ResellCorner.',
      field: 'Champ ResellCorner',
      value: 'Champ du fichier',
    },
    status: {
      processing: 'Processing',
      processed: 'Processed',
      failed: 'Failed',
      pending: 'Pending',
      completed: 'Completed',
    },
  },
  scanList: {
    emptyState: {
      title: 'Add products',
      description: 'Add products to your list by uploading a CSV or Excel file.',
    },
    addModal: {
      title: 'Add products',
      description: 'Add products to your list by uploading a CSV or Excel file.',
    },
    mapping: {
      title: 'Mapping des colonnes',
      description:
        'Sélectionnez les colonnes de votre fichier qui correspondent aux champs de ResellCorner.',
      field: 'Champ ResellCorner',
      value: 'Champ du fichier',
      affectedMarketplaces: 'Affected Marketplaces',
      selectMarketplace: 'Select Marketplaces',
    },
  },
  savedSearches: {
    title: 'Saved Searches',
    save: 'Save',
    saveTitle: 'Save Search',
    name: 'Search Name',
    namePlaceholder: 'Enter a name for your search',
    nameRequired: 'Name is required',
    saveSuccess: 'Search saved successfully',
    saveError: 'Error saving search',
    invalidFilters: 'Invalid filters',
    delete: 'Delete',
    deleteConfirm: 'Are you sure you want to delete this search?',
    deleteSuccess: 'Search deleted successfully',
    deleteError: 'Error deleting search',
    noResults: 'No saved searches',
    apply: 'Apply',
    new: 'New search',
    load: 'Load search',
  },
  account: {
    title: 'My Account',
    subtitle: 'Find all information related to your account.',
    personalInformation: 'My Personal Information',
    manageSubscription: 'Manage My Subscription',
    invoices: 'My Invoices',
    tabs: {
      personal: 'Personal Information',
      subscription: 'Subscription',
      invoices: 'Invoices',
    },
    updateProfile: 'Update Profile',
  },
  clearData: {
    title: 'Clear Application Data',
    description:
      'This action will clear all locally stored data and log you out of the application.',
    button: 'Clear Data',
  },
  productDetails: {
    viewOnAmazon: 'View on Amazon',
    viewSupplier: 'View Supplier',
    affiliateLink: 'Affiliate Link',
    metrics: {
      amazonPrice: 'Amazon Price',
      supplierPrice: 'Supplier Price',
      roi: 'ROI',
      salesRank: 'Sales Rank',
    },
    charts: {
      estimatedSales: 'Estimated Sales',
      numberOfOffers: 'Number of Offers',
      salesRank: 'Sales Rank',
    },
    financial: {
      title: 'Financial Information',
      fbaFees: 'FBA Fees',
      referralFees: 'Referral Fees',
      perItemFee: 'Per Item Fee',
      variableClosingFee: 'Variable Closing Fee',
      totalFees: 'Total Fees',
      profitPerUnit: 'Profit per Unit',
      monthlyProfit: 'Monthly Profit',
      vtaRate: 'VAT Rate',
    },
    additional: {
      title: 'Additional Information',
      brand: 'Brand',
      supplier: 'Supplier',
      supplierCountry: 'Supplier Country',
      stockStatus: 'Stock Status',
      inStock: 'In Stock',
      outOfStock: 'Out of Stock',
      estimatedSales: 'Estimated Sales',
      fulfillmentType: 'Fulfillment Type',
      expirationDated: 'Expiration Dated',
      launchDate: 'Launch Date',
      lastSalesCheck: 'Last Sales Check',
      lastUpdated: 'Last Updated',
    },
  },
};
