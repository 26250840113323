import { APIClient } from '../../services/client.service';
import { TUser } from '../../types/user';

class User extends APIClient<TUser> {
  constructor() {
    super('users');
  }
  switchToUser(userId: string) {
    return this.get(`switch/${userId}`);
  }
}

export { User };
