import React, { ReactNode } from 'react';
import { Logo } from './Logo';
import { Navigation } from './Navigation';
import { VideoCard } from './VideoCard';
import { ChromeExtension } from './ChromeExtension';

interface SidebarProps {
  children?: ReactNode; 
  collapse :boolean
}

export const Sidebar: React.FC<SidebarProps> = ({ children , collapse }) => (
  <div className={`fixed bottom-0 left-0 top-0 flex flex-col border-r border-gray-200 bg-white transition-all duration-300 ${
    collapse ? 'w-16' : 'w-64'
  }`}>
    <Logo collpase = {collapse} />
    <div className="flex-1 overflow-y-auto">
      <Navigation collapse ={collapse} />
      <div className="mt-auto">
        <VideoCard collapse = {collapse}  />
        <ChromeExtension collapse = {collapse} />
      </div>
    </div>
    {children}
  </div>
);
