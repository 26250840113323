import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayout } from '../components/Auth/AuthLayout';
// import { Stepper } from '../components/Auth/Stepper';
import { ProfileForm, FormValues } from '../components/Auth/ProfileForm';
// import { AmazonSellerForm } from '../components/Auth/AmazonSellerForm';
// import { SubscriptionForm } from '../components/Auth/SubscriptionForm';
// import { useTranslation } from 'react-i18next';
// import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as apiClient from '../apiClient';
import toast from 'react-hot-toast';
export const Auth: React.FC = () => {
  // const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();

  const handleSubmit = async (values: FormValues) => {
    try {
      const data = {
        ...values,
        email: values.email.toLowerCase(),
      };
      const response = await apiClient.auth.signup(data);
      console.log(response);
      navigate('/login');
    } catch (error) {
      toast.error(error as string);
    }
  };

  // const handleNext = () => {
  //   if (currentStep === 1) {
  //     if (!registerValues) {
  //       return;
  //     }
  //   }
  //   if (currentStep === 3) {
  //     // Navigate to product search page after completing subscription
  //     navigate('/');
  //     return;
  //   }
  //   setCurrentStep((prev) => Math.min(prev + 1, 3));
  // };

  // const handlePrevious = () => {
  //   setCurrentStep((prev) => Math.max(prev - 1, 1));
  // };

  // const renderStep = () => {
  //   switch (currentStep) {
  //     case 1:
  //       return <ProfileForm onSubmitSuccess={handleSubmit} />;
  //     case 2:
  //       return <AmazonSellerForm />;
  //     case 3:
  //       return <SubscriptionForm />;
  //     default:
  //       return null;
  //   }
  // };

  // const { t } = useTranslation();
  return (
    <AuthLayout>
      {/* <Stepper currentStep={currentStep} /> */}
      <ProfileForm onSubmitSuccess={handleSubmit} />
      {/* {renderStep()} */}
      {/* <div className="mt-8 flex justify-between">
        <button
          onClick={handlePrevious}
          disabled={currentStep === 1}
          className={`flex items-center rounded px-4 py-2 ${
            currentStep === 1
              ? 'bg-white-300 text-black-500 cursor-not-allowed'
              : 'bg-white-500 text-black'
          }`}
        >
          <ChevronLeft size={16} className="mr-2" />
          {t('auth.previous', 'Précédent')}
        </button>

        <button
          onClick={handleNext}
          disabled={currentStep === 1 && !registerValues}
          className={`flex items-center rounded bg-orange-500 px-4 py-2 text-white hover:bg-orange-600 ${
            currentStep === 1 && !registerValues ? 'cursor-not-allowed opacity-50' : ''
          }`}
        >
          {currentStep === 3 ? (
            <>
              {t('auth.finish', 'Terminer')}
              <ChevronRight size={16} className="ml-2" />
            </>
          ) : (
            <>
              {t('auth.next', 'Suivant')}
              <ChevronRight size={16} className="ml-2" />
            </>
          )}
        </button>
      </div> */}
    </AuthLayout>
  );
};
