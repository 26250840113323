import React from 'react';
// import { ChevronRight } from 'lucide-react';

interface Category {
  name: string;
  products: number;
  roi: number;
}

// const RoiBadge: React.FC<{ value: number }> = ({ value }) => {
//   let bgColor = 'bg-green-50';
//   let textColor = 'text-green-600';

//   if (value < 8) {
//     bgColor = 'bg-red-50';
//     textColor = 'text-red-600';
//   } else if (value < 10) {
//     bgColor = 'bg-yellow-50';
//     textColor = 'text-yellow-600';
//   }

//   return (
//     <span className={`${bgColor} ${textColor} rounded-sm px-1.5 py-0.5 text-[10px] font-medium`}>
//       ROI moy {value.toFixed(1)}%
//     </span>
//   );
// };

export const ProductCategories: React.FC = () => {
  const categories: Category[] = [
    { name: 'High-Tech', products: 1952, roi: 12.2 },
    { name: 'Matériel de bureau', products: 22110, roi: 8.4 },
    { name: 'Cuisine & Maison', products: 14845, roi: 8.2 },
    { name: 'Jeux vidéos', products: 12998, roi: 7.8 },
  ];

  return (
    <div className="grid grid-cols-4 gap-4">
      {categories.map((category) => (
        <div
          key={category.name}
          className="secteur-bar-step rounded-lg border border-gray-100 bg-white p-4 shadow-sm transition-shadow hover:shadow-md"
        >
          <div className="mb-1.5 flex items-center justify-between">
            <h3 className="secteur-bar-step text-sm font-medium">{category.name}</h3>
            {/* <button className="text-[10px] text-gray-400 hover:text-gray-600">
              Voir <ChevronRight size={12} className="inline" />
            </button> */}
          </div>
          <div className="flex items-center justify-between text-xs text-gray-500">
            <span>{category.products.toLocaleString()} produits</span>
            {/* <RoiBadge value={category.roi} /> */}
          </div>
        </div>
      ))}
    </div>
  );
};
