import React, { useEffect } from 'react';
import { UserListHeader } from '../components/User/UserList/UserListHeader';
import { UserListGrid } from '../components/User/UserList/UserListGrid';
import * as Store from '../store';
export const UserListPage: React.FC = () => {
  const dispatch = Store.useDispatch();
  const users = Store.useAppSelector(Store.users.selectors.users);

  useEffect(() => {
    if (users.length === 0) {
      dispatch(Store.users.fetchUsers());
    }
  }, [dispatch, users]);

  return (
    <div className="space-y-6">
      <div className="rounded-lg bg-white shadow-sm">
        <UserListHeader />
        <div className="p-6">
          <UserListGrid users={users} />
        </div>
      </div>
    </div>
  );
};
