import React from 'react';

type ImageTooltipProps = {
  imageUrl: string;
  isVisible: boolean;
};

export const ImageTooltip: React.FC<ImageTooltipProps> = ({ imageUrl, isVisible }) => {
  if (!isVisible) return null;

  return (
    <div
      className="fixed z-50 bg-white border border-gray-300 shadow-lg rounded-lg"
      style={{
        bottom: '20px',
        right: '20px',
        width: '300px',
        height: '300px',
      }}
    >
      <img
        src={imageUrl}
        alt="Enlarged Product"
        className="w-full h-full object-contain rounded"
      />
    </div>
  );
};
