import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';

// Define schemas first
export const loginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(6),
});

export const registerSchema = loginSchema.extend({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
});

// Use schema types
export type LoginValues = z.infer<typeof loginSchema>;
export type RegisterValues = z.infer<typeof registerSchema>;
export type FormValues = LoginValues | RegisterValues;

interface ProfileFormProps {
  onSubmitSuccess?: (values: FormValues) => void;
  isLoginMode?: boolean;
}

export const ProfileForm: React.FC<ProfileFormProps> = ({
  onSubmitSuccess,
  isLoginMode = false,
}) => {
  const { t } = useTranslation();

  const validationSchema = isLoginMode
    ? toFormikValidationSchema(loginSchema)
    : toFormikValidationSchema(registerSchema);

  const initialValues = {
    email: '',
    password: '',
    ...(isLoginMode
      ? {}
      : {
          firstName: '',
          lastName: '',
        }),
  };

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      const schema = isLoginMode ? loginSchema : registerSchema;
      const validatedValues = await schema.parseAsync(values);
      onSubmitSuccess?.(validatedValues);
    } catch (error) {
      if (error instanceof z.ZodError) {
        toast.error(error.errors[0].message);
      } else if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred');
      }
      console.error('Form submission error:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="space-y-6">
          {!isLoginMode && (
            <>
              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('auth.profile.firstName')} <span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="firstName"
                  placeholder={t('auth.profile.firstNamePlaceholder')}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="mt-1 text-sm text-red-600"
                />
              </div>

              <div>
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('auth.profile.lastName')} <span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="lastName"
                  placeholder={t('auth.profile.lastNamePlaceholder')}
                  className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="mt-1 text-sm text-red-600"
                />
              </div>
            </>
          )}
          <div>
            <label className="mb-1 block text-sm font-medium text-gray-700">
              {t('auth.login.email')} <span className="text-red-500">*</span>
            </label>
            <Field
              type="email"
              name="email"
              placeholder={t('auth.login.emailPlaceholder')}
              className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
            />
            <ErrorMessage name="email" component="div" className="mt-1 text-sm text-red-600" />
          </div>

          <div>
            <label className="mb-1 block text-sm font-medium text-gray-700">
              {t('auth.login.password')} <span className="text-red-500">*</span>
            </label>
            <Field
              type="password"
              name="password"
              placeholder={t('auth.login.passwordPlaceholder')}
              className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
            />
            <ErrorMessage name="password" component="div" className="mt-1 text-sm text-red-600" />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full rounded-lg bg-orange-500 px-4 py-2 text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-200 disabled:opacity-50"
          >
            {isLoginMode ? t('auth.login.submit') : t('auth.register.submit')}
          </button>

          {isLoginMode && (
            <div className="mt-4 text-center">
              <p className="text-sm text-gray-600">
                {t('auth.login.forgotPassword')}{' '}
                <Link
                  to="/forgot-password"
                  className="font-medium text-orange-500 hover:text-orange-600"
                >
                  {t('auth.login.resetLink')}
                </Link>
              </p>
            </div>
          )}

          {!isLoginMode && (
            <div className="mt-4 text-center">
              <p className="text-sm text-gray-600">
                {t('auth.register.hasAccount')}{' '}
                <Link to="/login" className="font-medium text-orange-500 hover:text-orange-600">
                  {t('auth.register.loginLink')}
                </Link>
              </p>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
