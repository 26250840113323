import React, { useState, useEffect } from 'react';
import { User } from '../../../types/user';
import styles from '../AccountPage.module.css';
import { AmazonSellerForm } from '../../Auth/AmazonSellerForm';
import { useTranslation } from 'react-i18next';

interface PersonalInformationProps {
  user: User;
  onUpdateProfile: (userData: Partial<User>) => void;
}

export const PersonalInformation: React.FC<PersonalInformationProps> = ({
  user,
  onUpdateProfile,
}) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    lastName: user.lastName,
    firstName: user.firstName,
    email: user.email,
    phoneNumber: user.phoneNumber || '',
  });

  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const changed = Object.entries(formData).some(
      ([key, value]) => user[key as keyof User]?.toString() !== value?.toString()
    );
    setHasChanges(changed);
  }, [formData, user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!hasChanges) return;
    onUpdateProfile(formData);
  };

  return (
    <div className={styles.section}>
      <h2>{t('account.personalInformation')}</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="lastName">{t('user.lastName')}</label>
          <input
            id="lastName"
            name="lastName"
            type="text"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="firstName">{t('user.firstName')}</label>
          <input
            id="firstName"
            name="firstName"
            type="text"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">{t('user.email')}</label>
          <input
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="phoneNumber">{t('user.phoneNumber')}</label>
          <input
            id="phoneNumber"
            name="phoneNumber"
            type="tel"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </div>
        <button
          type="submit"
          className={`${styles.submitButton} ${!hasChanges ? styles.disabled : ''}`}
          disabled={!hasChanges}
        >
          {t('account.updateProfile')}
        </button>
      </form>

      <AmazonSellerForm />
    </div>
  );
};
