import React, { useMemo, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { Star, TextSearch, X } from 'lucide-react';
import { SupplierPrices } from './SupplierPrices';
import { MarketplaceFlag } from './MarketplaceFlag';
import { IdentifierBadge } from './IdentifierBadge';
import { Product } from '../../types/product';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { ImageTooltip } from './ImageTooltip';
import _ from 'lodash';

import { AsinTooltip } from './AsinTooltip';
import { Tooltip } from 'flowbite-react';

import { ProfitBadgeTooltip } from './PorfitBadgeTooltip';

import * as apiClient from '../../apiClient';

import { LoadingOverlay } from '../LoadingOverlay/LoadingOverlay';
import * as Store from '../../store';
import { RowHeightParams as AgGridRowHeightParams } from 'ag-grid-community';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { COUNTRY_FLAGS } from '../../types/supplier';
import {
  findMostCommonPriceIndex,
  getSupplierPriceHT,
  getSupplierPriceTTC,
} from '../../utils/price';

type TProductListProps = {
  docs: TGridData[];
  meta: {
    hasNextPage: boolean;
    hasPrevPage: boolean;
    limit: number;
    nextPage: number;
    page: number;
    pagingCounter: number;
    prevPage: number;
    totalDocs: number;
    totalPages: number;
  };
  gridOptions?: {
    getRowHeight?: (params: AgGridRowHeightParams<TGridData>) => number;
    suppressRowTransform?: boolean;
    animateRows?: boolean;
  };
  conditions: Record<string, unknown>;
  gridRef?: React.RefObject<AgGridReact<TGridData>>;
  isLoading: boolean;
};

// Définir les interfaces pour les paramètres
interface CellRendererParams {
  value: unknown;
  data: {
    products: Product[];
    _id: string;
  };
}

// Remplacer value: any par un type plus précis
interface GridParams {
  data: {
    products: Product[];
    _id: string;
  };
  value: unknown;
}

// 1. Définir le type de la donnée de grille
export interface TGridData {
  _id: string;
  count: number;
  lastUpdateDate: string;
  profit: number;
  products: Product[];
}

const SavedAsinsComponent: FC<{ params: CellRendererParams }> = ({ params }) => {
  const { t } = useTranslation();
  const asins = Store.useAppSelector(Store.userSettings.selectors.selectSavedAsins);
  const isSaved = asins.includes(params.data.products[0].asin);
  const dispatch = Store.useDispatch();
  const onSaveAsin = () => {
    if (isSaved) {
      dispatch(Store.userSettings.unsaveAsin(params.data.products[0].asin));
      toast.success(t('productList.asin.removedFromFavorites'));
    } else {
      dispatch(Store.userSettings.saveAsin(params.data.products[0].asin));
      toast.success(t('productList.asin.addedToFavorites'));
    }
  };
  return (
    <button
      className={`flex items-center justify-center text-${isSaved ? 'yellow' : 'gray'}-400 hover:text-yellow-500`}
      onClick={onSaveAsin}
    >
      <Star size={20} fill={isSaved ? 'currentColor' : 'none'} />
    </button>
  );
};

const ImageCell: FC<{ params: GridParams }> = ({ params }) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const [isStockHovered, setIsStockHovered] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="relative inline-block"
    >
      <div className="relative">
        <img
          src={params.data.products[0].amazonImageUrl}
          alt=""
          className="h-16 w-16 rounded object-cover transition-transform duration-300 ease-in-out"
        />
        {params.data.products[0].stock !== undefined && params.data.products[0].stock !== null && (
          <>
            <div
              onMouseEnter={() => setIsStockHovered(true)}
              onMouseLeave={() => setIsStockHovered(false)}
              className={`absolute -right-1 -top-1 h-3 w-3 rounded-full ${
                params.data.products[0].stock ? 'bg-green-500' : 'bg-red-500'
              }`}
            />
            {isStockHovered && (
              <div className="absolute left-1/2 top-1/2 z-50 -translate-x-1/2 -translate-y-1/2 transform whitespace-nowrap rounded bg-gray-900 px-2 py-1 text-xs text-white">
                {params.data.products[0].stock ? t('common.inStock') : t('common.outOfStock')}
              </div>
            )}
          </>
        )}
      </div>
      <ImageTooltip imageUrl={params.data.products[0].amazonImageUrl} isVisible={isHovered} />
    </div>
  );
};

export const ProductList: React.FC<TProductListProps> = ({
  docs,
  gridOptions,
  gridRef,
  meta,
  conditions,
}) => {
  const { t } = useTranslation();
  const { isAdmin } = useUser();
  const [selectedKeepa, setSelectedKeepa] = useState<Product | null>(null);
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState<{ [key: string]: Product[] } | null>(null);
  const [asin, setAsin] = useState<string | null>(null);
  const [showSupplierPrices, setShowSupplierPrices] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const selectedMarketplaces = Store.useAppSelector(
    Store.userSettings.selectors.selectSelectedMarketplaces
  );
  const supplierIndexes = Store.useAppSelector(Store.suppliers.selectors.selectSupplierIndexes);

  const filteredDocs = useMemo(() => {
    return docs
      .map((doc) => ({
        ...doc,
        products: doc.products.filter((product) =>
          selectedMarketplaces?.includes(product.amazonCountry)
        ),
      }))
      .filter((doc) => doc.products.length > 0);
  }, [docs, selectedMarketplaces]);
  const columnDefs = useMemo(
    () => [
      {
        width: 60,
        headerName: t('products.resultTable.starred'),
        cellRenderer: (params: CellRendererParams) => {
          return <SavedAsinsComponent params={params} />;
        },
      },

      {
        headerName: t('products.resultTable.image'),
        cellRenderer: (params: GridParams) => <ImageCell params={params} />,
      },
      {
        headerName: 'ASIN / EAN',
        cellRenderer: (params: CellRendererParams) => (
          <div className="flex flex-col gap-1">
            <div className="relative">
              <AsinTooltip asin={params.data.products?.[0].asin} />
            </div>

            <IdentifierBadge type="EAN" value={params.data.products?.[0].ean} />
          </div>
        ),
      },
      {
        headerName: t('products.title'),
        cellRenderer: (params: CellRendererParams) => (
          <div className="space-y-2">
            {params.data.products.map((product: Product, index: number) => (
              <div key={index} className="flex items-center gap-2">
                <MarketplaceFlag marketplace={product.amazonCountry} />
                <div className="flex-1">
                  <div className="text-sm font-medium">
                    <a
                      href={product.amazonUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                    >
                      {product.title || product.titles?.[0] || product.name}
                    </a>
                  </div>
                  {/* <div className="text-sm text-gray-500">{product.price}€</div> */}
                </div>
              </div>
            ))}
          </div>
        ),
      },
      {
        headerName: t('products.resultTable.amazonPrice'),
        cellRenderer: (params: CellRendererParams) => (
          <div className="space-y-2">
            {params.data.products.map((product: Product, index: number) => (
              <div key={index} className="flex items-center gap-2">
                <MarketplaceFlag marketplace={product.amazonCountry} />
                <div className="flex-1">
                  <div className="text-sm font-medium">{product.amazonPrice.toFixed(2)}€</div>
                  {/* <div className="text-sm text-gray-500">{product.price}€</div> */}
                </div>
              </div>
            ))}
          </div>
        ),
      },
      {
        headerName: t('products.resultTable.supplierPrice'),
        cellRenderer: (params: CellRendererParams) => {
          const mostCommonPriceIndex = findMostCommonPriceIndex(
            params.data.products as unknown as {
              supplierPrice: number;
              lastUpdateDate: Date;
            }[]
          );
          return (
            <div className="flex flex-row items-center justify-end gap-2">
              <div className="text-right">
                <div className="text-sm">
                  <span className="text-xs text-gray-500">VAT excl</span>{' '}
                  {
                    <span>
                      {getSupplierPriceHT({
                        supplier:
                          supplierIndexes[params.data.products?.[mostCommonPriceIndex].supplier],
                        supplierPrice: params.data.products?.[mostCommonPriceIndex].supplierPrice,
                        supplierPriceTTC:
                          params.data.products?.[mostCommonPriceIndex].supplierPriceTTC,
                        supplierPriceHT:
                          params.data.products?.[mostCommonPriceIndex].supplierPriceHT,
                      })?.toFixed(2)}
                      €
                    </span>
                  }
                </div>
                <div className="text-sm">
                  <span className="text-xs text-gray-500">VAT incl</span>{' '}
                  <span>
                    {getSupplierPriceTTC({
                      supplier:
                        supplierIndexes[params.data.products?.[mostCommonPriceIndex].supplier],
                      supplierPrice: params.data.products?.[mostCommonPriceIndex].supplierPrice,
                      supplierPriceTTC:
                        params.data.products?.[mostCommonPriceIndex].supplierPriceTTC,
                      supplierPriceHT: params.data.products?.[mostCommonPriceIndex].supplierPriceHT,
                    })?.toFixed(2)}
                    €
                  </span>
                </div>
              </div>
              <button
                onClick={() => {
                  const groupBySupplier = _.groupBy(params.data.products, 'supplier');
                  setSelectedProduct(groupBySupplier);
                  setAsin(params.data.products?.[0].asin);
                  setShowSupplierPrices(true);
                }}
                className="action-buttons-step rounded-md bg-gray-100 p-1 text-gray-400 shadow-sm hover:text-gray-600"
              >
                <Tooltip content="Voir tous les prix" className="bg-black text-white">
                  <TextSearch size={20} />
                </Tooltip>
              </button>
            </div>
          );
        },
      },
      {
        headerName: t('products.resultTable.profitUnit') + ' € / ROI',
        cellRenderer: (params: CellRendererParams) => (
          <div className="flex flex-col gap-1">
            {params.data.products.map((product: Product, index: number) => (
              <div key={index} className="flex flex-col">
                <div className="flex items-center gap-2">
                  <MarketplaceFlag marketplace={product.amazonCountry} />

                  <ProfitBadgeTooltip product={product} />
                </div>
              </div>
            ))}
          </div>
        ),
      },
      {
        headerName: t('products.resultTable.estimatedSales'),
        cellRenderer: (params: CellRendererParams) => {
          return (
            <div className="flex flex-col gap-1">
              {params.data.products.map((product: Product, index: number) => (
                <div key={index} className="flex flex-col">
                  <div className="flex items-center gap-2">
                    <MarketplaceFlag marketplace={product.amazonCountry} />
                    <div className="text-sm font-medium">
                      {product.estimatedSales}
                      {t('products.resultTable.estimatedSales')}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          );
        },
      },
      {
        headerName: 'Keepa',
        cellRenderer: (params: CellRendererParams) => (
          <div className="flex flex-col gap-1">
            {params.data.products.map((product: Product, index: number) => (
              <div key={index} className="flex flex-col">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setSelectedKeepa(product)}
                    data-popover-target="popover-default"
                    type="button"
                    className="rounded-lg bg-orange-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-orange-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Voir le Keepa
                  </button>
                </div>
              </div>
            ))}
          </div>
        ),
      },
      {
        hide: !isAdmin,
        headerName: 'Détails',
        cellRenderer: (params: CellRendererParams) => (
          <div className="flex flex-col gap-1">
            {params.data.products.map((product: Product, index: number) => (
              <div key={index} className="flex flex-col">
                <div
                  className="flex items-center gap-2"
                  onClick={() => navigate(`/product/${product._id}`)}
                >
                  <MarketplaceFlag marketplace={product.amazonCountry} />
                </div>
              </div>
            ))}
          </div>
        ),
      },
    ],
    [t]
  );

  const handleCSVExport = async () => {
    if (Object.keys(conditions).length === 0) {
      toast.error('Aucune condition sélectionnée');
      return;
    }
    console.log('Exportation de données en cours...');
    setIsExporting(true);
    try {
      const content = await apiClient.products.post('download', conditions);
      const blob = new Blob([content as unknown as BlobPart], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${new Date().getTime()}-exported_data.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('CSV Export failed:', error);
    } finally {
      setIsExporting(false);
    }
  }; 
  


  return (
    <div className="p-4">
      <div className="mb-4 flex items-center justify-between">
        <div className="text-lg font-medium">
          {meta.totalDocs} {t('products.found')}
        </div>

        <button
          disabled={isExporting}
          onClick={handleCSVExport}
          className={`export-produit flex items-center gap-2 rounded-md ${
            isExporting ? 'bg-gray-400' : 'bg-orange-600'
          } px-4 py-2 text-white`}
        >
          {isExporting ? 'Exportation en Cours ...' : t('export')}
        </button>
      </div>
      {selectedKeepa && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-[90vw] max-w-6xl rounded-lg bg-white p-6 shadow-xl">
            <a
              href={`https://keepa.com/#!product/4-${selectedKeepa.asin}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="text-center">
                {COUNTRY_FLAGS[selectedKeepa.amazonCountry as keyof typeof COUNTRY_FLAGS]}{' '}
                {selectedKeepa.title || selectedKeepa.name}
              </div>
              <img
                src={`https://graph.keepa.com/pricehistory.png?asin=${selectedKeepa.asin}&domain=${(
                  selectedKeepa.amazonCountry || ''
                ).toLowerCase()}`}
                alt="Keepa Graph"
                style={{ width: '80%' }}
              />
            </a>
            <button
              onClick={() => setSelectedKeepa(null)}
              className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
            >
              <X size={20} />
            </button>
          </div>
        </div>
      )}
      <div className="ag-theme-alpine h-[600px] w-full">
        <AgGridReact<TGridData>
          ref={gridRef}
          rowData={filteredDocs}
          columnDefs={columnDefs}
          defaultColDef={{
            sortable: true,
            resizable: true,
          }}
          loadingOverlayComponent={LoadingOverlay}
          getRowHeight={(params: AgGridRowHeightParams<TGridData>): number => {
            if (!params.data || params.node.rowIndex === null) return 60;
            const productsCount = params.data.products.length;
            return 60 + productsCount * 40;
          }}
          suppressRowTransform={gridOptions?.suppressRowTransform}
          animateRows={gridOptions?.animateRows}
          //  domLayout="autoHeight"
        />
      </div>

      {selectedProduct && (
        <>
          {showSupplierPrices && (
            <SupplierPrices
              asin={asin as string}
              products={selectedProduct}
              isOpen={showSupplierPrices}
              onClose={() => setShowSupplierPrices(false)}
            />
          )}
        </>
      )}
    </div>
  );
};
