import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import { TUser } from '../../types/user';
import { RootState } from '../index';
export const reducerName = 'users';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await apiClient.user.get('');
  return response.data as TUser[];
});

export const fetchUserById = createAsyncThunk('users/fetchUserById', async (id: string) => {
  const response = await apiClient.user.get(id);
  return response.data as TUser;
});

interface UsersState {
  indexes: {
    [key: string]: TUser;
  };
}

const initialState: UsersState = {
  indexes: {},
};

export const usersSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<TUser[]>) => {
      state.indexes = action.payload.reduce(
        (acc, user) => {
          acc[user._id] = user;
          return acc;
        },
        {} as { [key: string]: TUser }
      );
    });
    builder.addCase(fetchUserById.fulfilled, (state, action: PayloadAction<TUser>) => {
      state.indexes[action.payload._id] = action.payload;
    });
  },
});

const state = (state: RootState) => state[reducerName] as UsersState;
const userIndexes = createSelector(state, (state) => state?.indexes);
const users = createSelector(userIndexes, (indexes) => Object.values(indexes || {}));

export const selectors = {
  userIndexes,
  users,
};
export const actions = usersSlice.actions;
export const reducer = usersSlice.reducer;
