import React, { useState, useRef, useEffect } from 'react';
import { Check, ChevronDown } from 'lucide-react';
import { marketplaces } from '../../types/marketplace';
import { FlagComponent } from '../../components/FlagComponent/FlagComponent';
import * as Store from '../../store';

export const MarketplaceSelector = () => {
  const dispatch = Store.useDispatch();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const allMarketplaceIds = marketplaces.map((m) => m.id);
  const selectedMarketplaces = Store.useAppSelector(
    Store.userSettings.selectors.selectSelectedMarketplaces
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const setSelectedMarketplaces = (marketplaces: string[]) => {
    dispatch(Store.userSettings.actions.setSelectedMarketplaces(marketplaces));
  };

  const toggleMarketplace = (id: string) => {
    if (selectedMarketplaces?.includes(id)) {
      setSelectedMarketplaces(selectedMarketplaces.filter((m) => m !== id));
    } else {
      setSelectedMarketplaces([...selectedMarketplaces, id]);
    }
  };

  const selectAll = () => {
    setSelectedMarketplaces(allMarketplaceIds);
  };

  const getDisplayText = () => {
    if (selectedMarketplaces?.length === marketplaces.length) {
      return (
        <>
          <span className="text-lg">🌎</span>
          <span className="text-sm text-gray-600">Tout</span>
        </>
      );
    }

    return (
      <div className="flex flex-wrap items-center gap-2">
        <div className="flex flex-wrap items-center gap-1">
          {selectedMarketplaces?.map((id) => {
            const marketplace = marketplaces.find((m) => m.id === id);
            return marketplace ? (
              <img
                key={marketplace.id}
                src={marketplace.flag}
                alt={`${marketplace.name} flag`}
                className="h-6 w-6 rounded-full"
              />
            ) : null;
          })}
        </div>
        <span className="text-sm text-gray-600">
          {selectedMarketplaces?.length} marketplace
          {selectedMarketplaces?.length > 1 ? 's' : ''}
        </span>
      </div>
    );
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex w-full items-center gap-2 rounded-lg border border-gray-200 bg-white px-4 py-2 hover:bg-gray-50"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        {getDisplayText()}
        <ChevronDown size={16} className="ml-auto text-gray-400" />
      </button>

      {isOpen && (
        <div
          className="absolute top-full z-50 mt-2 w-full rounded-lg border border-gray-200 bg-white shadow-lg"
          role="listbox"
        >
          <div className="p-2">
            <button
              onClick={selectAll}
              className="flex w-full items-center gap-3 rounded-md px-3 py-2 hover:bg-gray-50"
              aria-selected={selectedMarketplaces?.length === marketplaces.length}
            >
              <div className="flex h-4 w-4 items-center justify-center rounded border border-gray-300">
                {selectedMarketplaces?.length === marketplaces.length && (
                  <Check size={12} className="text-green-500" />
                )}
              </div>
              <span className="text-lg">🌎</span>
              <span className="flex-1 text-left text-sm">Tout</span>
            </button>
            <div className="my-2 border-t border-gray-100"></div>
            {marketplaces.map((marketplace) => (
              <button
                key={marketplace.id}
                onClick={() => toggleMarketplace(marketplace.id)}
                className="flex w-full items-center gap-3 rounded-md px-3 py-2 hover:bg-gray-50"
                aria-selected={selectedMarketplaces?.includes(marketplace.id)}
              >
                <div className="flex h-4 w-4 items-center justify-center rounded border border-gray-300">
                  {selectedMarketplaces?.includes(marketplace.id) && (
                    <Check size={12} className="text-green-500" />
                  )}
                </div>
                <FlagComponent
                  flagSrc={marketplace.flag}
                  countryName={marketplace.name}
                  size="small"
                />
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
