import { z } from 'zod';
import { APIClient } from '../../services/client.service';

// Define the status enums
const SupplierStatus = z.enum(['WAITING', 'PROCESSING', 'FINISHED', 'ACTIVE', 'INACTIVE', 'ERROR']);

const ProcessStatus = z.enum(['WAITING', 'PROCESSING', 'FINISHED', 'ERROR', 'READY_TO_PROCESS']);

// Main supplier schema
export const supplierSchema = z.object({
  name: z.string().optional(),
  email: z.string().email().optional(),
  phoneNumber: z.string().optional(),
  address: z.string().optional(),
  zipcode: z.string().optional(),
  country: z.string().optional(),
  imgUrl: z.string().url().optional(),
  slug: z.string().optional(),
  url: z.string().url().optional(),
  type: z.string().optional(),
  isActive: z.boolean().default(true),
  affilizId: z.string().optional(),
  numberOfProducts: z.number().default(0),
  status: SupplierStatus.default('WAITING'),
  octoParseStatus: z.string().default('WAITING'),
  lastOctoParseStatusUpdate: z.date().default(() => new Date()),
  processStatus: ProcessStatus.default('WAITING'),
  processStartDate: z.date().default(() => new Date()),
  processEndDate: z.date().default(() => new Date()),
  processId: z.string().optional(),
  taskId: z.string().optional(),
  lastUpdateDate: z.date().default(() => new Date()),
  dataProvider: z.string().optional(),
  nextUpdateDates: z.array(z.date()),
  outDateInterval: z.number().default(30),
  updateDateItems: z.array(z.date()).default([new Date()]),
  progress: z.number().min(0).max(100).default(0),
  exportSlug: z.string().optional(),
  cronId: z.string().optional(),
  hasAffiliation: z.boolean().default(false),
});

// Generate types
export type TSupplier = z.infer<typeof supplierSchema> & { _id: string };
export type TSupplierStatus = z.infer<typeof SupplierStatus>;
export type ProcessStatus = z.infer<typeof ProcessStatus>;

// Create a type for required fields when creating a new supplier
export type TSupplierCreate = Partial<TSupplier>;

// Create a type for updating a supplier
export type TSupplierUpdate = Partial<TSupplier>;

// Validation functions
export const validateSupplier = (data: unknown): Omit<TSupplier, '_id'> => {
  return supplierSchema.parse(data);
};

export const validateSupplierUpdate = (data: unknown): TSupplierUpdate => {
  return supplierSchema.partial().parse(data);
};

// Additional validation schema with custom rules
export const supplierSchemaWithValidations = supplierSchema
  .refine(
    (data) => {
      // Validate that processEndDate is after processStartDate
      if (data.processEndDate && data.processStartDate) {
        return data.processEndDate >= data.processStartDate;
      }
      return true;
    },
    {
      message: 'Process end date must be after or equal to process start date',
    }
  )
  .refine(
    (data) => {
      // Validate progress is between 0 and 100
      if (data.progress !== undefined) {
        return data.progress >= 0 && data.progress <= 100;
      }
      return true;
    },
    {
      message: 'Progress must be between 0 and 100',
    }
  );

export class SuppliersApiClient extends APIClient<TSupplier> {
  constructor() {
    super('suppliers');
  }

  public async getOne(id: string) {
    if (!id) {
      throw new Error('Supplier ID is required');
    }

    return this.get(`byId/${id}`);
  }

  public async affilizSuppliers() {
    return this.get('affiliz');
  }

  public async getProcessingSuppliers() {
    return this.get('processing');
  }

  public async linkAffilizSupplier({
    affilizId,
    supplierId,
  }: {
    affilizId: string;
    supplierId: string;
  }) {
    if (!affilizId || !supplierId) {
      throw new Error('Affiliz ID and supplier ID are required');
    }
    return this.put('affiliz', { affilizId, supplierId } as TSupplierUpdate);
  }

  public async updateSupplier(supplierId: string, updates: TSupplierUpdate) {
    if (!supplierId) {
      throw new Error('Supplier ID is required');
    }
    return this.put(`${supplierId}`, updates);
  }

  public async ActivateSupplier(supplierId: string) {
    if (!supplierId) {
      throw new Error('Supplier ID is required');
    }
    return this.post(`${supplierId}/activate`, { isActive: true });
  }

  public async createSupplier(supplier: TSupplierCreate) {
    return this.post('', supplier);
  }
}
