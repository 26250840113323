import React, { useEffect, useState } from 'react';
import { X, ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Product } from '../../types/product';
import * as Store from '../../store';
import { COUNTRY_FLAGS } from '../../types/supplier';
import * as apiClient from '../../apiClient';
import { IdentifierBadge } from './IdentifierBadge';
import { getAffilizzUrl } from '../../utils/filter';
import { getSupplierPriceHT, getSupplierPriceTTC } from '../../utils/price';
// import { useUser } from '../../contexts/UserContext';
interface SupplierPricesProps {
  isOpen: boolean;
  onClose: () => void;
  products: { [key: string]: Product[] };
  asin?: string;
}

export const SupplierPrices: React.FC<SupplierPricesProps> = ({
  isOpen,
  onClose,
  products,
  asin,
}) => {
  // const { hasBasicPlan, hasProPlan } = useUser();
  const { t } = useTranslation();
  const [priceOptions, setPriceOptions] = useState<
    {
      supplier: string;
      supplierPrice: number;
      supplierPriceHT: number;
      supplierPriceTTC: number;
      supplierUrl: string;
      supplierCountry: string;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = Store.useDispatch();
  const supplierIndexes = Store.useAppSelector(Store.suppliers.selectors.selectSupplierIndexes);

  useEffect(() => {
    dispatch(Store.suppliers.fetchSuppliers());
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    apiClient.products.get('price-options/' + asin).then((res) => {
      setPriceOptions(
        res.data as unknown as {
          supplier: string;
          supplierPrice: number;
          supplierPriceHT: number;
          supplierPriceTTC: number;
          supplierUrl: string;
          supplierCountry: string;
        }[]
      );
      setIsLoading(false);
    });
  }, [asin]);
  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 z-40 bg-black bg-opacity-25 transition-opacity"
          onClick={onClose}
        />
      )}

      <div
        className={`fixed bottom-0 right-0 top-0 z-50 w-[500px] transform bg-white shadow-xl transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'} `}
      >
        <div className="flex h-full flex-col">
          <div className="flex items-center justify-between border-b border-gray-100 p-6">
            <h2 className="text-xl font-semibold">{t('products.comparePrices')}</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X size={24} />
            </button>
          </div>

          <div className="border-b border-gray-100 p-6">
            <div className="flex gap-4">
              <img
                src={Object.values(products || {})[0]?.[0]?.amazonImageUrl}
                alt=""
                className="h-20 w-20 rounded-lg object-cover"
              />
              <div>
                <h3 className="mb-2 text-lg font-medium">
                  {Object.values(products || {})[0]?.[0]?.title}
                </h3>
                <div className="flex gap-4">
                  <div className="flex items-center gap-1">
                    <IdentifierBadge
                      type="ASIN"
                      value={Object.values(products || {})[0]?.[0]?.asin}
                    />
                    {/* <span className="rounded-md border border-gray-200 bg-gray-100 px-2 py-0.5 text-xs text-gray-500 shadow-sm">
                      ASIN
                    </span>
                    <span className="text-sm">{Object.values(products)[0]?.[0]?.asin}</span> */}
                  </div>
                  <div className="flex items-center gap-1">
                    <IdentifierBadge
                      type="EAN"
                      value={Object.values(products || {})[0]?.[0]?.ean}
                    />
                    {/* <span className="rounded-md border border-gray-200 bg-gray-100 px-2 py-0.5 text-xs text-gray-500 shadow-sm">
                      EAN
                    </span>
                    <span className="text-sm">{Object.values(products)[0]?.[0]?.ean}</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto">
            <div className="divide-y divide-gray-100">
              {isLoading ? (
                <div className="flex items-center justify-center p-8">
                  <div className="h-8 w-8 animate-spin rounded-full border-4 border-gray-200 border-t-orange-500" />
                </div>
              ) : (
                priceOptions
                  .sort((a, b) => a.supplierPrice - b.supplierPrice)
                  .map((priceOption) => (
                    <div
                      key={priceOption.supplier}
                      className="flex items-center justify-between p-4 hover:bg-gray-50"
                    >
                      <div className="flex items-center gap-2">
                        <span>
                          {
                            COUNTRY_FLAGS[
                              (supplierIndexes?.[priceOption.supplier]?.country ||
                                'FR') as keyof typeof COUNTRY_FLAGS
                            ]
                          }
                        </span>
                        <span className="font-medium">
                          {supplierIndexes[priceOption?.supplier]?.name}
                        </span>
                      </div>
                      <div className="flex items-center gap-6">
                        <div className="flex-row text-right">
                          <div className="flex justify-end gap-2 text-sm">
                            <span className="text-gray-500">{t('common.vatExcl')}</span>
                            <span>
                              {getSupplierPriceHT({
                                supplier: supplierIndexes[priceOption?.supplier],
                                supplierPrice: priceOption?.supplierPrice,
                                supplierPriceHT: priceOption?.supplierPriceHT,
                                supplierPriceTTC: priceOption?.supplierPriceTTC,
                              })?.toFixed(2)}
                              €
                            </span>
                          </div>
                          <div className="flex justify-end gap-2 text-sm">
                            <span className="text-gray-500">{t('common.vatIncl')}</span>
                            <span>
                              {getSupplierPriceTTC({
                                supplier: supplierIndexes[priceOption?.supplier],
                                supplierPrice: priceOption?.supplierPrice,
                                supplierPriceHT: priceOption?.supplierPriceHT,
                                supplierPriceTTC: priceOption?.supplierPriceTTC,
                              })?.toFixed(2)}
                              €
                            </span>
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            window.open(
                              getAffilizzUrl(
                                priceOption.supplier,
                                priceOption.supplierUrl,
                                supplierIndexes
                              ),
                              '_blank'
                            );
                          }}
                          className="flex items-center gap-1 text-orange-500 hover:text-orange-600"
                        >
                          <span>{t('common.buy')}</span>
                          <ChevronRight size={16} />
                        </button>
                      </div>
                    </div>
                  ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
