import React from 'react';
import { Search } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface SearchBarProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = ({ placeholder, value, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className="relative flex-1">
      <Search
        className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-400"
        size={20}
      />
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder || t('common.keywords')}
        className="w-full rounded-lg border border-gray-200 py-2 pl-10 pr-4 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
      />
    </div>
  );
};
