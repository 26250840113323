import React from 'react';

interface FlagComponentProps {
  flagSrc: string; // Chemin de l'image
  countryName: string;
  size?: 'extrasmall' | 'small' | 'medium' | 'large'; // Taille optionnelle
}

export const FlagComponent: React.FC<FlagComponentProps> = ({
  flagSrc,
  countryName,
  size = 'medium',
}) => {
  let flagSize: string;
  switch (size) {
    case 'extrasmall':
      flagSize = 'w-4 h-4';
      break;
    case 'small':
      flagSize = 'w-6 h-6';
      break;
    case 'medium':
      flagSize = 'w-8 h-8';
      break;
    case 'large':
      flagSize = 'w-12 h-12';
      break;
    default:
      flagSize = 'w-8 h-8';
  }

  return (
    <div className="flex items-center gap-2" role="img" aria-label={`Flag of ${countryName}`}>
      <div
        className={`rounded-full ${flagSize} flex items-center justify-center border border-gray-200 bg-white`}
      >
        <img
          src={flagSrc}
          alt={`${countryName} flag`}
          className="h-full w-full rounded-full object-cover"
          onError={(e) => {
            e.currentTarget.src = '/assets/default-flag.png';
          }}
        />
      </div>
      <span className="text-sm font-medium text-gray-700">{countryName}</span>
    </div>
  );
};
