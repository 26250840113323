import React from 'react';
import { useTranslation } from 'react-i18next';

interface StatCardProps {
  icon: React.ReactNode;
  label: string;
  value: string | number;
  suffix?: string;
}

export const StatCard: React.FC<StatCardProps> = ({ icon, label, value, suffix }) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-lg bg-white p-4 shadow-sm">
      <div className="mb-2 flex items-center gap-3">
        {icon}
        <span className="text-sm text-gray-600">{t(label)}</span>
      </div>
      <div className="text-xl font-semibold">
        {value}
        {suffix && <span className="ml-1 text-sm text-gray-500">{suffix}</span>}
      </div>
    </div>
  );
};
