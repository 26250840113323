import React from 'react';

interface PageHeaderProps {
  title: string;
  description?: string;
  actions?: React.ReactNode;
  children?: React.ReactNode;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
  actions,
  children,
}) => {
  return (
    <div className="border-b border-gray-100 p-6">
      <div className="mb-4 flex items-start justify-between">
        <div>
          <h1 className="mb-2 text-2xl font-semibold">{title}</h1>
          {description && <p className="text-gray-600">{description}</p>}
        </div>
        {actions && <div>{actions}</div>}
      </div>
      {children}
    </div>
  );
};
