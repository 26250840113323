import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Search, ArrowRight } from 'lucide-react';
import { format } from 'date-fns';
import { useAppSelector } from '../../store';
import * as Store from '../../store';
import { TSavedSearch } from '../../apiClient/userSettings/userSettings';

export const SavedSearches: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const savedSearches = useAppSelector(Store.userSettings.selectors.selectSavedSearches);
  const savedSearchIds = useAppSelector(Store.userSettings.selectors.selectSavedSearchIds);

  const handleSearchClick = (search: TSavedSearch) => {
    navigate('/', { state: { searchQuery: search.conditions } });
  };

  return (
    <div className="rounded-lg border border-gray-100 bg-white shadow-sm">
      <div className="border-b border-gray-100 p-6">
        <div className="mb-2 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Search size={20} className="text-gray-400" />
            <h2 className="text-lg font-medium">{t('dashboard.savedSearches.title')}</h2>
          </div>
          <button
            onClick={() => navigate('/saved-searches')}
            className="flex items-center gap-1 text-sm text-orange-500 hover:text-orange-600"
          >
            {t('common.viewAll')} <ArrowRight size={16} />
          </button>
        </div>
        <p className="text-sm text-gray-500">{t('dashboard.savedSearches.description')}</p>
      </div>
      <div className="divide-y divide-gray-100">
        {savedSearchIds.slice(0, 5).map((searchId) => {
          const search = savedSearches[searchId];
          if (!search) return null;
          return (
            <button
              key={search._id}
              onClick={() => handleSearchClick(search)}
              className="group flex w-full items-center justify-between p-4 text-left transition-colors hover:bg-gray-50"
            >
              <div>
                <div className="mb-1 font-medium transition-colors group-hover:text-orange-500">
                  {search.name}
                </div>
                <div className="text-sm text-gray-500">
                  {format(new Date(search?.dateOfCreation), 'PP')}
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};
