import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { User, Users, LogOut, ArrowLeft, MessageSquare } from 'lucide-react';
//import { useUser } from '../../contexts/UserContext';

interface ProfileTooltipProps {
  collapse: boolean;
  isAdmin: boolean;
  swichModeActive: boolean;
  switchBackToAdmin: (callback: () => void) => void;
  handleLogoutClick: () => void;
  user: {
    firstName: string;
    lastName: string;
  };
}

export const ProfileTooltip: React.FC<ProfileTooltipProps> = ({
  collapse,
  isAdmin,
  swichModeActive,
  switchBackToAdmin,
  handleLogoutClick,
  user 
  
}) => {
  const [isOPen, setIsOPen] = useState(false);
  const navigate = useNavigate(); 
  const [position, setPosition] = useState<{ top: number; left: number } | null>(null);
   const [isSelected, setIsSelected] = useState(false); 



  const handleMouseEnter = (e: React.MouseEvent) => { 
    console.log("ici mon sourie ets la ")  
   setIsSelected(true);
   const { top, left, width } = e.currentTarget.getBoundingClientRect();
   setPosition({ top: top + window.scrollY, left: left + window.scrollX + width / 2 });
    setIsOPen(true);
  };

  const handleMouseLeave = () => { 
   console.log("ici mon souris sort ") 
   setIsSelected(false);
    setIsOPen(false);
  };

  if (!collapse) return null;

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-orange-500 text-white">
      {user.firstName.charAt(0)}
      {user.lastName.charAt(0)}
      </div>
      {isOPen && position && isSelected &&
        ReactDOM.createPortal(
          <div
            className="rounded-lg bg-white p-3 text-black shadow-lg"
            style={{
                position: 'fixed',
                top: `${position.top -100}px`,
                left: `${position.left}px`,
                zIndex: 9999,
                whiteSpace: 'nowrap', 
               // color :'orange' 
            //   minWidth: '300px'
              }}
          >
            <div className="py-1">
              <button
                onClick={() => {
                  navigate('/account');
                  setIsOPen(true);
                }}
                className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
              >
                <User size={16} />
                Modifier Profil
              </button>
              {isAdmin && (
                <button
                  onClick={() => {
                    navigate('/admin/users');
                    setIsOPen(false);
                  }}
                  className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
                >
                  <Users size={16} />
                  Lister les utilisateurs
                </button>
              )}
              {swichModeActive && (
                <>
                  <button
                    onClick={() => {
                      switchBackToAdmin(() => {
                        navigate('/admin/users');
                        setIsOPen(false);
                      });
                    }}
                    className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
                  >
                    <ArrowLeft size={16} />
                    Revenir à l'admin
                  </button>
                  {isAdmin && (
                    <button
                      onClick={() => setIsOPen(false)}
                      className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
                    >
                      <MessageSquare size={16} />
                      Messages Admin
                    </button>
                  )}
                </>
              )}
              <div className="my-1 h-px bg-gray-100" />
              <button
                onClick={handleLogoutClick}
                className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-red-600 hover:bg-red-50"
              >
                <LogOut size={16} />
                Déconnexion
              </button>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};
