import { APIClient } from '../../services/client.service';

export type TSubscription = {
    client_secret: string;
    price_id: string;
    url?: string;
}
export class SubscriptionApiClient extends APIClient<TSubscription> {
  constructor() {
    super('/subscriptions');
  }
}

