import React, { useState, useEffect } from 'react';
import Tour from 'reactour';
export const AppTour: React.FC<{ isOpen: boolean; onCompleted: () => void }> = ({
  isOpen,
  onCompleted,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Cette logique garantit que l'état est bien récupéré avant de décider d'afficher le tour.
    setLoading(false);
  }, []);

  if (loading || !isOpen) {
    return null;
  }

  const steps = [
    {
      selector: '.welcome-step',
      content: (
        <>
          Adieu, les recherches sans fin. <br />
          Bienvenue chez Resell Corner. <br />
          Resell Corner vous facilite la recherche de produits rentables à vendre sur Amazon.
        </>
      ),
    },
    {
      selector: '.sidebar-step',
      content: 'Voici le menu de navigation pour accéder aux différentes sections.',
    },
    {
      selector: '.chrome-extension',
      content: "Vous pouvez télécharger l'extension chrome  ",
    },
    {
      selector: '.search-bar-step',
      content: (
        <>
          Trouvez les produits les plus rentables en un clic <br />
          ou accédez au filtres avancés : Prix, Profit, Ventes, ROI, Inclusion/Exclusion EAN,
          Fournisseurs...
        </>
      ),
    },

    {
      selector: '.secteur-bar-step',
      content: (
        <>Nous vous proposons ici des produits des secteurs qui pourraient vous intéresser.</>
      ),
    },
    {
      selector: '.export-produit',
      content: <>Vous pouvez exporter vos produits en un click .</>,
    },
    {
      selector: '.action-buttons-step',
      content: 'Visualiser rapidement le prix d’un même produit chez plus de 250 fournisseurs.',
    },
  ];
  console.log('mon composant app tour ici ');

  return (
    <Tour
      steps={steps}
      isOpen={isOpen}
      onRequestClose={onCompleted} // on ferme le tour
      accentColor="#f97316" // Une Couleur personnalisée
      showCloseButton // on affiche  bouton fermer
      showNavigation // on affiche la navigation
      rounded={10} // un  Coins arrondis
      disableInteraction={false}
      lastStepNextButton={
        <button
          onClick={onCompleted}
          style={{
            backgroundColor: '#f97316',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '3px',
            cursor: 'pointer',
            fontSize: '12px',
          }}
        >
          Quitter
        </button>
      }
      //  stepInteraction
      prevButton={<button>Précédent</button>}
      nextButton={
        <button
          style={{
            backgroundColor: '#f97316',
            color: 'white',
            padding: '5px 10px',
            // border: 'none',
            borderRadius: '3px',
            cursor: 'pointer',
            fontSize: '12px',
            //  transition: 'background-color 0.3s ease',
          }}
        >
          Suivant
        </button>
      }
    />
  );
};
