import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../common/Modal/index';
// import { FileDropzone } from '../common/FileDropzone/FileDropzone';
import CSVReader from 'react-csv-reader';
interface AddScanModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFileLoaded: (data: string[][], file?: File) => void;
}

export const AddScanModal: React.FC<AddScanModalProps> = ({ isOpen, onClose, onFileLoaded }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('scanList.addModal.title')}>
      <div className="p-6">
        <CSVReader
          cssClass="form-control"
          onError={(e) => console.error('Error:', e)}
          onFileLoaded={(data, fileInfo, file) => {
            onFileLoaded(data, file);
            onClose();
          }}
        />
        {/* <FileDropzone
          onFileLoaded={(data, file) => {
            onFileLoaded(data, file);
            onClose();
          }}
        /> */}
      </div>
    </Modal>
  );
};
