import React ,{useState ,useEffect}  from 'react' 
import {produit ,abonment2  ,amazon} from '../../assets/images/carrousel'
import { ArrowRight  , ArrowLeft } from 'lucide-react'; 


export const Carrousel   = () =>{  


    const [currentIndex, setCurrentIndex] = useState(0); 

    useEffect(() => {
        const interval = setInterval(goToNext, 6000); 
        return () => clearInterval(interval); 
      }, []);

    const carouselItems = [
      {
        imgSrc: produit,
        alt: 'Image 1',
        title: (
          <>
           Trouvez vos produits <br />
          <span className="text-orange-500">
          au meilleur prix</span>  avec Resell Corner
          </>
        ),
        description: (
          <>
           Notre plateforme vous permet de cibler directement<br />
            les articles les plus rentables, simplifiant <br /> considérablement votre travail sur Amazon.
          </>
        ),
      },
      {
        imgSrc: amazon,
        alt: 'Image 2',
        title: (
          <>
            Concentrez-vous sur l’essentiel :<br/> <span className='text-orange-500'>votre business</span>
          </>
        ),
        description: (
          <>
            Avec Resell Corner, ouvrez la porte à des <br/>
             opportunités d'arbitrage uniques qui transformeront <br/>
             les résultats de votre business sur Amazon, en vous <br/>
             permettant de surclasser la concurrence et de <br/>
              maximiser votre profit.
          </>
        ),
      },
      {
        imgSrc: abonment2,
        alt: 'Image 3',
        title: (
          <>
           Tirez profit de Resell Corner<br/> 
           pour<span className='text-orange-500'> maximiser vos revenus</span>
          </>
        ),
        description: (
          <>
           Avec Resell Corner, acheter au prix le plus bas <br/> 
            devient une certitude, pas une chance. Prenez des <br/> 
             décisions d'achat éclairées et augmentez vos <br/> 
             marges en sélectionnant systématiquement l'offre la <br/>
              plus avantageuse disponible sur le marché.
          </>
        ),
      },
     
    ];
  
    
    const goToNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    };
  
    const goToPrevious = () => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + carouselItems.length) % carouselItems.length
      );
    };
  
    
    return( 
        <div className=" bg-white p-16 lg:flex lg:flex-1">
       <div className="relative max-w-lg mx-auto text-center">

   
    <div className="mb-6 ">
      <h1 className="text-[32px] font-bold mb-4 text-left">
        {carouselItems[currentIndex].title}
      </h1>
      <p className="text-base text-left">
        {carouselItems[currentIndex].description}
      </p>
    </div>

  
    <div className="overflow-hidden relative rounded-lg">
    
      <img
        src={carouselItems[currentIndex].imgSrc}
        alt={carouselItems[currentIndex].alt}
        className="w-full h-auto object-contain max-w-[240px] mx-auto" 
      />
    </div>

   
    <button
      onClick={goToPrevious}
      className="absolute top-1/2 left-4 text-orange   p-2 rounded-full"
    >
       <ArrowLeft />
    </button>
    <button
      onClick={goToNext}
      className="absolute top-1/2 right-4 text-red  p-2 rounded-full"
    >
     <ArrowRight/>
    </button>
  </div>
</div>

    )
}