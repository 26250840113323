import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'lucide-react';
import { MarketplaceSelector } from '../Marketplace/MarketplaceSelector';
import { UploadMapping } from './UploadMapping/UploadMapping';
import { AddScanModal } from './AddScanModal';
import { AgGridReact } from 'ag-grid-react';

interface ScanUploadModalProps {
  onClose: () => void;
  onMappingValidateOrClosed: (data: { title?: string; price?: string; id?: string }) => void;
  data: string[][];
  onInputUploadValidateOrClosed: (data?: string[][], file?: File) => void;
  onDataUploadValidateOrClosed: (validated: boolean) => void;
  isMappingModalOpen: boolean;
  isInputUploadModalOpen: boolean;
  isDataUploadModalOpen: boolean;
  mapping: { [key: string]: string };
  title: string;
  onTitleChange: (title: string) => void;
}

export const ScanUploadModal: React.FC<ScanUploadModalProps> = ({
  onClose,
  onMappingValidateOrClosed,
  onInputUploadValidateOrClosed,
  onDataUploadValidateOrClosed,
  data,
  isMappingModalOpen,
  isInputUploadModalOpen,
  isDataUploadModalOpen,
  mapping,
  title,
  onTitleChange,
}) => {
  const { t } = useTranslation();

  const [header, ...rows] = data;
  const mockFileData = [header, rows[0]];

  const uploadedProducts = useMemo(() => {
    if (!mapping.id || !mapping.title || !mapping.price) return [];
    if (
      !header.includes(mapping.id) ||
      !header.includes(mapping.title) ||
      !header.includes(mapping.price)
    )
      return [];
    const idIndex = header.indexOf(mapping.id);
    const titleIndex = header.indexOf(mapping.title);
    const priceIndex = header.indexOf(mapping.price);
    return rows.map((row) => ({
      id: row[idIndex],
      title: row[titleIndex],
      price: row[priceIndex],
    }));
  }, [header, mapping.id, mapping.title, mapping.price, rows]);

  if (!isDataUploadModalOpen && !isInputUploadModalOpen && !isMappingModalOpen) return null;

  return (
    <>
      {isDataUploadModalOpen && (
        <>
          <div className="fixed inset-0 z-40 bg-black bg-opacity-25" onClick={onClose} />
          <div className="fixed inset-x-0 top-[10%] z-50 mx-auto max-w-3xl rounded-lg bg-white shadow-xl">
            <div className="flex max-h-[80vh] flex-col">
              <div className="flex items-center justify-between border-b border-gray-100 p-6">
                <h2 className="text-xl font-semibold">{t('scans.addScan')}</h2>
                <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
                  <X size={24} />
                </button>
              </div>

              <div className="space-y-6 p-6">
                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      {t('scans.scanTitle')}
                    </label>
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => onTitleChange(e.target.value)}
                      className="w-full rounded-lg border border-gray-200 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
                    />
                  </div>
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      {t('scans.affectedMarketplaces')} <span className="text-red-500">*</span>
                    </label>
                    <MarketplaceSelector />
                  </div>
                </div>

                <div className="ag-theme-alpine h-[400px] w-full">
                  <AgGridReact
                    rowData={uploadedProducts}
                    columnDefs={[
                      {
                        field: 'id',
                        headerName: 'ID',
                        flex: 1,
                      },
                      {
                        field: 'title',
                        headerName: t('products.title'),
                        flex: 2,
                      },
                      {
                        field: 'price',
                        headerName: t('products.price'),
                        flex: 1,
                        cellClass: 'text-right',
                      },
                    ]}
                    defaultColDef={{
                      sortable: true,
                      filter: true,
                    }}
                  />
                </div>
              </div>

              <div className="flex justify-end gap-3 border-t border-gray-100 bg-gray-50 p-6">
                <button
                  onClick={() => onDataUploadValidateOrClosed(false)}
                  className="rounded-lg px-4 py-2 text-gray-600 hover:bg-gray-100"
                >
                  {t('common.cancel')}
                </button>
                <button
                  onClick={() => onDataUploadValidateOrClosed(true)}
                  className="rounded-lg bg-orange-500 px-4 py-2 text-white hover:bg-orange-600"
                >
                  {t('common.validate')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {isMappingModalOpen && (
        <UploadMapping
          isOpen={isMappingModalOpen}
          onClose={() => onMappingValidateOrClosed({})}
          onValidate={onMappingValidateOrClosed}
          fileData={mockFileData}
        />
      )}
      {isInputUploadModalOpen && (
        <AddScanModal
          isOpen={isInputUploadModalOpen}
          onClose={() => {
            onInputUploadValidateOrClosed();
          }}
          onFileLoaded={(_data, file) => {
            onInputUploadValidateOrClosed(_data, file);
          }}
        />
      )}
    </>
  );
};
