import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalLink } from 'lucide-react';
import * as apiClient from '../../apiClient';
import { toast } from 'react-hot-toast';
import * as Store from '../../store';
import { TUser } from '../../types/user';
import { LWA_CLIENT_ID } from '../../constants/constants';
/*interface AmazonSellerFormProps {
  onNext: () => void;
  onPrevious: () => void;
}
  */

export const AmazonSellerForm = () => {
  const { t } = useTranslation();
  const dispatch = Store.useDispatch();
  const user: TUser | null = Store.useAppSelector(Store.auth.selectUser);

  const sendCodeToBackend = useCallback(async (oauthCode: string, sellerPartnerId: string) => {
    try {
      const response = await apiClient.user.post('sellerapi', {
        spapi_oauth_code: oauthCode,
        selling_partner_id: sellerPartnerId,
      });

      if ((response as unknown as { data: boolean }).data === true) {
        toast.success('Amazon Seller account connected successfully!');
      }

      // if (response.status === 200) {
      //   toastr.success(
      //     'Amazon Seller account connected successfully!',
      //     'Success'
      //   )

      //   // history.push('/dashboard') // Redirect to dashboard after successful connection
      // }
    } catch (error: unknown) {
      console.log(error);

      toast.error('Error connecting to Amazon Seller');
      // alert('Failed to connect to Amazon Seller account.')
    }
  }, []);

  useEffect(() => {
    dispatch(Store.auth.fetchCurrentUser());
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const spapi_oauth_code = searchParams.get('spapi_oauth_code');
    const selling_partner_id = searchParams.get('selling_partner_id');

    // If we have both parameters, send them to the backend
    if (spapi_oauth_code && selling_partner_id) {
      sendCodeToBackend(spapi_oauth_code, selling_partner_id);
    }
  }, [sendCodeToBackend]);

  const handleConnect = () => {
    // const clientId = 'YOUR_CLIENT_ID' // Remplacez par votre ID client SP-API
    const redirectUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}`; // URL de redirection sur votre serveur
    const sellingPartnerId = LWA_CLIENT_ID; // ID de votre app
    const authUrl = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${sellingPartnerId}&state=login&redirect_uri=${encodeURIComponent(
      redirectUri
    )}`;

    // Rediriger vers Amazon pour l'authentification
    window.location.href = authUrl;
  };

  return (
    <div className="mt-8 flex flex-col items-center">
      <h2 className="mb-8 text-2xl font-semibold">{t('auth.seller.title')}</h2>
      <p className="mb-8 text-center text-gray-600">{t('auth.seller.description')}</p>

      {!user?.isSellerApiConnected ? (
        <div className="flex flex-col items-center gap-8">
          <button
            onClick={handleConnect}
            className="flex items-center gap-2 rounded-lg bg-orange-500 px-6 py-3 text-white transition-colors hover:bg-orange-600"
          >
            <img
              alt="Amazon Seller"
              width="20px"
              height="20px"
              src="https://upload.wikimedia.org/wikipedia/commons/4/4a/Amazon_icon.svg"
            />{' '}
            {t('auth.seller.connectButton')}
          </button>

          <div className="text-center">
            <p className="mb-2 text-gray-500">{t('auth.seller.noAccount')}</p>
            <p className="flex items-center gap-1">
              <a
                href="https://sellercentral.amazon.com/gp/mws/registration/register-your-account.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('auth.seller.createAccount')}
              </a>
              <ExternalLink size={16} />
            </p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-8">
          <button
            disabled
            className="flex cursor-not-allowed items-center gap-2 rounded-lg bg-green-500 px-6 py-3 text-white opacity-75"
          >
            {t('auth.seller.yourSellerAccountIsConnected')}
          </button>
        </div>
      )}
    </div>
  );
};
