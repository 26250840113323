import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarChart2, Search, List } from 'lucide-react';
import { PageLayout } from '../components/common/PageLayout';
import { PageHeader } from '../components/common/PageHeader';
import { MetricCard } from '../components/Dashboard/MetricCard';
import { SavedSearches } from '../components/Dashboard/SavedSearches';
import { RecentProducts } from '../components/Dashboard/RecentProducts';
import { RecentScans } from '../components/Dashboard/RecentScans';
import * as Store from '../store';
import * as apiClient from '../apiClient';
import { useUser } from '../contexts/UserContext';

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = Store.useDispatch();
  const [stats, setStats] = useState<{
    productSearch: { [date: string]: number };
    searchCount: number;
  }>();
  const { isAdmin } = useUser();

  const searchCount = stats?.searchCount;
  const totalProducts = Object.values(stats?.productSearch || {}).reduce(
    (acc, curr) => acc + curr,
    0
  );
  const scans = Store.useAppSelector(Store.scans.selectors.selectIds);

  useEffect(() => {
    dispatch(Store.userSettings.fetchUserSettings());
    dispatch(Store.scans.fetchScans());
  }, [dispatch]);

  useEffect(() => {
    apiClient.user.get('stats').then((res) => {
      console.log({ res });
      setStats(
        res.data as unknown as { productSearch: { [date: string]: number }; searchCount: number }
      );
    });
  }, [dispatch]);

  return (
    <PageLayout
      header={<PageHeader title={t('dashboard.title')} description={t('dashboard.description')} />}
    >
      <div className="space-y-6">
        {isAdmin && (
          <button
            onClick={() => {
              const error = new Error('Manually captured error in React !!!!');
              // Sentry.captureException(error);
              throw error;
              // Send it to Sentry
            }}
            className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600"
          >
            Throw Test Error
          </button>
        )}
        <div className="grid grid-cols-4 gap-6">
          <MetricCard
            icon={BarChart2}
            label={t('dashboard.metrics.totalProducts')}
            value={(totalProducts || 0) + ''}
            // trend={12}
          />
          <MetricCard
            icon={Search}
            label={t('dashboard.metrics.activeSearches')}
            value={(searchCount || 0) + ''}
          />
          <MetricCard
            icon={List}
            label={t('dashboard.metrics.recentScans')}
            value={(scans?.length || 0) + ''}
            // trend={15}
          />
          {/* <MetricCard
            icon={DollarSign}
            label={t('dashboard.metrics.potentialProfit')}
            value="€24,500"
            trend={23}
          /> */}
        </div>

        <div className="grid grid-cols-2 gap-6">
          <RecentProducts />
          <SavedSearches />
        </div>

        <RecentScans />
      </div>
    </PageLayout>
  );
};
