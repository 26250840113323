import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AffilizSuppliersGrid } from '../components/AffilizSuppliers/AffilizSuppliersGrid';
import { PageLayout } from '../components/common/PageLayout';
import { PageHeader } from '../components/common/PageHeader';
import { SearchToolbar } from '../components/common/SearchToolbar';
import { useUser } from '../contexts/UserContext';
import { useSelector } from 'react-redux';
import * as Store from '../store';
import { TAffilizSupplier } from '../store/suppliers/suppliers';
import toast from 'react-hot-toast';
import { TSupplier } from '../apiClient/suppliers/suppliers';

export const AffilizSuppliersPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = Store.useDispatch();
  const _affilizCollection = useSelector(Store.suppliers.selectors.selectUnusedAffilizIndexes);
  const [searchValue, setSearchValue] = useState('');
  const { isAdmin } = useUser();
  const affilizCollection = useMemo(() => {
    return Object.values(_affilizCollection)
      .map((affiliz) => ({
        ...affiliz,
        country: null,
        type: null,
      }))
      .filter((supplier) => Boolean(supplier.shopName));
  }, [_affilizCollection]);

  const handleAddSupplier = async (data: Partial<TSupplier>) => {
    try {
      dispatch(Store.suppliers.createSupplier(data));
      toast.success(t('affiliz.addSupplierSuccess'));
    } catch (error: unknown) {
      console.error(error);
      toast.error(t('affiliz.addSupplierError'));
    }
  };

  if (!isAdmin) {
    return <div>You are not authorized to access this page</div>;
  }

  return (
    <PageLayout
      header={
        <PageHeader title={t('affiliz.title')} description={t('affiliz.description')}>
          <SearchToolbar
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            onFilterClick={() => {}}
            onSearch={() => {}}
            placeholder={t('affiliz.searchPlaceholder')}
          />
        </PageHeader>
      }
    >
      <AffilizSuppliersGrid
        suppliers={affilizCollection as TAffilizSupplier[]}
        onAddSupplier={handleAddSupplier}
      />
    </PageLayout>
  );
};
