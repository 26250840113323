import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Store from '../../store';
import { AmazonSellerForm } from '../Auth/AmazonSellerForm';
interface EmptyStateProps {
  onFileLoaded?: (data: string[][], file?: File) => void;
}

export const EmptyState: React.FC<EmptyStateProps> = () => {
  const user = Store.useAppSelector(Store.auth.selectUser);

  const { t } = useTranslation();

  return (
    <>
      {user?.isSellerApiConnected ? (
        <div className="relative z-50 flex flex-col items-center justify-center p-8">
          <h3 className="mb-2 text-lg font-medium">{t('scanList.emptyState.title')}</h3>
          <p className="mb-8 text-sm text-gray-600">{t('scanList.emptyState.description')}</p>
          {/* <FileDropzone onFileLoaded={onFileLoaded} className="w-full max-w-xl" /> */}
        </div>
      ) : (
        <AmazonSellerForm />
      )}
    </>
  );
};
