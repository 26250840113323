import React, { useState, useRef, useEffect } from 'react';
import { Lock, Search, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import * as Store from '../../store';

interface SelectProps {
  label: string;
  placeholder: string;
  options: { value: string; label: string; isPro?: boolean }[];
  value: string[];
  onChange: (value: string | string[]) => void;
  isMulti?: boolean;
  hasSearch?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  isMulti = false,
  hasSearch = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const selectRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const user = Store.useAppSelector(Store.auth.selectUser);
  const hasPremiumPlan = user?.hasPremiumPlan;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  const handleSelect = (optionValue: string, isPro?: boolean) => {
    if (isPro && !hasPremiumPlan) {
      navigate('/account?tab=subscription');
      setIsOpen(false);
      return;
    }

    if (isMulti) {
      const newValue = value.includes(optionValue)
        ? value.filter((v) => v !== optionValue)
        : [...value, optionValue];
      onChange(newValue);
    } else {
      onChange([optionValue]);
      setIsOpen(false);
    }
    setSearchTerm('');
  };

  const handleRemoveValue = (optionValue: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent opening dropdown
    const newValue = value.filter((v) => v !== optionValue);
    onChange(newValue);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative" ref={selectRef}>
      <label className="mb-1 block text-sm font-medium text-gray-700">{label}</label>
      <div
        role="combobox"
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        aria-labelledby={label}
        tabIndex={0}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            setIsOpen(!isOpen);
          }
        }}
        className="w-full cursor-pointer rounded-lg border border-gray-300 bg-white px-3 py-2 text-left text-sm focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-200"
      >
        {value.length > 0 ? (
          <div className="flex flex-wrap gap-1">
            {options
              .filter((option) => value.includes(option.value))
              .map((option) => (
                <span
                  key={option.value}
                  className="inline-flex items-center gap-1 rounded bg-gray-100 px-2 py-0.5"
                >
                  {option.label}
                  {isMulti && (
                    <button
                      type="button"
                      onClick={(e) => handleRemoveValue(option.value, e)}
                      className="ml-1 rounded-full p-0.5 hover:bg-gray-200"
                    >
                      <X className="h-3 w-3" />
                    </button>
                  )}
                </span>
              ))}
          </div>
        ) : (
          placeholder
        )}
      </div>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-full rounded-lg border border-gray-200 bg-white shadow-lg">
          {hasSearch && (
            <div className="p-2">
              <div className="relative">
                <Search className="absolute left-2 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
                <input
                  ref={searchInputRef}
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full rounded-md border border-gray-300 py-1.5 pl-8 pr-3 text-sm focus:border-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-200"
                  placeholder="Search..."
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            </div>
          )}
          <div className="max-h-60 overflow-auto py-1">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <button
                  key={option.value}
                  type="button"
                  onClick={() => handleSelect(option.value, option.isPro)}
                  className={`flex w-full items-center justify-between px-4 py-2 text-left text-sm hover:bg-gray-100 ${
                    (!isMulti && value[0] === option.value) ||
                    (isMulti && value.includes(option.value))
                      ? 'bg-orange-50 text-orange-600'
                      : ''
                  } ${option.isPro && !hasPremiumPlan ? 'cursor-not-allowed opacity-50' : ''}`}
                >
                  <span>{option.label}</span>
                  {option.isPro && !hasPremiumPlan && <Lock className="h-4 w-4" />}
                </button>
              ))
            ) : (
              <div className="px-4 py-2 text-sm text-gray-500">No results found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
