import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { Copy, UserCog, Check, X, Clock, Shield, User as UserIcon, TestTube2 } from 'lucide-react';
import { UserRole, TUser } from '../../../types/user';
import { useUser } from '../../../contexts/UserContext';
import toast from 'react-hot-toast';
import * as Store from '../../../store';
import * as apiClient from '../../../apiClient';
import { IRowNode, Column, ICellRendererParams, ColDef } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';

interface UserListGridProps {
  users: TUser[];
}

type SelectFieldName = 'planStatus' | 'plan' | 'roles';
type PlanType = 'FREE' | 'BASIC' | 'PREMIUM';
type RoleType = 'USER' | 'ADMIN' | 'TESTER';
type StatusType = 'active' | 'inactive' | 'canceled' | 'trialing' | 'past_due' | 'unpaid';

type IconsType = {
  plan: Record<PlanType, React.ReactNode>;
  roles: Record<RoleType, React.ReactNode>;
  planStatus: Record<StatusType, React.ReactNode>;
};

export const selectFieldsIcon: IconsType = {
  plan: {
    FREE: <UserIcon size={16} />,
    BASIC: <UserCog size={16} />,
    PREMIUM: <Shield size={16} />,
  },
  roles: {
    USER: <UserIcon size={16} />,
    ADMIN: <Shield size={16} />,
    TESTER: <TestTube2 size={16} />,
  },
  planStatus: {
    active: <Check size={16} />,
    inactive: <X size={16} />,
    canceled: <X size={16} />,
    trialing: <Clock size={16} />,
    past_due: <X size={16} />,
    unpaid: <X size={16} />,
  },
};

const CopyCell: React.FC<{ value: string }> = ({ value }) => {
  const { t } = useTranslation();

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    toast.success(t('common.copied'));
  };

  return (
    <div className="flex items-center gap-2">
      <span>{value}</span>
      <button onClick={handleCopy} className="text-gray-400 hover:text-gray-600">
        <Copy size={14} />
      </button>
    </div>
  );
};

export const selectFieldsOptions: Record<SelectFieldName, string[]> = {
  planStatus: ['active', 'inactive', 'canceled', 'trialing', 'past_due', 'unpaid'],
  plan: ['FREE', 'BASIC', 'PREMIUM'],
  roles: ['USER', 'CUSTOMER', 'ADMIN', 'TESTER'],
};

interface RoleEditorProps {
  value: string[];
  api: {
    stopEditing: () => void;
    refreshCells: (params: { rowNodes: IRowNode[]; force?: boolean }) => void;
  };
  column: Column;
  node: IRowNode;
}

const RoleEditor = forwardRef((props: RoleEditorProps, ref) => {
  const [selectedRoles, setSelectedRoles] = useState<string[]>(props.value || []);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return selectedRoles;
      },
      isPopup() {
        return true;
      },
    };
  });

  const roles = Object.values(UserRole);

  const isSelected = (role: string) => selectedRoles.includes(role);

  const toggleRole = (role: string) => {
    setSelectedRoles((prev) => {
      const newRoles = isSelected(role) ? prev.filter((r) => r !== role) : [...prev, role];
      return newRoles;
    });
  };

  const getRoleIcon = (role: string) => {
    switch (role) {
      case UserRole.ADMIN:
        return <Shield size={16} />;
      case UserRole.USER:
        return <UserIcon size={16} />;
      case UserRole.TESTER:
        return <TestTube2 size={16} />;
      default:
        return null;
    }
  };

  return (
    <div className="flex gap-2 rounded-lg bg-white p-2 shadow-lg">
      {roles.map((role) => (
        <button
          key={role}
          type="button"
          onClick={() => toggleRole(role)}
          className={`flex items-center gap-1 rounded px-2 py-1 ${
            isSelected(role) ? 'bg-orange-100 text-orange-600' : 'bg-gray-100 text-gray-600'
          }`}
        >
          {getRoleIcon(role)}
          {role}
        </button>
      ))}
    </div>
  );
});

RoleEditor.displayName = 'RoleEditor';

export const UserListGrid: React.FC<UserListGridProps> = ({ users }) => {
  const dispatch = Store.useDispatch();
  const { switchToUser, isAdmin } = useUser();
  const navigate = useNavigate();
  const baseColDef: Partial<ColDef<TUser>> = {
    valueGetter: (params) => params.data?.[params.colDef.field as keyof TUser],
    valueSetter: (params) => {
      const field = params.colDef.field!;
      const id = params.data?._id;
      const value = params.newValue;
      console.log('params.newValue', params.newValue);
      if (value === undefined || value === null) return false;
      if (!id) return false;
      apiClient.user
        .put(id, { [field]: params.newValue })
        .then(() => {
          toast.success(`La mise à jour de ${field.toUpperCase()} a été effectuée`);
          dispatch(Store.users.fetchUsers());
        })
        .catch((err) => {
          toast.error(`Erreur pendant la mise à jour de ${field.toUpperCase()}`);
          console.error('err', err);
        });

      return true;
    },
  };

  const columnDefs: ColDef<TUser>[] = [
    {
      field: '_id',
      headerName: 'ID',
      editable: false,
      cellRenderer: (params: ICellRendererParams<TUser>) => <CopyCell {...params} />,
    },
    {
      field: 'email',
      headerName: 'Email',
      editable: false,
      filter: true,
      cellRenderer: (params: ICellRendererParams<TUser>) => <CopyCell {...params} />,
    },
    {
      field: 'firstName',
      ...baseColDef,
      headerName: 'First Name',
      editable: isAdmin,
      filter: true,
    },
    {
      field: 'companyName',
      ...baseColDef,
      headerName: 'Company Name',
      editable: isAdmin,
    },
    {
      field: 'phoneNumber',
      ...baseColDef,
      headerName: 'Phone Number',
      cellRenderer: CopyCell,
      editable: isAdmin,
      filter: true,
    },
    {
      field: 'stripeCustomerId',
      headerName: 'Stripe Customer ID',
      ...baseColDef,
      cellRenderer: CopyCell,
      editable: isAdmin,
    },
    {
      field: 'createdAt',
      filter: 'agDateColumnFilter',
      headerName: 'Date de création',
      editable: false,
    },
    {
      field: 'plan',
      headerName: 'Abonnement',
      ...baseColDef,
      editable: isAdmin,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['FREE', 'BASIC', 'PREMIUM'],
      },

      // cellRenderer: (params: ICellRendererParams<TUser>) => (
      //   <SelectCell defaultValue="FREE" params={params} />
      // ),
    },
    {
      field: 'planStatus',
      headerName: 'Statut Abonnement',
      ...baseColDef,
      editable: isAdmin,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['active', 'inactive', 'canceled', 'trialing', 'past_due', 'unpaid'],
      },
      // cellRenderer: (params: ICellRendererParams<TUser>) => (
      //   <SelectCell defaultValue="inactive" params={params} />
      // ),
    },
    {
      field: 'planStartAt',
      ...baseColDef,
      filter: 'agDateColumnFilter',
      headerName: 'Début Abonnement',
      editable: isAdmin,
      cellEditor: 'agDateCellEditor',
    },
    {
      field: 'planEndAt',
      ...baseColDef,
      filter: 'agDateColumnFilter',
      headerName: 'Fin Abonnement',
      cellEditor: 'agDateCellEditor',
      editable: isAdmin,
    },
    {
      field: 'roles',
      ...baseColDef,
      headerName: 'Roles',
      editable: isAdmin,
      cellEditor: RoleEditor,
      // cellRenderer: (params: ICellRendererParams<TUser>) => (
      //   <SelectCell defaultValue={['USER']} params={params} isMulti />
      // ),
    },
    {
      headerName: '',
      width: 100,
      cellRenderer: (params: ICellRendererParams<TUser>) => (
        <button
          onClick={() =>
            params.data?._id &&
            switchToUser(params.data._id, () => {
              navigate('/');
            })
          }
          className="rounded px-3 py-1 text-sm text-orange-500 hover:bg-orange-50"
        >
          <UserCog size={16} />
        </button>
      ),
    },
  ];

  return (
    <div className="ag-theme-alpine h-[600px] w-full">
      <AgGridReact
        rowData={users}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          resizable: true,
        }}
      />
    </div>
  );
};
