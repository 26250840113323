export interface Marketplace {
  id: string;
  name: string;
  flag: string; // Chemin vers l'image du drapeau
  domain: string;
}

import { France, UnitedKingdom, Germany, Spain, Italy } from '../assets/images/flags';

export const marketplaces: Marketplace[] = [
  { id: 'FR', name: 'France', flag: France, domain: 'amazon.fr' },
  { id: 'UK', name: 'United Kingdom', flag: UnitedKingdom, domain: 'amazon.co.uk' },
  { id: 'DE', name: 'Germany', flag: Germany, domain: 'amazon.de' },
  { id: 'ES', name: 'Spain', flag: Spain, domain: 'amazon.es' },
  { id: 'IT', name: 'Italy', flag: Italy, domain: 'amazon.it' },
];
