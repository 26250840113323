import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFilters } from './SearchFilters';

interface SearchHeaderProps {
  onSubmit: (values: Record<string, unknown>) => void;
  conditions: string;
}

export const SearchHeader: React.FC<SearchHeaderProps> = ({ onSubmit, conditions }) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-lg bg-white shadow-sm">
      <div className="p-6">
        <div className="flex flex-col items-start justify-between gap-4 md:flex-row md:items-center">
          <div>
            <h1 className="mb-2 text-2xl font-semibold">{t('search.title')}</h1>
            <p className="text-gray-600">{t('search.description')}</p>
          </div>
          <SearchFilters onSubmit={onSubmit} conditions={conditions} />
        </div>
      </div>
    </div>
  );
};
