import { APIClient } from '../../services/client.service';
import { TUser } from '../../types/user';

export class AuthService extends APIClient<TUser> {
  constructor() {
    super('');
  }

  public async login({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<{ data: TUser; token: string }> {
    const response = await this.post('login', { email, password });
    return response as unknown as { data: TUser; token: string };
  }

  /**
   * signup
   */
  public signup(user: Partial<TUser>) {
    if (user.email && user.password && user.firstName && user.lastName) {
      return this.post('signup', user);
    }
    return Promise.reject(new Error('Invalid user data'));
  }

  public logout() {
    return this.post('logout', {});
  }

  public forgotPassword(email: string) {
    return this.post('forgot-password', { email });
  }

  public resetPassword(data: { token: string; password: string; id: string }) {
    return this.post('reset-password', data);
  }

  public emailVerification(token: string) {
    return this.post('verify-email', { token } as Partial<TUser>);
  }

  public getCurrentUser() {
    return this.get('users/current');
  }

  public updateUser(userId: string, userData: Partial<TUser>) {
    return this.put(`users/${userId}`, userData);
  } 

  public contactSupport(data: { email: string; message: string; firstName: string; lastName: string }) {
    return this.post('support', data);
  }
  
}
