import { APIClient } from '../services/client.service';
import { AuthService } from './auth/auth';
import { ProductsApiClient } from './products/products';
import { ScansApiClient } from './scans/scans';
import { SubscriptionApiClient } from './subscription/subscription';
import { SuppliersApiClient } from './suppliers/suppliers';
import { userSettings } from './userSettings/userSettings';
import { User } from './user/user';
const auth = new AuthService();
const base = new APIClient();
const products = new ProductsApiClient();
const suppliers = new SuppliersApiClient();
const scans = new ScansApiClient();
const subscription = new SubscriptionApiClient();
const user = new User();
export { auth, base, products, suppliers, scans, userSettings, subscription, user };
