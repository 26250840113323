export const EXPORT_FIELDS = {
  "ASIN": "ASIN",
  "Categorie": "Categorie",
  "Categories": "Categories",
  "Devise des frais totaux": "Devise des frais totaux",
  "EAN": "EAN",
  "Frais totaux": "Frais totaux",
  "Fulfillment Amazon": "Fulfillment Amazon",
  "Image Amazon": "Image Amazon",
  "Lien Amazon": "Lien Amazon",
  "Lien Fournisseur": "Lien Fournisseur",
  "Marketplace": "Marketplace",
  "Meilleur classement": "Meilleur classement",
  "Prix Amazon": "Prix Amazon",
  "Prix Fournisseur": "Prix Fournisseur",
  "Profit": "Profit",
  "ROI": "ROI",
  "Titre": "Titre",
  "Type de vendeur": "Type de vendeur",
  "UPC": "UPC"
} as const; 