import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { X } from 'lucide-react';
import { ColumnMapping } from './ColumnMapping';

interface UploadMappingProps {
  isOpen: boolean;
  onClose: () => void;
  fileData: string[][];
  onValidate: (mapping: { [key: string]: string }) => void;
}

export const UploadMapping: React.FC<UploadMappingProps> = ({
  isOpen,
  onClose,
  fileData,
  onValidate,
}) => {
  const { t } = useTranslation();
  const [mapping, setMapping] = useState<{ [key: string]: string }>({
    title: '',
    price: '',
    id: '',
  });

  const getValue = (key: string) => {
    const index = fileData?.[0]?.indexOf(key);

    return index !== -1 ? fileData?.[1]?.[index] : '';
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-40 bg-black bg-opacity-25" onClick={onClose} />
      <div className="fixed inset-x-0 top-[10%] z-50 mx-auto max-w-3xl rounded-lg bg-white shadow-xl">
        <div className="flex max-h-[80vh] flex-col">
          <div className="flex items-center justify-between border-b border-gray-100 p-6">
            <h2 className="text-xl font-semibold">{t('scans.mapping.title')}</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X size={24} />
            </button>
          </div>

          <div className="p-6">
            <p className="mb-6 text-gray-600">{t('scans.mapping.description')}</p>

            <div className="space-y-4">
              {Object.keys(mapping).map((key, index) => (
                <ColumnMapping
                  key={index}
                  fieldName={key}
                  fieldValue={mapping[key]}
                  options={fileData?.[0] || []}
                  value={getValue(mapping[key])}
                  onChange={(fieldName, value) => setMapping({ ...mapping, [fieldName]: value })}
                />
              ))}
            </div>
          </div>

          <div className="flex justify-end gap-3 border-t border-gray-100 bg-gray-50 p-6">
            <button
              onClick={onClose}
              className="rounded-lg px-4 py-2 text-gray-600 hover:bg-gray-100"
            >
              {t('common.cancel')}
            </button>
            <button
              onClick={() => onValidate(mapping)}
              disabled={!mapping.id || !mapping.price || !mapping.title}
              className={`rounded-lg px-4 py-2 ${
                !mapping.id || !mapping.price || !mapping.title
                  ? 'cursor-not-allowed bg-gray-300 text-gray-500'
                  : 'bg-orange-500 text-white hover:bg-orange-600'
              }`}
            >
              {t('common.validate')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
