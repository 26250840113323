import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { format } from 'date-fns';
import { CopiableCell } from './CopiableCell';
import { StatusCell } from './StatusCell';
import { AffilizSelector } from './AffilizSelector';
import { ActionCell } from './ActionCell';
import { COUNTRY_FLAGS, COUNTRY_NAMES, SupplierStatus } from '../../../types/supplier';
import { useUser } from '../../../contexts/UserContext';
import { TSupplier } from '../../../apiClient/suppliers/suppliers';
import {
  ValueSetterParams,
  ValueFormatterParams,
  ICellRendererParams,
  ColDef,
} from 'ag-grid-community';
import { useSelector } from 'react-redux';
import * as Store from '../../../store';
import { Modal } from '../../common/Modal';
import { TProcessingSupplier } from '../../../store/suppliers/suppliers';

interface SupplierGridProps {
  suppliers: Record<string, TSupplier>;
  processingData: Record<string, TProcessingSupplier>;
  onStatusClick: (supplier: TSupplier) => void;
  onProcess: (id: string) => void;
  onToggleActive: (id: string, isActive: boolean) => void;
  onSupplierUpdate: (id: string, updates: Partial<TSupplier>) => void;
}

type SupplierColDef = ColDef<TSupplier>;

const ProcessingDataModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  data: Record<string, string>;
  suppliers: Record<string, TSupplier>;
}> = ({ isOpen, onClose, data, suppliers }) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Processing Details" size="md">
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              Key
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
              Value
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">{key}</td>
              <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                {key === 'supplier'
                  ? suppliers[value]?.name
                  : typeof value === 'object'
                    ? JSON.stringify(value)
                    : String(value)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Modal>
);

export const SupplierGrid: React.FC<SupplierGridProps> = ({
  suppliers,
  processingData,
  onStatusClick,
  onProcess,
  onToggleActive,
  onSupplierUpdate,
}) => {
  const { t } = useTranslation();
  const { isAdmin } = useUser();
  const affilizIndexes = useSelector(Store.suppliers.selectors.selectAffilizIndexes);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProcessingData, setSelectedProcessingData] = useState<Record<string, string>>({});
  const [editingAffilizId, setEditingAffilizId] = useState<string | null>(null);

  const handleProcessDataClick = (data: Record<string, string>) => {
    setSelectedProcessingData(data);
    setIsModalOpen(true);
  };

  const columnDefs = useMemo<SupplierColDef[]>(() => {
    const valueSetter = (params: ValueSetterParams<TSupplier>) => {
      const field = params.colDef.field as keyof TSupplier;
      onSupplierUpdate(params.data._id, { [field]: params.newValue });
      return true;
    };

    return [
      {
        field: '_id',
        headerName: 'ID',
        cellRenderer: CopiableCell,
        hide: !isAdmin,
        editable: false,
      },
      {
        field: 'affilizId',
        headerName: 'Affiliz ID',
        hide: !isAdmin,
        editable: false,
        cellRenderer: (params: ICellRendererParams) =>
          editingAffilizId === params.data._id ? (
            <AffilizSelector
              value={params.value}
              supplierId={params.data._id}
              onCancel={() => setEditingAffilizId(null)}
              onSuccess={() => setEditingAffilizId(null)}
            />
          ) : (
            <div
              onDoubleClick={() => setEditingAffilizId(params.data._id)}
              className="cursor-pointer"
            >
              {affilizIndexes[params.value]?.shopName
                ? `${affilizIndexes[params.value].shopName} (${params.value})`
                : 'NOT SET YET'}
            </div>
          ),
      },
      {
        field: 'name',
        headerName: t('suppliers.name'),
        valueSetter,
      },
      {
        field: 'country',
        headerName: t('suppliers.country'),
        valueFormatter: (params: ValueFormatterParams) =>
          `${COUNTRY_FLAGS[params.value as keyof typeof COUNTRY_FLAGS]} ${COUNTRY_NAMES[params.value as keyof typeof COUNTRY_NAMES]}`,
        valueSetter,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: Object.keys(COUNTRY_FLAGS),
        },
      },
      {
        field: 'type',
        headerName: t('suppliers.type'),
        editable: isAdmin,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['supplier', 'professional'],
        },
        valueFormatter: (params: ValueFormatterParams) =>
          params.value === 'supplier' ? 'Site Marchand' : 'Site professionnel',
        valueSetter,
      },
      {
        field: 'numberOfProducts',
        headerName: t('suppliers.productCount'),
        editable: false,
        valueFormatter: (params: ValueFormatterParams) => params.value.toLocaleString(),
      },
      {
        field: 'status',
        headerName: t('suppliers.status'),
        cellRenderer: StatusCell,
        cellRendererParams: {
          processingData,
          onStatusClick,
          onProcessDataClick: handleProcessDataClick,
        },
        hide: !isAdmin,
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: Object.values(SupplierStatus || {}),
        },
        valueSetter,
      },
      {
        field: 'lastUpdateDate',
        headerName: t('suppliers.lastUpdate'),
        valueFormatter: (params: ValueFormatterParams) => format(new Date(params.value), 'PP'),
        editable: false,
      },
      {
        field: 'outDateInterval',
        headerName: 'Cleaning Interval',
        hide: !isAdmin,
        valueSetter,
      },
      {
        field: 'hasAffiliation',
        headerName: t('suppliers.affiliated'),
        valueFormatter: (params: ValueFormatterParams) =>
          params.value ? t('common.yes') : t('common.no'),
        cellEditor: 'agCheckboxCellEditor',
        valueSetter,
        hide: !isAdmin,
      },
      {
        headerName: t('suppliers.actions'),
        cellRenderer: ActionCell,
        cellRendererParams: {
          onProcess,
          onToggleActive,
        },
        editable: false,
        hide: !isAdmin,
      },
    ];
  }, [
    isAdmin,
    t,
    onSupplierUpdate,
    processingData,
    onStatusClick,
    onProcess,
    onToggleActive,
    editingAffilizId,
    affilizIndexes,
  ]);

  return (
    <div className="ag-theme-alpine h-[600px] w-full">
      <ProcessingDataModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data={selectedProcessingData}
        suppliers={suppliers}
      />
      <AgGridReact<TSupplier>
        rowData={Object.values(suppliers || {})}
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: true,
          resizable: true,
          editable: true,
          filter: true,
        }}
        pagination={true}
        paginationPageSize={50}
      />
    </div>
  );
};
