import React from 'react';
import { TrendingUp } from 'lucide-react';
import { LucideIcon } from 'lucide-react';

interface MetricCardProps {
  icon: LucideIcon;
  label: string;
  value: string | number;
  trend?: number;
}

export const MetricCard: React.FC<MetricCardProps> = ({ icon: Icon, label, value, trend }) => (
  <div className="rounded-lg border border-gray-100 bg-white p-6 shadow-sm">
    <div className="mb-4 flex items-center justify-between">
      <div className="rounded-lg bg-orange-50 p-2 text-orange-500">
        <Icon size={24} />
      </div>
      {trend && (
        <div className="flex items-center gap-1 text-sm text-green-500">
          <TrendingUp size={16} />
          <span>+{trend}%</span>
        </div>
      )}
    </div>
    <div className="mb-1 text-2xl font-semibold">{value}</div>
    <div className="text-sm text-gray-500">{label}</div>
  </div>
);
