import React from 'react';

interface MarketplaceFlagProps {
  marketplace: string;
}

const flagEmoji: Record<string, string> = {
  FR: '🇫🇷',
  UK: '🇬🇧',
  DE: '🇩🇪',
  ES: '🇪🇸',
  IT: '🇮🇹',
  ALL: '🌎',
};

export const MarketplaceFlag: React.FC<MarketplaceFlagProps> = ({ marketplace }) => (
  <span className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-50">
    {flagEmoji[marketplace]}
  </span>
);
