import { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import * as apiClient from '../apiClient';
import { toast } from 'react-hot-toast';
import { TGridData } from '../components/ProductList/ProductList';
import { useNavigate } from 'react-router-dom';

export const useProductSearch = (gridRef?: React.RefObject<AgGridReact>) => {
  const navigate = useNavigate();

  const [conditions, setConditions] = useState<Record<string, unknown>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<{
    docs: TGridData[];
    meta: {
      hasNextPage: boolean;
      hasPrevPage: boolean;
      limit: number;
      nextPage: number;
      page: number;
      pagingCounter: number;
      prevPage: number;
      totalDocs: number;
      totalPages: number;
    };
  }>({
    docs: [],
    meta: {
      hasNextPage: false,
      hasPrevPage: false,
      limit: 0,
      nextPage: 0,
      page: 0,
      pagingCounter: 0,
      prevPage: 0,
      totalDocs: 0,
      totalPages: 0,
    },
  });

  const paginateSearch = async (page: number, poConditions?: Record<string, unknown>) => {
    if (!conditions && !poConditions) return;
    navigate('', { state: { searchQuery: poConditions || conditions }, replace: true });
    const searchConditions = poConditions || conditions;
    if (Object.keys(searchConditions || {}).length === 0) return;
    setIsLoading(true);
    gridRef?.current?.api.showLoadingOverlay();

    try {
      const res = await apiClient.products.findByConditions('search', {
        conditions: poConditions || conditions,
        paginationOption: { page },
        group: {
          _id: '$asin',
          count: { $first: '$count' }, // Assuming you want to keep some count
          profit: { $first: '$profit' }, // Preserve the first profit value
          lastUpdateDate: { $first: '$lastUpdateDate' }, // Preserve the first last update date
        },
        sort: {
          accuracy: -1,
          brandFound: -1,
          lastUpdateDate: -1,
          profit: -1,
        },
      });
      setData(res.data);
    } catch (err: unknown) {
      console.error(err);
      toast.error(err as string);
    } finally {
      setIsLoading(false);
      gridRef?.current?.api.hideOverlay();
    }
  };

  const handleSubmit = (values: Record<string, unknown>) => {
    setConditions(values);
    paginateSearch(1, values);
  };

  return {
    data,
    handleSubmit,

    paginateSearch,
    isLoading,

    conditions,
  };
};
