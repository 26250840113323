import { useMemo } from 'react';
import { TGridData } from '../ProductList/ProductList';

export const useProductGridConfig = () => {
  const gridOptions = useMemo(() => ({
          getRowHeight: (params: { data?: TGridData }) => {
            // Calculer la hauteur en fonction du nombre de produits
            const productsCount = params.data?.products?.length || 0;
            const baseHeight = 60; // Hauteur minimum
            const heightPerProduct = 40; // Hauteur additionnelle par produit

            return baseHeight + productsCount * heightPerProduct;
          },
          // Autres options de la grille
          suppressRowTransform: true, // Pour éviter les problèmes de rendu avec les hauteurs variables
          animateRows: true, // Animation fluide lors des changements de hauteur
        }), []);

  return { gridOptions };
}; 