import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { ProfitBadge } from './ProfitBadge';

interface Product {
  profit: number;
  roi: number;
  supplierPriceTTC?: number;
  supplierPrice?: number;
}
interface ProfitBadgeTooltipProps {
  product: Product;
}

export const ProfitBadgeTooltip: React.FC<ProfitBadgeTooltipProps> = ({ product }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState<{ top: number; left: number } | null>(null);

  const handleMouseEnter = (e: React.MouseEvent) => {
    const { top, left } = e.currentTarget.getBoundingClientRect();
    setPosition({ top, left });
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <ProfitBadge value={product?.profit || 0} percentage={product?.roi || 0} />
      {isOpen &&
        position &&
        ReactDOM.createPortal(
          <div
            className="rounded-lg bg-black p-3 text-white shadow-lg"
            style={{
              position: 'fixed',
              top: `${position.top + 20}px`,
              left: `${position.left + 20}px`,
              zIndex: 9999,
              minWidth: '70px',
              borderRadius: '10px',
              // padding: '10px',
            }}
          >
            <div className="text-center">
              <span className="text-sm">
                Calcul du ROI : <br />({product.profit} /{' '}
                {product.supplierPriceTTC || product.supplierPrice}) x 100 = {product.roi}
              </span>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};
