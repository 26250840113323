import { useEffect, useState } from 'react';
import type { TSubscription } from '../../apiClient/subscription/subscription';
import { subscription } from '../../apiClient';
import { STRIPE_PUBLISHABLE_KEY, STRIPE_TABLE_PRICE_ID } from '../../constants/constants';

type StripePricingTableProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
> & {
  'pricing-table-id'?: string;
  'publishable-key'?: string;
  'customer-session-client-secret'?: string;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': StripePricingTableProps;
    }
  }
}

export const PriceTable = (): JSX.Element => {
  const [clientSecret, setClientSecret] = useState<string>('');
  const [isLoaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    subscription
      .get('session')
      .then((response: { data: TSubscription | TSubscription[] }) => {
        const data = Array.isArray(response.data) ? response.data[0] : response.data;
        setClientSecret(data.client_secret);
      })
      .catch((error: Error) => {
        console.error('error', error);
      })
      .finally(() => {
        setLoaded(true);
      });

    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <stripe-pricing-table
      pricing-table-id={STRIPE_TABLE_PRICE_ID}
      publishable-key={STRIPE_PUBLISHABLE_KEY}
      customer-session-client-secret={clientSecret}
    />
  );
};
