import React, { useState } from 'react';
import { Tv, ExternalLink } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from './Tooltip';

interface VideoCardProps {
  collapse: boolean;
}

export const VideoCard: React.FC<VideoCardProps> = ({collapse}) => {
  const { t } = useTranslation();

  // La Gestion de l'état de la vidéo sélectionnée ici
  const [isSelected, setIsSelected] = useState(false);

  const handleMouseEnter = () => {
    setIsSelected(true); // Lorsque la souris entre dans l'élément ici
  };

  const handleMouseLeave = () => {
    setIsSelected(false); // Lorsque la souris quitte l'élément ici
  };

  return (
    <div
      className={`relative mx-4 mb-4 cursor-pointer rounded-lg bg-gray-50 p-4 ${isSelected ? 'bg-gray-200' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        onClick={() => {
          window.open('https://www.resellcorner.io/tutoriels', '_blank');
        }}
        className="mb-2 flex items-center justify-between"
      >
        <div className="flex items-center gap-2">
        <Tooltip tooltipText="Vidéos">
            <Tv size={18} className="text-gray-600 -ml-2" />
          </Tooltip>
          {!collapse && (
            <div className="text-sm font-medium">{t('sidebar.videos')}</div>
          )}
        </div>
       {!collapse && (
         <a
         href="https://www.resellcorner.io/tutoriels"
         target="_blank"
         className="flex items-center gap-1 text-xs text-gray-600 hover:text-gray-800"
       >
         <ExternalLink size={12} />
       </a>
       )}
      </div>

      {!collapse && (
        <p className="font-inter mb-3 text-sm leading-5 text-gray-400">
        {t('sidebar.videosDescription')}
      </p>
      )}
    </div>
  );
};
