import React from 'react';
import * as Images from '../../assets/images';
import { Carrousel } from './Carrousel';
interface AuthLayoutProps {
  children: React.ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  // const { t } = useTranslation();

  return (
    <div className="flex min-h-screen flex-col bg-gray-50 lg:flex-row">
      <div className="flex-2 flex items-center justify-center bg-gray-100 lg:w-4/6">
        <div className="mx-auto w-full max-w-[800px] p-8 lg:max-w-[1000px] lg:p-16">
          <img src={Images.LogoImage} alt="Resell Cornerr" className="mb-16 h-8" />
          {children}
        </div>
      </div>
      <div className="flex-1.5 bg-white lg:w-2/6">
        <Carrousel />
      </div>
    </div>
  );
};
