import { Loader } from "lucide-react";

const LoadingOverlay = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-gray-50">
      <Loader className="animate-spin h-10 w-10 text-gray-500" />
      <span className="text-gray-600 mt-2">Loading data...</span>
    </div>
  );
}

export  {LoadingOverlay};