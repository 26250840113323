import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TScan } from '../../apiClient/scans/scans';
import * as apiClient from '../../apiClient';
import { RootState } from '..';
import { AxiosRequestConfig } from 'axios';

export const reducerName = 'scans';

export type TScansState = {
  indexes: {
    [key: string]: TScan;
  };
  ids: string[];
};
export const fetchScans = createAsyncThunk(`${reducerName}/fetchScans`, async () => {
  const scans = await apiClient.scans.fetchScans();
  return scans;
});

export const updateScan = createAsyncThunk<TScan, { id: string; data: Partial<TScan> }>(
  `${reducerName}/updateScan`,
  async ({ id, data }) => {
    const response = await apiClient.scans.post(`${id}`, data);
    return response.data as TScan;
  }
);

export const fetchScanById = createAsyncThunk<TScan, string>(
  `${reducerName}/fetchScanById`,
  async (id) => {
    const response = await apiClient.scans.fetchById(id);
    return response as TScan;
  }
);

export const createScan = createAsyncThunk<TScan, { data: FormData; options?: AxiosRequestConfig }>(
  `${reducerName}/createScan`,
  async ({ data, options }) => {
    const response = await apiClient.scans.createScan(data, options);
    return response as TScan;
  }
);

interface DownloadScanParams {
  fileId: string;
  type: string;
}

interface PreviewScanParams {
  fileId: string;
}

export const previewScan = createAsyncThunk<TScan, PreviewScanParams>(
  `${reducerName}/previewScan`,
  async ({ fileId }) => {
    const response = await apiClient.base.get('products/scan/preview/?fileId=' + fileId);
    return response.data as TScan;
  }
);

export const downloadScan = createAsyncThunk<TScan, DownloadScanParams>(
  `${reducerName}/downloadScan`,
  async ({ fileId, type }) => {
    const response = await apiClient.scans.post('download', {
      fileId,
      type,
    });
    return response.data as TScan;
  }
);
export const scansSlice = createSlice({
  name: reducerName,
  initialState: {
    indexes: {},
    ids: [],
  } as TScansState,
  reducers: {
    reset: () => ({
      indexes: {},
      ids: [],
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScans.fulfilled, (state, action) => {
      const indexes = (action.payload as TScan[]).reduce(
        (acc: TScansState['indexes'], scan) => {
          acc[scan._id] = scan;
          return acc;
        },
        {} as TScansState['indexes']
      );
      state.indexes = indexes;
      state.ids = Object.keys(indexes);
    });
    builder.addCase(fetchScanById.fulfilled, (state, { payload }) => {
      state.indexes[payload._id] = payload;
      state.ids = Object.keys(state.indexes);
    });
    builder.addCase(createScan.fulfilled, (state, { payload }) => {
      state.indexes[payload._id] = payload;
      state.ids = Object.keys(state.indexes);
    });
    builder.addCase(updateScan.fulfilled, (state, { payload }) => {
      state.indexes[payload._id] = payload;
    });
  },
});

const selectState = (state: RootState) => state[reducerName] as TScansState;
const selectIndexes = (state: RootState) => selectState(state)?.indexes;
const selectIds = (state: RootState) => selectState(state)?.ids;
const selectScanCollection = (state: RootState) => Object.values(selectIndexes(state));

export const { reset } = scansSlice.actions;
export const reducer = scansSlice.reducer;
export const selectors = {
  selectState,
  selectIndexes,
  selectIds,
  selectScanCollection,
};
