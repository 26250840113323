import React, { useState } from 'react';
import { ExternalLink } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import {Tooltip} from './Tooltip'
import { Chrome } from '../../assets/images'; 

interface ChromeExtensionProps {
  collapse: boolean;
}
export const ChromeExtension: React.FC<ChromeExtensionProps> = ({collapse}) => {
  const { t } = useTranslation();

  const [isInstalled, setIsInstalled] = useState(false);

  const handleInstallClick = () => {
    setIsInstalled(true);
  };

  return ( 
    <div>
   {collapse ? 
    <Tooltip tooltipText="Installer l'extension chrome">
   <a
    href="https://chromewebstore.google.com/detail/resellcorner-asin-fetcher/bbjibbialgdojaohpiinbgijgochcoog"
    className="flex items-center gap-2 ml-5"
    onClick={handleInstallClick} 
    target='_blank' 
    
  >
    <img src={Chrome} alt="Logo Google Chrome" style={{ width: 20, height: 20 }} />
  </a> </Tooltip> :  ( <div
    className="mx-4 mb-4 rounded-lg p-4 text-white chrome-extension"
    style={{
      background: 'linear-gradient(111.27deg, rgba(35, 39, 46, 0.9) 16%, #23272E 100%)',
    }}
  >
    <div className="mb-4 flex items-center justify-between">
      <div className="flex items-center gap-2">
        <img src={Chrome} alt="Logo Google Chrome" style={{ width: 20, height: 20 }} />
       { !collapse && ( <span className="text-sm font-medium">{t('sidebar.chromeExtension')}</span>)}
      </div>

     { !collapse && (
       <a
       href="https://chromewebstore.google.com/detail/resellcorner-asin-fetcher/bbjibbialgdojaohpiinbgijgochcoog"
       className="flex items-center gap-1 text-white hover:text-gray-400"
       onClick={handleInstallClick}
     >
       <ExternalLink size={12} />
     </a>
     )}
    </div>

   { !collapse && ( <p className="mb-3 text-sm text-gray-400">{t('sidebar.chromeDescription')}</p>)}

    { !collapse && (
      <div className="mb-4">
      <p className="text-sm text-gray-400">{t('sidebar.extensionStatus')}</p>
      <div className="flex items-center gap-2">
        <span
          className={`h-2.5 w-2.5 rounded-full ${isInstalled ? 'bg-green-500' : 'bg-red-500'}`}
        ></span>
        <span className="text-xs text-gray-400">
          {' '}
          {isInstalled ? t('sidebar.installed') : t('sidebar.notInstalled')}
        </span>
      </div>
    </div>
    )}
  </div>)}
  </div>
  );
};
