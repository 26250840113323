import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

export const ClearDataPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClearData = () => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      toast.success(t('common.dataCleared'));
      navigate('/login');
    } catch (error) {
      toast.error(t('common.error'));
      console.error('Error clearing data:', error);
    }
  };

  return (
    <div className="flex h-[calc(100vh-4rem)] flex-col items-center justify-center">
      <div className="mb-8 text-center">
        <h1 className="mb-4 text-2xl font-bold text-gray-900">{t('clearData.title')}</h1>
        <p className="text-gray-600">{t('clearData.description')}</p>
      </div>
      <button
        onClick={handleClearData}
        className="rounded-lg bg-red-500 px-6 py-3 text-white transition-colors hover:bg-red-600"
      >
        {t('clearData.button')}
      </button>
    </div>
  );
};

export default ClearDataPage;
