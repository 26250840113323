import React from 'react';
import { toast } from 'react-hot-toast';

interface IdentifierBadgeProps {
  type: 'ASIN' | 'EAN';
  value: string;
}

export const IdentifierBadge: React.FC<IdentifierBadgeProps> = ({ type, value }) => {
  const handleClick = () => {
    const copyToClipboard = () => {
      navigator.clipboard.writeText(value);
      toast.success('Identifiant copié dans le presse-papiers');
    };

    return copyToClipboard();
  };

  return (
    <div className="flex items-center gap-1">
      <span className="rounded bg-gray-50 px-1.5 py-0.5 text-xs text-gray-500">{type}</span>
      <button onClick={handleClick} className={`cursor-pointer text-sm hover:text-orange-500`}>
        {value}
      </button>
    </div>
  );
};
