// tokenService.js
export const setToken = (token: string) => localStorage.setItem('authToken', token);
export const getToken = () => {
  const oldToken = localStorage.getItem('token');
  const newToken = localStorage.getItem('authToken');
  if (oldToken) {
    localStorage.removeItem('token');
  }
  if (!newToken) {
    setToken(oldToken as string);
  }
  return newToken || oldToken;
};

export const setAdminToken = (token: string) => localStorage.setItem('adminToken', token);
export const getAdminToken = () => localStorage.getItem('adminToken');

export const switchToUserToken = (userToken: string) => {
  // Save the admin token if not already saved
  if (!getAdminToken()) {
    setAdminToken(getToken() as string);
  }
  setToken(userToken);
};

export const switchBackToAdminToken = () => {
  const adminToken = getAdminToken();
  if (adminToken) {
    setToken(adminToken);
    localStorage.removeItem('adminToken'); // Clear it after switching back
  }
};

export const clearToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('authToken');
  localStorage.removeItem('adminToken');
};

export const isAdminSwitching = () => {
  return getAdminToken() !== null;
};
