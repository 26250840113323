import React from 'react';
import { useTranslation } from 'react-i18next';
import { Search, Download } from 'lucide-react';

export const UserListHeader: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="border-b border-gray-100 p-6">
      <div className="mb-4 flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-semibold">{t('user.userList')}</h1>
          <p className="text-gray-600">{t('user.userListDescription')}</p>
        </div>
        <button className="flex items-center gap-2 rounded-lg px-4 py-2 text-gray-600 hover:bg-gray-50">
          <Download size={20} />
          <span>{t('common.export')}</span>
        </button>
      </div>
      <div className="relative">
        <Search
          className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-400"
          size={20}
        />
        <input
          type="text"
          placeholder={t('user.searchUsers')}
          className="w-full rounded-lg border border-gray-200 py-2 pl-10 pr-4 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
        />
      </div>
    </div>
  );
};
