import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import * as apiClient from '../../apiClient';
import { TSupplier } from '../../apiClient/suppliers/suppliers';
import { RootState } from '..';

export type TProcessingSupplier = {
  lastOctoParseFetch: string;
  lastUpdateDate: string;
  numberOfAddedProducts: string;
  numberOfUpdatedProducts: string;
  numberOfFailedEanProducts: string;
  status: string;
  progress: string;
  total: string;
  offset: string;
  restTotal: string;
  processId: string;
};
type TProcessingIndexes = {
  [id: string]: TProcessingSupplier;
};
export const reducerName = 'suppliers';

const initialState: TState = {
  indexes: {},
  affilizIndexes: {},
  ids: [],
  isLoading: false,
  isError: false,
  byAffilizId: {},
  processingIndexes: {},
  processingIds: [],
  processingIsLoading: false,
  processingIsError: false,
};

export const fetchSuppliers = createAsyncThunk<TSupplier[], void>(reducerName, async () => {
  const response = await apiClient.suppliers.get('');
  return response.data as TSupplier[];
});

export const fetchAffilizSuppliers = createAsyncThunk<TAffilizSupplier[], void>(
  `${reducerName}/affiliz`,
  async () => {
    const response = await apiClient.suppliers.affilizSuppliers();
    return response.data as unknown as TAffilizSupplier[];
  }
);

export const processingSuppliers = createAsyncThunk<TProcessingIndexes, void>(
  `${reducerName}/processing`,
  async () => {
    const response = await apiClient.suppliers.getProcessingSuppliers();
    return response.data as unknown as TProcessingIndexes;
  }
);

type UpdateSupplierArg = { supplierId: string; supplier: Partial<TSupplier> };

export const updateSupplier = createAsyncThunk<TSupplier, UpdateSupplierArg>(
  `${reducerName}/update`,
  async ({ supplierId, supplier }) => {
    const response = await apiClient.suppliers.updateSupplier(supplierId, supplier);
    return response.data as TSupplier;
  }
);

export const linkAffilizSupplier = createAsyncThunk<
  {
    supplierId: string;
    affilizId: string;
  },
  {
    supplierId: string;
    affilizId: string;
  }
>(`${reducerName}/linkAffilizSupplier`, async ({ supplierId, affilizId }) => {
  const response = await apiClient.suppliers.linkAffilizSupplier({ supplierId, affilizId });
  return response.data as {
    supplierId: string;
    affilizId: string;
  };
});

export const sendToProcess = createAsyncThunk<TSupplier, string>(
  `${reducerName}/sendToProcess`,
  async (supplierId) => {
    const response = await apiClient.suppliers.post(`${supplierId}/activate`, {});
    return response.data as TSupplier;
  }
);

export const refreshOctoParse = createAsyncThunk<TSupplier[], void>(
  `${reducerName}/refreshOctoParse`,
  async () => {
    const response = await apiClient.suppliers.get(`refresh`, {});
    return response.data as TSupplier[];
  }
);

export const toggleActive = createAsyncThunk<TSupplier, { supplierId: string; isActive: boolean }>(
  `${reducerName}/toggleActive`,
  async ({ supplierId, isActive }: { supplierId: string; isActive: boolean }) => {
    const response = await apiClient.suppliers.put(`${supplierId}`, { isActive });
    return response.data as TSupplier;
  }
);

export const createSupplier = createAsyncThunk<TSupplier, Partial<TSupplier>>(
  `${reducerName}/create`,
  async (supplier) => {
    const response = await apiClient.suppliers.create('', supplier);
    return response.data as TSupplier;
  }
);

type TFetchOneState = {
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error?: string;
};
export type TSupplierIndexes = {
  [id: string]: TSupplier;
};

export type TAffilizSupplier = {
  shopName: string;
  _id: string;
  country?: string | null;
  type?: string | null;
};
export type TAffilizSupplierIndexes = {
  [id: string]: TAffilizSupplier;
};

export type TState = {
  indexes: TSupplierIndexes;
  affilizIndexes: TAffilizSupplierIndexes;
  ids: string[];
  isLoading: boolean;
  isError: boolean;
  byAffilizId: { [affilizId: string]: string };
  processingIndexes: TProcessingIndexes;
  processingIds: string[];
  processingIsLoading: boolean;
  processingIsError: boolean;

  fetchStatus?: {
    [id: string]: TFetchOneState;
  };
};
const suppliers = createSlice({
  name: reducerName,
  initialState: {
    indexes: {} as TSupplierIndexes,
    affilizIndexes: {} as TAffilizSupplierIndexes,
  } as TState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
    updateSupplier: (state, { payload }: PayloadAction<UpdateSupplierArg>) => {
      state.indexes[payload.supplierId] = {
        ...state.indexes[payload.supplierId],
        ...payload.supplier,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSuppliers.fulfilled, (state, { payload }: PayloadAction<TSupplier[]>) => {
      const { indexes, byAffilizId } = payload.reduce(
        (
          acc: {
            indexes: TSupplierIndexes;
            byAffilizId: { [affilzId: string]: string };
          },
          supplier
        ) => {
          acc.indexes[supplier._id] = supplier;
          if (supplier.affilizId) {
            acc.byAffilizId[supplier.affilizId] = supplier._id;
          }
          return acc;
        },
        {
          indexes: {} as TSupplierIndexes,
          byAffilizId: {} as { [affilzId: string]: string },
        }
      );

      state.indexes = indexes;
      state.byAffilizId = byAffilizId;
      state.ids = Object.keys(state.indexes);
      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(
      processingSuppliers.fulfilled,
      (state, { payload }: PayloadAction<TProcessingIndexes>) => {
        let data = {};
        for (const [id, supplier] of Object.entries(payload)) {
          data = {
            ...data,
            [id]: JSON.parse(supplier as unknown as string),
          };
        }
        state.processingIndexes = data;
        state.processingIds = Object.keys(payload);
        state.processingIsLoading = false;
        state.processingIsError = false;
      }
    );
    builder.addCase(
      fetchAffilizSuppliers.fulfilled,
      (state, { payload }: PayloadAction<TAffilizSupplier[]>) => {
        state.affilizIndexes = payload.reduce((acc, supplier) => {
          acc[supplier._id] = supplier;
          return acc;
        }, {} as TAffilizSupplierIndexes);
      }
    );
    builder.addCase(linkAffilizSupplier.fulfilled, (state, { meta }) => {
      const { supplierId, affilizId } = meta.arg;
      state.indexes[supplierId].affilizId = affilizId;
      state.byAffilizId[affilizId] = supplierId;
      state.indexes[supplierId].hasAffiliation = true;
    });
    builder.addCase(updateSupplier.fulfilled, (state, { payload }: PayloadAction<TSupplier>) => {
      state.indexes[payload._id] = payload;
    });
    builder.addCase(createSupplier.fulfilled, (state, { payload }: PayloadAction<TSupplier>) => {
      state.indexes[payload._id] = payload;
    });
    builder.addCase(
      refreshOctoParse.fulfilled,
      (state, { payload }: PayloadAction<TSupplier[]>) => {
        state.indexes = payload.reduce((acc, supplier) => {
          acc[supplier._id] = supplier;
          return acc;
        }, {} as TSupplierIndexes);
        state.ids = Object.keys(state.indexes);
        state.isLoading = false;
        state.isError = false;
      }
    );
  },
});

const state = (state: RootState) => state[reducerName];

const selectSupplierIndexes = createSelector(state, (state: TState) => state?.indexes);
const selectAffilizIndexes = createSelector(state, (state: TState) => state?.affilizIndexes);
const selectIds = createSelector(state, (state: TState) => state?.ids);
const selectIsLoading = createSelector(state, (state: TState) => state?.isLoading);
const selectIsError = createSelector(state, (state: TState) => state?.isError);
const selectFetchStatus = createSelector(state, (state: TState) => state?.fetchStatus);
const selectSupplierCollection = createSelector(
  selectSupplierIndexes,
  (indexes: TSupplierIndexes) => Object.values(indexes)
);
const selectAffilizSupplierCollection = createSelector(
  selectAffilizIndexes,
  (indexes: TAffilizSupplierIndexes) => Object.values(indexes) as TAffilizSupplier[]
);
const selectByAffilizId = createSelector(state, (state: TState) => state?.byAffilizId);

const selectUnusedAffilizIndexes = createSelector(
  [selectAffilizIndexes, selectByAffilizId],
  (indexes: TAffilizSupplierIndexes, byAffilizId: { [affilizId: string]: string }) => {
    return Object.values(indexes).filter((value) => !byAffilizId[value._id]);
  }
);

const selectAffilizIds = createSelector(
  selectByAffilizId,
  (byAffilizId: { [affilizId: string]: string }) => Object.keys(byAffilizId)
);

const selectProcessingIndexes = createSelector(state, (state: TState) => state?.processingIndexes);
const selectProcessingIds = createSelector(state, (state: TState) => state?.processingIds);
const selectProcessingIsLoading = createSelector(
  state,
  (state: TState) => state?.processingIsLoading
);
const selectProcessingIsError = createSelector(state, (state: TState) => state?.processingIsError);

export const selectors = {
  state,
  selectSupplierIndexes,
  selectIds,
  selectIsLoading,
  selectIsError,
  selectFetchStatus,
  selectSupplierCollection,
  selectAffilizSupplierCollection,
  selectByAffilizId,
  selectAffilizIds,
  selectProcessingIndexes,
  selectProcessingIds,
  selectProcessingIsLoading,
  selectProcessingIsError,
  selectAffilizIndexes,
  selectUnusedAffilizIndexes,
};

export const { reset } = suppliers.actions;
export const reducer = suppliers.reducer;
