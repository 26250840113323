import React, { createContext, useContext, useEffect } from 'react';
import { TUserContextType, UserRole } from '../types/user';
import { useSelector } from 'react-redux';
import * as Store from '../store';
import * as apiClient from '../apiClient';
import {
  isAdminSwitching,
  switchBackToAdminToken,
  switchToUserToken,
} from '../services/token.service';
const UserContext = createContext<TUserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const currentUser = useSelector(Store.auth.selectUser);
  const isAdmin = currentUser?.roles.includes(UserRole.ADMIN) ?? false;
  const hasBasicPlan = currentUser?.hasBasicPlan ?? false;
  const hasProPlan = currentUser?.hasPremiumPlan ?? false;
  const dispatch = Store.useDispatch();
  useEffect(() => {
    if (!currentUser) {
      dispatch(Store.auth.fetchCurrentUser());
    }
  }, [currentUser, dispatch]);

  const switchToUser = async (userId: string, callback?: () => void) => {
    // Implement user switching logic here
    try {
      const response = await apiClient.user.switchToUser(userId);
      const token = response.data as unknown as string;
      switchToUserToken(token);
      callback?.();
    } catch (error) {
      console.error(error);
    }

    console.log('Switching to user:', userId);
  };

  const switchBackToAdmin = async (callback?: () => void) => {
    // Implement admin switching logic here
    switchBackToAdminToken();
    callback?.();
  };

  const swichModeActive = isAdminSwitching();
  return (
    <UserContext.Provider
      value={{
        user: currentUser,
        setUser: () => {},
        isAdmin,
        switchToUser,
        switchBackToAdmin,
        swichModeActive,
        hasBasicPlan,
        hasProPlan,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
