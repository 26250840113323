import React, { useState, useRef, useEffect, useCallback } from 'react';
import { RotateCcw } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Select } from './Select';
import { RangeInput } from './RangeInput';
import {
  InputRangesData,
  useSupplierOptions,
  TFilterRanges,
  cleanFilterState,
  formatFilterStateToSearchConditions,
} from '../../utils/filter';
import toast from 'react-hot-toast';
import { SavedSearchesMenu } from '../ProductList/SavedSearchesMenu';
import * as Store from '../../store';
import { Modal } from '../common/Modal';
import { TSupplier } from '../../apiClient/suppliers/suppliers';

interface FilterSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (values: Record<string, unknown>) => void;
  conditions?: string;
}

// Ici  Types pour les filtres

const rangeInitialState = {
  amazonPrice: { min: 0, max: 10000 },
  supplierPrice: { min: 0, max: 10000 },
  roi: { min: 0, max: 10000 },
  estimatedSales: { min: 0, max: 10000 },
  profit: { min: 0, max: 10000 },
  profitPerMonth: { min: 0, max: 10000 },
};
const initialDataState: TFilterRanges = {
  ...rangeInitialState,
  isGrouped: false,
  IsAmazonFulfilled: false,
};

type TransformedConditions = {
  selectedTypes: string[];
  selectedSuppliers: string[];
  data: TFilterRanges;
};

const transformConditionsToState = (
  conditions: Record<string, unknown>,
  supplierIndexes: Record<string, TSupplier>
): TransformedConditions => {
  const result: TransformedConditions = {
    selectedTypes: [],
    selectedSuppliers: [],
    data: { ...initialDataState },
  };

  // Handle checkbox conditions
  result.data.isGrouped = !!(conditions.isGrouped ?? initialDataState.isGrouped);
  result.data.IsAmazonFulfilled = !!(
    conditions.IsAmazonFulfilled ?? initialDataState.IsAmazonFulfilled
  );

  // Handle suppliers array condition
  if (conditions.supplier && typeof conditions.supplier === 'object') {
    const suppliersCondition = conditions.supplier as { $in?: string[] };
    if (suppliersCondition.$in) {
      result.selectedSuppliers = suppliersCondition.$in;
      const types = [
        ...new Set(suppliersCondition.$in.map((id) => supplierIndexes[id]?.type).filter(Boolean)),
      ] as unknown as string[];
      result.selectedTypes = types;
    }
  }

  // Handle range conditions
  Object.entries(conditions).forEach(([field, condition]) => {
    if (field in rangeInitialState && typeof condition === 'object' && condition !== null) {
      const rangeCondition = condition as { $gte?: number; $lte?: number };
      const fieldKey = field as keyof typeof rangeInitialState;
      result.data[fieldKey] = {
        min: rangeCondition.$gte ?? initialDataState[fieldKey]?.min ?? 0,
        max: rangeCondition.$lte ?? initialDataState[fieldKey]?.max ?? 10000,
      };
    }
  });

  return result;
};

export const FilterSidebar: React.FC<FilterSidebarProps> = ({
  isOpen,
  onClose,
  onSubmit,
  conditions,
}) => {
  const { t } = useTranslation();
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([]);

  const [showSaveModal, setShowSaveModal] = useState(false);
  const [searchName, setSearchName] = useState('');
  const dispatch = Store.useDispatch();
  const selectedMarketPlaces = Store.useAppSelector(
    Store.userSettings.selectors.selectSelectedMarketplaces
  );

  const supplierOptions = useSupplierOptions(selectedTypes[0] || null);

  const typeOptions = [
    { value: 'supplier', label: t('filters.suppliers.supplier') },
    { value: 'pro', label: t('filters.suppliers.professional'), isPro: true },
  ];

  //  Ic Initialisation de l'état avec la nouvelle interface FilterRanges
  const [data, setData] = useState<TFilterRanges>(initialDataState);

  const sidebarRef = useRef<HTMLDivElement>(null);

  const supplierIndexes = Store.useAppSelector(Store.suppliers.selectors.selectSupplierIndexes);

  const applySavedSearch = useCallback(
    (conditions: Record<string, unknown>) => {
      try {
        const { selectedTypes, selectedSuppliers, data } = transformConditionsToState(
          conditions,
          supplierIndexes
        );
        setSelectedTypes(selectedTypes);
        setSelectedSuppliers(selectedSuppliers);
        setData(data);
      } catch (error) {
        toast.error(t('error.somethingWentWrong') + ': ' + (error as Error).message);
      }
    },
    [supplierIndexes, t, setSelectedTypes, setSelectedSuppliers, setData]
  );
  useEffect(() => {
    if (conditions) {
      applySavedSearch(JSON.parse(conditions));
    }
  }, [conditions, applySavedSearch]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node) &&
        !(event.target as Element).closest('.modal-content')
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleApply = () => {
    if (isFormValid()) {
      const cleanData = cleanFilterState(data);

      const searchConditions = formatFilterStateToSearchConditions({
        state: {
          ...cleanData,
          amazonCountry: selectedMarketPlaces,
          supplier: selectedSuppliers,
        },
      });

      onSubmit?.(searchConditions);
      onClose();
    } else {
      if (selectedSuppliers.length === 0) {
        toast.error(t('filters.suppliers.selectAtLeastOneSupplier'));
      }
      if (selectedSuppliers.length > 3) {
        toast.error(t('filters.suppliers.selectAtMostThreeSuppliers'));
      }
    }
  };

  // États pour gérer les erreurs de validation

  const isFormValid = () => {
    if (selectedSuppliers.length === 0) {
      return false;
    }
    if (selectedSuppliers.length > 3) {
      return false;
    }
    if (
      data?.supplierPrice?.min &&
      data?.supplierPrice?.max &&
      data.supplierPrice.min > data.supplierPrice.max
    ) {
      return false;
    }
    if (
      data?.amazonPrice?.max &&
      data?.amazonPrice?.max &&
      data?.amazonPrice?.min > data?.amazonPrice?.max
    ) {
      return false;
    }
    if (data?.roi?.max && data?.roi?.max && data.roi.min > data.roi.max) {
      return false;
    }
    if (
      data?.estimatedSales?.max &&
      data?.estimatedSales?.max &&
      data.estimatedSales.min > data.estimatedSales.max
    ) {
      return false;
    }
    if (data?.profit?.max && data?.profit?.max && data.profit.min > data.profit.max) {
      return false;
    }
    if (
      data?.profitPerMonth?.max &&
      data?.profitPerMonth?.max &&
      data.profitPerMonth.min > data.profitPerMonth.max
    ) {
      return false;
    }
    return true;
  };

  const resetFilters = () => {
    setData({
      ...initialDataState,
      isGrouped: false,
      IsAmazonFulfilled: false,
    });
  };

  const onChangeFilter = (
    name: string,
    value: number[] | string | undefined | number | boolean
  ) => {
    if (name === 'isGrouped' || name === 'IsAmazonFulfilled') {
      setData({
        ...data,
        [name]: value,
      });
    } else {
      setData({
        ...data,
        [name]: Array.isArray(value) ? { min: value[0], max: value[1] } : value,
      });
    }
  };

  const handleSaveSearch = async () => {
    if (!searchName.trim()) {
      toast.error(t('savedSearches.nameRequired'));
      return;
    }

    if (!isFormValid()) {
      toast.error(t('savedSearches.invalidFilters'));
      return;
    }

    const cleanData = cleanFilterState(data);
    const conditions = formatFilterStateToSearchConditions({
      state: {
        ...cleanData,
        amazonCountry: selectedMarketPlaces,
        supplier: selectedSuppliers,
      },
    });

    try {
      await dispatch(
        Store.userSettings.saveSearch({
          name: searchName.trim(),
          conditions,
        })
      ).unwrap();
      toast.success(t('savedSearches.saveSuccess'));
      setShowSaveModal(false);
      setSearchName('');
    } catch (error) {
      console.log(error);
      toast.error(t('savedSearches.saveError'));
    }
  };

  //console.log("mes data sont ==>"  , data)

  return (
    <>
      <div
        className={`fixed inset-0 z-40 bg-black bg-opacity-25 transition-opacity ${
          isOpen ? 'opacity-100' : 'pointer-events-none opacity-0'
        }`}
      />

      <div
        ref={sidebarRef}
        className={`fixed bottom-0 right-0 top-0 z-50 w-96 transform bg-white shadow-xl transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex h-full flex-col">
          <div className="flex items-center justify-between border-b border-gray-100 p-6">
            <h2 className="text-xl font-semibold">{t('filters.title')}</h2>
            <div className="flex items-center gap-2">
              <button
                onClick={() => {
                  if (isFormValid()) {
                    setShowSaveModal(true);
                  }
                  return;
                }}
                className="rounded-lg bg-orange-500 px-4 py-2 text-sm font-medium text-white hover:bg-orange-600"
              >
                {t('savedSearches.save')}
              </button>
              <SavedSearchesMenu
                onAddSavedSearch={() => setShowSaveModal(true)}
                onApplySearch={applySavedSearch}
              />
            </div>
          </div>

          <div className="flex-1 space-y-8 overflow-y-auto p-6">
            <div>
              <h3 className="mb-4 text-sm font-semibold text-gray-900">
                {t('filters.suppliers.title')}
              </h3>
              <div className="space-y-4">
                <Select
                  label={t('filters.suppliers.type')}
                  placeholder={t('filters.suppliers.selectType')}
                  options={typeOptions}
                  value={selectedTypes}
                  isMulti={false}
                  onChange={(value) => {
                    console.log({ value });
                    setSelectedTypes(Array.isArray(value) ? value : [value]);
                    setSelectedSuppliers([]);
                  }}
                />
                <Select
                  label={t('filters.suppliers.name')}
                  placeholder={t('filters.suppliers.selectSupplier')}
                  options={supplierOptions}
                  value={selectedSuppliers}
                  onChange={(value) => setSelectedSuppliers(Array.isArray(value) ? value : [value])}
                  isMulti
                  hasSearch
                />
                <RangeInput
                  label={t('filters.profit.supplierPrice')}
                  min={0}
                  onChange={(values) => {
                    onChangeFilter('supplierPrice', values);
                  }}
                  max={10000}
                  step={10}
                />
              </div>
            </div>
            <div className="space-y-4 border-t border-gray-200 pt-6">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="isGrouped"
                  checked={data.isGrouped}
                  onChange={(e) => onChangeFilter('isGrouped', e.target.checked)}
                  className="h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500"
                />
                <label htmlFor="isGrouped" className="ml-2 text-sm text-gray-700">
                  {t('filters.isGrouped')}
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="IsAmazonFulfilled"
                  checked={data.IsAmazonFulfilled}
                  onChange={(e) => onChangeFilter('IsAmazonFulfilled', e.target.checked)}
                  className="h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500"
                />
                <label htmlFor="IsAmazonFulfilled" className="ml-2 text-sm text-gray-700">
                  {t('filters.isAmazonFulfilled')}
                </label>
              </div>
            </div>
            <div>
              <h3 className="mb-4 text-sm font-semibold text-gray-900">
                {t('filters.profit.title')}
              </h3>
              <div className="space-y-6">
                {InputRangesData.map((range) => (
                  <RangeInput
                    key={range.label}
                    label={t(range.label)}
                    min={range.min}
                    max={range.max}
                    step={range.step}
                    value={[data[range.name]?.min ?? 0, data[range.name]?.max ?? 10000]}
                    onChange={(values) => {
                      onChangeFilter(range.name, values);
                    }}
                  />
                ))}
              </div>

              {/* <div className="space-y-4 border-t border-gray-200 pt-6">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="isGrouped"
                    checked={data.isGrouped}
                    onChange={(e) => onChangeFilter('isGrouped', e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500"
                  />
                  <label htmlFor="isGrouped" className="ml-2 text-sm text-gray-700">
                    {t('filters.isGrouped')}
                  </label>
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="isAmazonFulfilled"
                    checked={data.IsAmazonFulfilled}
                    onChange={(e) => onChangeFilter('IsAmazonFulfilled', e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-orange-500 focus:ring-orange-500"
                  />
                  <label htmlFor="isAmazonFulfilled" className="ml-2 text-sm text-gray-700">
                    {t('filters.isAmazonFulfilled')}
                  </label>
                </div>
              </div> */}
            </div>
          </div>

          <div className="border-t border-gray-100 bg-gray-50 p-6">
            <div className="flex items-center justify-between gap-4">
              <button
                onClick={resetFilters}
                className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900"
              >
                <RotateCcw size={16} />
                <span>{t('common.reset')}</span>
              </button>
              <button
                onClick={handleApply}
                className={`rounded-lg bg-orange-500 px-4 py-2 text-sm text-white hover:bg-orange-600`}
              >
                {t('common.search')}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="md"
        isOpen={showSaveModal}
        onClose={() => {
          setShowSaveModal(false);
          setSearchName('');
        }}
        title={t('savedSearches.saveTitle')}
      >
        <div className="space-y-4">
          <div>
            <label htmlFor="searchName" className="block text-sm font-medium text-gray-700">
              {t('savedSearches.name')}
            </label>
            <input
              type="text"
              id="searchName"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
              placeholder={t('savedSearches.namePlaceholder')}
            />
          </div>
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={() => {
                setShowSaveModal(false);
                setSearchName('');
              }}
              className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {t('common.cancel')}
            </button>
            <button
              type="button"
              onClick={handleSaveSearch}
              className="rounded-md bg-orange-500 px-4 py-2 text-sm font-medium text-white hover:bg-orange-600"
            >
              {t('common.save')}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
