import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ValueGetterParams, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { subscription } from '../../../apiClient';
import styles from '../AccountPage.module.css';

interface StripeInvoice {
  lines: {
    data: Array<{
      description: string;
      amount: number;
    }>;
  };
  amount_paid: number;
  created: number;
  invoice_pdf: string;
  hosted_invoice_url: string;
}

interface StripeResponse {
  data: StripeInvoice[];
  object: string;
}

export const InvoicesList: React.FC = () => {
  const [invoices, setInvoices] = useState<StripeInvoice[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    subscription
      .get('invoices')
      .then((response) => {
        const invoiceData = response.data as unknown as StripeResponse;
        setInvoices(invoiceData.data);
      })
      .catch((error) => {
        console.error('Failed to fetch invoices:', error);
        setError('Failed to load invoices');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <div className={styles.section}>Loading invoices...</div>;
  }

  if (error) {
    return <div className={styles.section}>{error}</div>;
  }

  return (
    <div className={styles.section}>
      <div className="ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
        <AgGridReact
          rowData={invoices}
          columnDefs={[
            {
              headerName: 'Nom',
              valueGetter: (params: ValueGetterParams<StripeInvoice>) =>
                params.data?.lines?.data?.[0]?.description ?? '',
            },
            {
              field: 'amount_paid',
              headerName: 'Montant',
              valueFormatter: (params: ValueFormatterParams<StripeInvoice>) =>
                `${params.value / 100}€`,
            },
            {
              field: 'created',
              headerName: 'Créée le',
              valueFormatter: (params: ValueFormatterParams<StripeInvoice>) =>
                new Date(params.value * 1000).toLocaleDateString('fr-FR'),
            },
            {
              headerName: 'Actions',
              cellRenderer: (params: ICellRendererParams<StripeInvoice>) => (
                <div>
                  <button
                    className={styles.viewButton}
                    title="Voir"
                    onClick={() => window.open(params.data?.hosted_invoice_url, '_blank')}
                  >
                    👁️
                  </button>
                  <button
                    className={styles.downloadButton}
                    title="Télécharger"
                    onClick={() => window.open(params.data?.invoice_pdf, '_blank')}
                  >
                    ⬇️
                  </button>
                </div>
              ),
            },
          ]}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
        />
      </div>
    </div>
  );
};
