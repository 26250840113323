export const fr = {
  validation: {
    required: 'Champ requis',
    email: 'Email invalide',
    password: 'Mot de passe invalide',
    phone: 'Numéro de téléphone invalide',
    min: 'Minimum de {{min}} caractères',
    max: 'Maximum de {{max}} caractères',
    minLength: 'Minimum de {{min}} caractères',
    maxLength: 'Maximum de {{max}} caractères',
    minValue: 'Minimum de {{min}}',
    maxValue: 'Maximum de {{max}}',
    invalid: 'Format invalide',
  },
  common: {
    search: 'Rechercher...',
    previous: 'Précédent',
    next: 'Suivant',
    cancel: 'Annuler',
    validate: 'Valider',
    save: 'Enregistrer',
    apply: 'Appliquer',
    reset: 'Réinitialiser',
    category: 'Catégorie',
    marketplace: 'Place de marché',
    vatExcl: 'HT',
    vatIncl: 'TTC',
    buy: 'Acheter',
    keywords: 'Mots-clés...',
    otherTitles: 'Autres titres',
    export: 'Exporter',
    copied: 'Copié dans le presse-papier',
    yes: 'Oui',
    no: 'Non',
    error: 'Une erreur est survenue',
    title: 'Titre',
    price: 'Prix',
    id: 'ID',
    viewAll: 'Voir tout',
    dataCleared: 'Données effacées avec succès',
    inStock: 'En stock',
    outOfStock: 'Rupture de stock',
  },
  auth: {
    title: {
      1: 'Maximisez',
      2: 'vos profits',
      3: 'avec',
    },
    description:
      'Trouvez les produits les plus rentables à revendre sur Amazon avec notre puissant moteur de recherche.',
    steps: {
      profile: 'Profil',
      seller: 'Vendeur Amazon',
      subscription: 'Abonnement',
    },
    profile: {
      title: 'Votre Profil',
      lastName: 'Nom',
      firstName: 'Prénom',
      email: 'Email',
      phone: 'Téléphone',
      lastNamePlaceholder: 'Entrez votre nom',
      firstNamePlaceholder: 'Entrez votre prénom',
      emailPlaceholder: 'Entrez votre email',
      phonePlaceholder: 'Entrez votre téléphone',
    },
    seller: {
      title: 'Connectez votre compte Vendeur Amazon',
      description: 'Connectez votre compte Vendeur Amazon pour commencer à importer vos produits.',
      connectButton: 'Se connecter avec Amazon',
      noAccount: "Vous n'avez pas de compte Vendeur Amazon ?",
      createAccount: 'Créez-en un maintenant',
      yourSellerAccountIsConnected: 'Votre compte Amazon Seller est connecté',
    },
    login: {
      title: 'Connexion',
      noAccount: "Vous n'avez pas de compte ?",
      registerLink: "S'inscrire",
      submit: 'Se connecter',
      email: 'Email',
      password: 'Mot de passe',
      emailPlaceholder: 'Entrez votre email',
      passwordPlaceholder: 'Entrez votre mot de passe',
      forgotPassword: 'Mot de passe oublié ?',
      resetLink: 'Réinitialiser ici',
    },
    register: {
      title: 'Créer un compte',
      hasAccount: 'Vous avez déjà un compte ?',
      loginLink: 'Se connecter',
      submit: "S'inscrire",
      email: 'Email',
      password: 'Mot de passe',
      emailPlaceholder: 'Entrez votre email',
      passwordPlaceholder: 'Entrez votre mot de passe',
      error: 'Veuillez remplir tous les champs',
    },
    forgotPassword: {
      submit: 'Envoyer le lien',
      emailSent: 'Les instructions de réinitialisation ont été envoyées par email',
      backToLogin: 'Vous vous souvenez de votre mot de passe ?',
      loginLink: 'Se connecter ici',
    },
    resetPassword: {
      newPassword: 'Nouveau mot de passe',
      confirmPassword: 'Confirmer le mot de passe',
      submit: 'Réinitialiser le mot de passe',
      success: 'Votre mot de passe a été réinitialisé avec succès',
      invalidTokenOrId: 'Lien de réinitialisation invalide ou expiré',
    },
    verifyEmail: {
      verifying: 'Vérification de votre email...',
      wait: 'Veuillez patienter pendant que nous vérifions votre email',
      success: 'Email vérifié avec succès',
      error: "Erreur lors de la vérification de l'email",
      invalidToken: 'Lien de vérification invalide ou expiré',
    },
  },
  user: {
    profile: 'Profil',
    editProfile: 'Modifier le profil',
    userList: 'Liste des utilisateurs',
    switchBackToAdmin: "Retour à l'admin",
    adminMessages: 'Messages admin',
    logout: 'Déconnexion',
    profileDescription: 'Gérez vos informations personnelles et préférences',
    personalInfo: 'Informations personnelles',
    companyInfo: 'Informations société',
    contactInfo: 'Informations de contact',
    subscriptionInfo: "Informations d'abonnement",
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Adresse e-mail',
    companyName: 'Nom de la société',
    companyId: 'SIRET',
    phoneNumber: 'Numéro de téléphone',
    address: 'Adresse',
    city: 'Ville',
    zipCode: 'Code postal',
    country: 'Pays',
    stripeCustomerId: 'ID Client Stripe',
    subscriptionPlan: "Plan d'abonnement",
    subscriptionStatus: 'Statut',
    subscriptionStart: 'Date de début',
    subscriptionEnd: 'Date de fin',
    roles: 'Rôles',
    createdAt: 'Créé le',
    searchUsers: 'Rechercher des utilisateurs...',
    userListDescription: 'Gérez les comptes utilisateurs et les permissions',
  },
  search: {
    title: 'Rechercher des produits',
    description: 'Plus de 25 millions de produits à portée de main, mis à jour quotidiennement.',
    include: 'Inclure',
    exclude: 'Exclure',
    searchButton: 'Rechercher',
    found: 'produits trouvés',
  },
  filters: {
    title: 'Filtres',
    profit: {
      title: 'Profit',
      price: 'Prix',
      roiUnit: 'ROI par unité',
      netRevenue: 'Revenu net après frais',
      salesPerMonth: 'Ventes par mois',
      profitUnit: 'Profit par unité',
      profitPerMonth: 'Profit mensuel',
      supplierPrice: 'Prix fournisseur',
      amazonPrice: 'Prix Amazon',
    },
    suppliers: {
      title: 'Fournisseurs',
      type: 'Type',
      name: 'Nom',
      price: 'Prix',
      selectType: 'Sélectionner le type',
      selectSupplier: 'Sélectionner le fournisseur',
      supplier: 'Sites marchands',
      professional: 'Sites professionnels',
      selectAtLeastOneSupplier: 'Veuillez sélectionner au moins un fournisseur',
      selectAtMostThreeSuppliers: 'Veuillez sélectionner au maximum trois fournisseurs',
    },
    isGrouped: ' Exclure les lots',
    isAmazonFulfilled: "Amazon n'est pas vendeur",
  },
  products: {
    title: 'Titre',
    price: 'Prix',
    supplierPrices: 'Prix fournisseurs',
    profitMargin: 'Marge',
    monthlySales: 'Ventes mensuelles',
    monthlyProfit: 'Profit mensuel',
    keepa: 'Keepa',
    export: 'Exporter',
    found: 'produits trouvés',
    comparePrices: 'Comparer les prix',
    marketplace: 'Marketplace',
    resultTable: {
      amazonTitle: 'Titre Amazon',
      amazonPrice: 'Prix Amazon',
      supplierTitle: 'Titre Fournisseur',
      supplierPrice: 'Prix Fournisseur',
      profitMargin: 'Marge',
      monthlySales: 'Ventes mensuelles',
      monthlyProfit: 'Profit mensuel',
      keepa: 'Keepa',
      profit: 'Profit',
      estimatedSales: 'Vente(s)/mois',
      estimatedProfit: 'Profit estimé/mois',
      estimatedROI: 'ROI estimé/mois',
      estimatedROIUnit: 'ROI estimé/unité',
      estimatedNetRevenue: 'Revenu net estimé/mois',
      estimatedNetRevenueUnit: 'Revenu net estimé/unité',
      image: 'Image',
      profitUnit: 'Profit unitaire',
      starred: 'Favoris',
    },
  },
  suppliers: {
    title: 'Fournisseurs',
    description: 'Gérez vos fournisseurs et leurs produits.',
    name: 'Nom',
    country: 'Pays',
    type: 'Type',
    category: 'Catégorie',
    productCount: 'Produits',
    lastUpdate: 'Dernière mise à jour',
    found: 'fournisseurs trouvés',
    affiliated: 'Fournisseur affilié',
    status: 'Statut',
    actions: 'Actions',
    sendToProcess: 'Envoyer au traitement',
    activate: 'Activer',
    deactivate: 'Désactiver',
    processingStarted: 'Traitement démarré',
    statusUpdated: 'Statut mis à jour',
    updated: 'Fournisseur mis à jour',
  },
  affiliz: {
    title: 'Fournisseurs Affiliz',
    description: 'Gérez vos connexions avec les fournisseurs Affiliz',
    selectCountry: 'Sélectionner un pays...',
    selectType: 'Sélectionner un type...',
    professional: 'Professionnel',
    merchant: 'Marchand',
    add: 'Ajouter',
  },
  countries: {
    france: 'France',
    uk: 'Royaume-Uni',
    italy: 'Italie',
    spain: 'Espagne',
    germany: 'Allemagne',
  },
  dashboard: {
    title: 'Tableau de bord',
    description: 'Consultez votre activité récente et les métriques importantes',
    metrics: {
      totalProducts: 'Total des produits',
      activeSearches: 'Recherches actives',
      recentScans: 'Scans récents',
      potentialProfit: 'Profit potentiel',
    },
    recentProducts: {
      title: 'Produits récents',
      description: 'Produits consultés récemment',
      viewAll: 'Voir tout',
    },
    savedSearches: {
      title: 'Recherches sauvegardées',
      description: 'Vos recherches de produits récentes',
      viewAll: 'Voir tout',
      results: 'résultats',
      potentialProfit: 'Profit potentiel',
    },
    recentScans: {
      title: 'Scans récents',
      description: 'Vos scans de produits récents',
      viewAll: 'Voir tout',
      lines: 'lignes',
      matches: 'correspondances',
      potentialProfit: 'Profit potentiel',
    },
    savedProducts: {
      title: 'Produits sauvegardés',
      description: 'Vos produits favoris et leurs variations de prix',
      viewAll: 'Voir tout',
    },
  },
  sidebar: {
    chromeExtension: 'Extension Chrome',
    chromeDescription:
      'Trouvez des produits rentables plus rapidement avec notre extension Chrome.',
    installExtension: "Installer l'extension",
    dashboard: 'Tableau de bord',
    findProduct: 'Trouver un produit',
    reverseSearch: 'Recherche inversée',
    scanList: 'Liste des scans',
    suppliers: 'Fournisseurs',
    affilizSuppliers: 'Fournisseurs Affiliz',
    support: 'Support',
    videos: 'Vidéos',
    videosDescription: 'Apprenez à maximiser vos profits avec nos tutoriels vidéo.',
    watchVideos: 'Voir les vidéos',
    activity: 'ACTIVITÉ',
    products: 'PRODUITS',
    contact: 'CONTACT',
  },
  scanList: {
    addModal: {
      title: 'Ajouter des produits',
      description: 'Ajoutez des produits à votre liste en chargeant un fichier CSV ou Excel.',
    },

    emptyState: {
      title: 'Ajouter des produits',
      description: 'Ajoutez des produits à votre liste en chargeant un fichier CSV ou Excel.',
    },
    mapping: {
      title: 'Mapping des colonnes',
      description:
        'Sélectionnez les colonnes de votre fichier qui correspondent aux champs de ResellCorner.',
      field: 'Champ ResellCorner',
      value: 'Champ du fichier',
    },
  },
  scans: {
    name: 'Nom',
    marketplace: 'Marketplace',
    date: 'Date',
    actions: 'Actions',
    processedProducts: 'Produits traités',
    scannedProducts: 'Produits scannés',
    title: 'Liste des scans',
    description: 'Gérez vos scans de produits',
    addScan: 'Ajouter un scan',
    affectedMarketplaces: 'Affected Marketplaces',
    selectMarketplace: 'Select Marketplaces',
    mapping: {
      title: 'Mapping des colonnes',
      description:
        'Sélectionnez les colonnes de votre fichier qui correspondent aux champs de ResellCorner.',
      field: 'Champ ResellCorner',
      value: 'Champ du fichier',
    },
    error: {
      noMarketplaceSelected: 'Veuillez sélectionner au moins un marketplace.',
      noTitle: 'Veuillez entrer un titre.',
      noFile: 'Veuillez charger un fichier.',
    },
    searchPlaceholder: 'Chercher un scan...',
    status: {
      processing: 'En cours de traitement',
      processed: 'Traité',
      failed: 'Échec',
      pending: 'En attente',
      completed: 'Terminé',
    },
  },
  dropzone: {
    error: 'Erreur lors du chargement du fichier',
    fileLoaded: 'Fichier chargé avec succès',
    fileLoadedError: 'Erreur lors du chargement du fichier',
    fileLoadedErrorMissingQuotes: 'Erreur lors du chargement du fichier: MissingQuotes',
    fileLoadedErrorUndetectableDelimiter:
      'Erreur lors du chargement du fichier: UndetectableDelimiter',
    fileLoadedErrorTooFewFields: 'Erreur lors du chargement du fichier: TooFewFields',
    fileLoadedErrorTooManyFields: 'Erreur lors du chargement du fichier: TooManyFields',
    fileLoadedErrorInvalidQuotes: 'Erreur lors du chargement du fichier: InvalidQuotes',
    MissingQuotes: 'Mauvais formatage des quotes',
    UndetectableDelimiter: 'Délimiteur non détectable',
    TooFewFields: 'Trop peu de champs',
    TooManyFields: 'Trop de champs',
    InvalidQuotes: 'Mauvais formatage des quotes',
    atIndex: "à l'index",
    dragInactive: 'Glisser-déposer votre fichier ici ou cliquer pour charger',
    dragActive: 'Déposez le fichier ici ou cliquez pour charger',
    browse: 'Parcourir',
    supportedFormats: 'Formats supportés: CSV, XLSX, XLS',
  },
  savedSearches: {
    title: 'Recherches sauvegardées',
    save: 'Sauvegarder',
    saveTitle: 'Sauvegarder la recherche',
    name: 'Nom de la recherche',
    namePlaceholder: 'Entrez un nom pour votre recherche',
    nameRequired: 'Le nom est requis',
    saveSuccess: 'Recherche sauvegardée avec succès',
    saveError: "Erreur lors de l'enregistrement de la recherche",
    invalidFilters: 'Les filtres sont invalides',
    delete: 'Supprimer',
    deleteConfirm: 'Êtes-vous sûr de vouloir supprimer cette recherche ?',
    deleteSuccess: 'Recherche supprimée avec succès',
    deleteError: 'Erreur lors de la suppression de la recherche',
    noResults: 'Aucune recherche sauvegardée',
    apply: 'Appliquer',
    new: 'Nouvelle recherche',
    load: 'Charger une recherche',
  },
  account: {
    title: 'Mon compte',
    subtitle: 'Retrouvez toutes les informations liées à votre compte.',
    personalInformation: 'Mes informations personnelles',
    manageSubscription: 'Gérer mon abonnement',
    invoices: 'Mes factures',
    tabs: {
      personal: 'Informations personnelles',
      subscription: 'Abonnement',
      invoices: 'Factures',
    },
    updateProfile: 'Modifier mon profil',
  },
  clearData: {
    title: 'Effacer les données',
    description:
      "Cette action effacera toutes les données stockées localement et vous déconnectera de l'application.",
    button: 'Effacer les données',
  },
  productDetails: {
    viewOnAmazon: 'Voir sur Amazon',
    viewSupplier: 'Voir le fournisseur',
    affiliateLink: 'Lien affilié',
    metrics: {
      amazonPrice: 'Prix Amazon',
      supplierPrice: 'Prix fournisseur',
      roi: 'ROI',
      salesRank: 'Classement des ventes',
    },
    charts: {
      estimatedSales: 'Ventes estimées',
      numberOfOffers: "Nombre d'offres",
      salesRank: 'Classement des ventes',
    },
    financial: {
      title: 'Informations financières',
      fbaFees: 'Frais FBA',
      referralFees: 'Frais de référencement',
      perItemFee: 'Frais par article',
      variableClosingFee: 'Frais de clôture variables',
      totalFees: 'Total des frais',
      profitPerUnit: 'Profit par unité',
      monthlyProfit: 'Profit mensuel',
      vtaRate: 'Taux de TVA',
    },
    additional: {
      title: 'Informations complémentaires',
      brand: 'Marque',
      supplier: 'Fournisseur',
      supplierCountry: 'Pays du fournisseur',
      stockStatus: 'État du stock',
      inStock: 'En stock',
      outOfStock: 'Rupture de stock',
      estimatedSales: 'Ventes estimées',
      fulfillmentType: 'Type de fulfillment',
      expirationDated: 'Date de péremption',
      launchDate: 'Date de lancement',
      lastSalesCheck: 'Dernière vérification des ventes',
      lastUpdated: 'Dernière mise à jour',
    },
  },
};
