import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown } from 'lucide-react';

interface ColumnMappingProps {
  fieldName: string;
  fieldValue: string;
  options: string[];
  value: string;
  onChange: (fieldName: string, value: string) => void;
}

export const ColumnMapping: React.FC<ColumnMappingProps> = ({
  fieldName,
  fieldValue,
  options,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-[1fr,2fr,2fr] items-center gap-4">
      <div className="text-sm font-medium text-gray-700">{t(`common.${fieldName}`)}</div>
      <div className="relative">
        <select
          value={fieldValue}
          name={fieldName}
          onChange={(e) => {
            const value = e.target.value;
            onChange(fieldName, value);
          }}
          className="w-full appearance-none rounded-lg border border-gray-200 bg-white px-3 py-2 pr-8 text-sm outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
        >
          <option value="">{t('scans.mapping.field')}</option>
          {options.map((field) => (
            <option key={field} value={field}>
              {field}
            </option>
          ))}
        </select>
        <ChevronDown
          size={16}
          className="pointer-events-none absolute right-3 top-1/2 -translate-y-1/2 transform text-gray-400"
        />
      </div>
      <div className="text-sm text-gray-600">{value}</div>
    </div>
  );
};
