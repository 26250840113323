import React from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { Plus } from 'lucide-react';
import { CopiableCell } from '../Suppliers/SupplierGrid/CopiableCell';
import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { TSupplier } from '../../apiClient/suppliers/suppliers';
import { TAffilizSupplier } from '../../store/suppliers/suppliers';
import { toast } from 'react-hot-toast';
import { COUNTRY_FLAGS, COUNTRY_NAMES } from '../../types/supplier';

interface AffilizSuppliersGridProps {
  suppliers: TAffilizSupplier[];
  onAddSupplier: (data: Partial<TSupplier>) => void;
}

type CountryType = 'FR' | 'UK' | 'IT' | 'ES' | 'DE' | null | undefined;
type SupplierType = 'pro' | 'supplier' | undefined;

type SupplierColDef = ColDef<TAffilizSupplier>;

export const AffilizSuppliersGrid: React.FC<AffilizSuppliersGridProps> = ({
  suppliers,
  onAddSupplier,
}) => {
  const { t } = useTranslation();

  const typeMap: Partial<Record<string, string>> = {
    '': t('affiliz.selectType'),
    pro: t('affiliz.professional'),
    supplier: t('affiliz.merchant'),
  };

  const columnDefs: SupplierColDef[] = [
    {
      field: '_id',
      headerName: 'Shop ID',
      cellRenderer: CopiableCell,
    },
    {
      field: 'shopName',
      headerName: 'Shop Name',
      flex: 1,
    },
    {
      field: 'country',
      headerName: 'Country',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['', ...Object.keys(COUNTRY_FLAGS)],
      },
      valueFormatter: (params: ValueFormatterParams) => {
        const value = params.value as CountryType;
        return value
          ? (COUNTRY_FLAGS[value as keyof typeof COUNTRY_FLAGS] || String(value)) +
              ' - ' +
              (COUNTRY_NAMES[value] || String(value))
          : t('affiliz.selectCountry');
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['', 'pro', 'supplier'],
      },
      valueFormatter: (params: ValueFormatterParams) => {
        const value = params.value as SupplierType;
        return value ? typeMap[value] || String(value) : t('affiliz.selectType');
      },
    },
    {
      headerName: 'Actions',
      cellRenderer: (params: { data: TAffilizSupplier }) => {
        const handleAdd = () => {
          if (!params.data) return;

          const { _id: shopId, shopName, type, country } = params.data;

          if (shopId && shopName && type && country) {
            onAddSupplier({
              affilizId: shopId,
              name: shopName,
              type,
              country,
              isActive: false,
              lastUpdateDate: new Date(),
              hasAffiliation: true,
            });
          } else {
            toast.error(t('affiliz.addSupplierError'));
          }
        };

        return (
          <button
            onClick={handleAdd}
            className="flex items-center gap-2 rounded bg-green-500 px-3 py-1 text-sm text-white hover:bg-green-600"
          >
            <Plus size={14} />
            {t('affiliz.add')}
          </button>
        );
      },
    },
  ];

  return (
    <div className="ag-theme-alpine h-[600px] w-full">
      <AgGridReact
        rowData={suppliers}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={50}
        defaultColDef={{
          sortable: true,
          resizable: true,
          filter: true,
        }}
      />
    </div>
  );
};
