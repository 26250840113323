import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ProductList, TGridData } from '../ProductList/ProductList';
import { ProductCategories } from './ProductCategories';
import { TablePagination } from '../Pagination/Pagination';

import { SearchHeader } from './SearchHeader';
import { useProductSearch } from '../../hooks/useProductSearch';
import { useProductGridConfig } from './ProductGridConfig';
import { useLocation } from 'react-router-dom';
import { AppTour } from '../Tour/AppTour';
import { useUser } from '../../contexts/UserContext';
import * as apiClient from '../../apiClient';
import * as Store from '../../store';
import {
  formatFilterStateToSearchConditions,
  formatSearchConditionsToFilterState,
  TFilterRanges,
} from '../../utils/filter';
export const ProductSearch: React.FC = () => {
  const { user } = useUser();
  const dispatch = Store.useDispatch();
  const gridRef = useRef<AgGridReact<TGridData>>(null);
  const selectedMarketplaces = Store.useAppSelector(
    Store.userSettings.selectors.selectSelectedMarketplaces
  );
  const [showOnboarding, setShowOnboarding] = useState(false);

  const isOnboardingCompleted = useMemo(() => user?.didOnboarding, [user]);

  const { data, handleSubmit, paginateSearch, isLoading, conditions } = useProductSearch(gridRef);

  const { gridOptions } = useProductGridConfig();

  const didFirstSearch = useRef(false);
  const oldConditions = useRef(conditions);

  useEffect(() => {
    const _data = formatSearchConditionsToFilterState(conditions) as Record<string, unknown>;
    const prequery = {
      ..._data,
      amazonCountry: selectedMarketplaces,
    } as Record<string, unknown>;
    if (
      _data.amazonCountry !== selectedMarketplaces &&
      ((_data.supplier as string[]) || []).length > 0
    ) {
      const query = formatFilterStateToSearchConditions({
        state: prequery as unknown as Record<string, unknown>,
      });
      handleSubmit(query);
    }
  }, [conditions, selectedMarketplaces, oldConditions, handleSubmit]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isOnboardingCompleted && !didFirstSearch.current) {
        if (data?.docs?.length === 0) {
          const searchConditions = formatFilterStateToSearchConditions({
            state: {
              estimatedSales: { min: 10, max: 10000 },
              roi: { min: 30, max: 10000 },
            } as TFilterRanges,
            suppliers: ['65bd5600bc6de92a651f048b'],
            amazonCountry: selectedMarketplaces,
          });
          handleSubmit(searchConditions);
          didFirstSearch.current = true;
          setShowOnboarding(true);
        }
      }
    }, 250);
    return () => clearTimeout(timeout);
  }, [isOnboardingCompleted, handleSubmit, data, selectedMarketplaces]);

  const handlePageChange = (page: number) => {
    paginateSearch(page);
  };

  const completeOnboarding = async () => {
    try {
      await apiClient.user.post('onboarding', { didOnboarding: true });
      dispatch(Store.auth.fetchCurrentUser());
      setShowOnboarding(false);
    } catch (error) {
      console.error(error);
    }
  };

  const { state } = useLocation();
  const isSearcheFromState = useRef(false);
  const searchString = JSON.stringify(state?.searchQuery || {});
  useEffect(() => {
    if (searchString && !isSearcheFromState.current) {
      isSearcheFromState.current = true;
      setTimeout(() => {
        handleSubmit(JSON.parse(searchString));
      }, 100);
    }
  }, [handleSubmit, searchString]);

  return (
    <div className="space-y-6">
      <AppTour isOpen={showOnboarding} onCompleted={completeOnboarding} />

      <SearchHeader onSubmit={handleSubmit} conditions={JSON.stringify(conditions)} />

      <div className="rounded-lg bg-white p-6 shadow-sm">
        <ProductCategories />
      </div>
      {isOnboardingCompleted && (
        <div className="rounded-lg bg-white p-3 shadow-sm">
          {/* Pagination Component */}
          <TablePagination
            meta={{
              total: data.meta.totalDocs, // Total des produits
              perPage: data.meta.limit, // Produits par page
              currentPage: data.meta.page, // Page actuelle
              lastPage: data.meta.totalPages, // Dernière page
              hasNextPage: data.meta.hasNextPage,
              hasPrevPage: data.meta.hasPrevPage,
            }}
            onPageChange={handlePageChange}
            isLoading={isLoading}
          />
        </div>
      )}
      <ProductList
        docs={data.docs}
        isLoading={isLoading}
        meta={data.meta}
        gridRef={gridRef}
        gridOptions={gridOptions}
        conditions={conditions}
      />
    </div>
  );
};
