import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from '../components/Auth/AuthLayout';
import { toast } from 'react-hot-toast';
import * as apiClient from '../apiClient';
import { Link } from 'react-router-dom';

const forgotPasswordSchema = z.object({
  email: z.string().email(),
});

type ForgotPasswordValues = z.infer<typeof forgotPasswordSchema>;

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();

  const handleSubmit = async (values: ForgotPasswordValues) => {
    try {
      await apiClient.auth.forgotPassword(values.email);
      toast.success(t('auth.forgotPassword.emailSent'));
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      console.error('Password reset error:', error);
    }
  };

  return (
    <AuthLayout>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={toFormikValidationSchema(forgotPasswordSchema)}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="space-y-6">
            <div>
              <label className="mb-1 block text-sm font-medium text-gray-700">
                {t('auth.login.email')} <span className="text-red-500">*</span>
              </label>
              <Field
                type="email"
                name="email"
                className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
              />
              <ErrorMessage name="email" component="div" className="mt-1 text-sm text-red-600" />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full rounded-lg bg-orange-500 px-4 py-2 text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-200 disabled:opacity-50"
            >
              {t('auth.forgotPassword.submit')}
            </button>

            <div className="mt-4 text-center">
              <p className="text-sm text-gray-600">
                {t('auth.forgotPassword.backToLogin')}{' '}
                <Link to="/login" className="font-medium text-orange-500 hover:text-orange-600">
                  {t('auth.forgotPassword.loginLink')}
                </Link>
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};
