import React from 'react';
import * as Store from '../../../store';
import { useSelector } from 'react-redux';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface AffilizSelectorProps {
  value: string;
  supplierId: string;
  onCancel: () => void;
  onSuccess: () => void;
}

export const AffilizSelector: React.FC<AffilizSelectorProps> = ({
  value,
  onCancel,
  onSuccess,
  supplierId,
}) => {
  const dispatch = Store.useDispatch();
  const { t } = useTranslation();
  const affilizCollection = useSelector(Store.suppliers.selectors.selectUnusedAffilizIndexes);
  return (
    <div className="flex items-center gap-2">
      <select
        defaultValue={value}
        className="rounded border border-gray-200 px-2 py-1 text-sm"
        onChange={(e) => {
          console.log('====================================');
          console.log(e.target.value);
          console.log('====================================');
          dispatch(Store.suppliers.linkAffilizSupplier({ supplierId, affilizId: e.target.value }));
          onSuccess();
        }}
      >
        <option value="">{t('affiliz.select')}</option>
        {affilizCollection.map((affiliz) =>
          affiliz._id !== null ? (
            <option key={affiliz._id} value={affiliz._id}>
              {affiliz.shopName}({affiliz._id})
            </option>
          ) : null
        )}
        {/* Add your affiliz options here */}
      </select>
      <button onClick={onCancel} className="text-gray-400 hover:text-gray-600">
        <X size={16} />
      </button>
    </div>
  );
};
