export const API_URL = import.meta.env.VITE_REACT_APP_API_URL || process.env.REACT_APP_API_URL;
export const LWA_CLIENT_ID =
  import.meta.env.VITE_LWA_CLIENT_ID ||
  import.meta.env.VITE_REACT_APP_LWA_CLIENT_ID ||
  process.env.REACT_APP_LWA_CLIENT_ID;

export const STRIPE_TABLE_PRICE_ID =
  import.meta.env.VITE_STRIPE_TABLE_PRICE_ID ||
  import.meta.env.VITE_REACT_APP_STRIPE_TABLE_PRICE_ID ||
  process.env.REACT_APP_STRIPE_TABLE_PRICE_ID;

export const STRIPE_PUBLISHABLE_KEY =
  import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const SENTRY_URL =
  process.env.SENTRY_URL ||
  import.meta.env.VIRE_SENTRY_URL ||
  'https://4ab54dc362ad03c4c0c86d83d6c46caa@o4508681900654592.ingest.de.sentry.io/4508681917104208';
export const SENTRY_AUTH_TOKEN =
  process.env.SENTRY_AUTH_TOKEN ||
  import.meta.env.VITE_SENTRY_AUTH_TOKEN ||
  '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';
