interface Meta {
  total: number;
  startIndex: number;
  endIndex: number;
  // lastPage: number;
  currentPage: number;
}

export const ProductCount = ({ total, startIndex, endIndex }: Meta) => {
  return (
    <div className="flex-1 text-sm text-gray-700">
      Affichage <span className="font-medium">{startIndex}</span> à{' '}
      <span className="font-medium">{endIndex}</span> sur{' '}
      <span className="font-medium">{total}</span> Produits
    </div>
  );
};
