import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Package, ExternalLink, DollarSign, Percent, BarChart2, Tag, Store } from 'lucide-react';
import { MarketplaceFlag } from '../components/ProductList/MarketplaceFlag';
import { StatCard } from '../components/ProductDetails/StatCard';
import { ProductChart } from '../components/ProductDetails/ProductChart';
import { DetailSection } from '../components/ProductDetails/DetailSection';
import { PageLayout } from '../components/common/PageLayout';
import { format } from 'date-fns';
import * as apiClient from '../apiClient';
interface Delivery {
  id: string;
  name: string;
  price: number;
  currency: string;
  estimatedDays: number;
}

interface Product {
  // Basic Information
  id?: string;
  oldId?: string;
  asin: string;
  name: string;
  description?: string;
  modelName?: string;
  titles?: string[];

  // Identifiers
  ean: string;
  upc?: string;
  upcList?: string[];
  eanList?: string[];
  gtin?: string;
  productId?: string;

  // Pricing & Fees
  amazonPrice: number;
  amazonPriceHT: number;
  price?: number;
  supplierPrice: number;
  supplierPriceHT: number;
  supplierPriceTTC: number;
  profit: number;
  profitMargin: number;
  roi: number;
  profitPerMonth: number;

  // Amazon Fees
  fbaFees: number;
  fbaFeesCurrency?: string;
  referralFees: number;
  referralFeesCurrency?: string;
  totalFeesEstimate: number;
  totalFeesEstimateCurrency?: string;
  variableClosingFee?: number;
  variableClosingFeeCurrency?: string;
  perItemFee?: number;
  perItemFeeCurrency?: string;

  // Images & URLs
  imageUrl: string;
  amazonImageUrl?: string;
  amazonImageUrls?: string[];
  amazonUrl?: string;
  link?: string;
  affiliateLink?: string;
  supplierUrl?: string;
  supplierImgUrl?: string;

  // Marketplace & Categories
  amazonCountry: string;
  countryCode?: string;
  currencyCode?: string;
  marketplaceId: string;
  category: string;
  categories?: {
    primary: string;
    secondary?: string;
    [key: string]: string | undefined;
  };
  rankingsByCategoryId?: {
    [categoryId: string]: number;
  };

  // Supplier Information
  supplier?: string;
  supplierCountry?: string;
  supplierName: string;
  supplierProductName?: string;
  shopId?: string;
  shopName?: string;
  shopIcon?: string;
  shopTechnicalName?: string;
  sellerType?: string;
  brandId?: string;
  brandName: string;

  // Status & Metrics
  stock: boolean;
  IsAmazonFulfilled: boolean;
  isExpirationDatedProduct?: boolean;
  estimatedSales: number;
  salesRank: number;
  highestRank?: number;
  rank?: number;

  // Offers & Sales Data
  numberOfOffersPerMonth: Record<string, number>;
  numberOfNewOffersPerMonth: Record<string, number>;
  numberOfUsedOffersPerMonth?: Record<string, number>;

  // Dates
  lastUpdateDate: string;
  lastSalesCheck?: Date;
  productSiteLaunchDate?: Date;
  createdAt?: Date;
  updateAt?: Date;

  // Other
  vtaRate: number;
  batchId?: string;
  deliveries?: Delivery[];
}

// Mock data structure matching the schema
const mockChartData = [
  {
    month: 'Jan',
    estimatedSales: 120,
    numberOfOffers: 25,
    numberOfNewOffers: 8,
    salesRank: 1500,
  },
  {
    month: 'Feb',
    estimatedSales: 145,
    numberOfOffers: 28,
    numberOfNewOffers: 10,
    salesRank: 1200,
  },
  {
    month: 'Mar',
    estimatedSales: 180,
    numberOfOffers: 32,
    numberOfNewOffers: 12,
    salesRank: 980,
  },
  {
    month: 'Apr',
    estimatedSales: 165,
    numberOfOffers: 30,
    numberOfNewOffers: 9,
    salesRank: 1100,
  },
  {
    month: 'May',
    estimatedSales: 200,
    numberOfOffers: 35,
    numberOfNewOffers: 15,
    salesRank: 850,
  },
  {
    month: 'Jun',
    estimatedSales: 220,
    numberOfOffers: 38,
    numberOfNewOffers: 18,
    salesRank: 750,
  },
];

export const ProductDetails: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    if (id) {
      apiClient.products.get(id).then((response) => {
        console.log(response.data);
        setProduct(response.data as unknown as Product);
      });
    }
  }, [id]);
  // Mock product data structure matching the schema
  //   const product: Product = {
  //     id: '123456',
  //   asin: id || 'B0CLL8ZXR',
  //   ean: '3662361001998',
  //   upc: '123456789012',
  //   upcList: ['123456789012'],
  //   eanList: ['3662361001998'],
  //   gtin: '3662361001998',
  //   name: 'Decadent Chocolate Truffle Box - Indulgent Chocolate Truffle Collection',
  //   description: 'Luxury chocolate truffles made with the finest ingredients',
  //   modelName: 'Premium Collection',
  //   titles: ['Chocolate Truffles', 'Luxury Chocolate Box', 'Premium Chocolate Gift'],

  //   imageUrl:
  //     'https://images.unsplash.com/photo-1481391319762-47dff72954d9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=800&q=80',
  //   amazonImageUrl: 'https://images.unsplash.com/photo-1481391319762-47dff72954d9',
  //   amazonImageUrls: ['https://images.unsplash.com/photo-1481391319762-47dff72954d9'],
  //   amazonUrl: `https://amazon.fr/dp/B0CLL8ZXR`,

  //   amazonPrice: 24.99,
  //   amazonPriceHT: 20.82,
  //   price: 24.99,
  //   supplierPrice: 12.5,
  //   supplierPriceHT: 10.42,
  //   supplierPriceTTC: 12.5,
  //   profit: 8.25,
  //   profitMargin: 33,
  //   roi: 66,
  //   profitPerMonth: 1237.5,

  //   fbaFees: 4.99,
  //   fbaFeesCurrency: 'EUR',
  //   referralFees: 2.5,
  //   referralFeesCurrency: 'EUR',
  //   totalFeesEstimate: 7.49,
  //   totalFeesEstimateCurrency: 'EUR',
  //   variableClosingFee: 0,
  //   perItemFee: 0.99,
  //   perItemFeeCurrency: 'EUR',

  //   amazonCountry: 'FR',
  //   countryCode: 'FR',
  //   currencyCode: 'EUR',
  //   marketplaceId: 'A13V1IB3VIYZZH',
  //   category: 'Food & Beverage',
  //   categories: {
  //     primary: 'Food & Beverage',
  //     secondary: 'Chocolate',
  //   },
  //   rankingsByCategoryId: {
  //     'food-beverage': 1250,
  //     chocolate: 150,
  //   },

  //   brandName: 'Luxury Chocolates',
  //   brandId: 'LUXCHOC123',
  //   supplierName: 'Wholesale Delights',
  //   supplierCountry: 'FR',
  //   supplierProductName: 'Premium Chocolate Truffles Box',
  //   supplierUrl: 'https://supplier.com/product/123',
  //   supplierImgUrl: 'https://supplier.com/images/123.jpg',
  //   sellerType: 'professional',

  //   lastUpdateDate: new Date().toISOString(),
  //   lastSalesCheck: new Date(),
  //   productSiteLaunchDate: new Date('2023-01-01'),
  //   createdAt: new Date('2023-01-01'),

  //   estimatedSales: 150,
  //   salesRank: 1250,
  //   highestRank: 750,
  //   rank: 1250,
  //   stock: true,
  //   IsAmazonFulfilled: true,
  //   isExpirationDatedProduct: true,

  //   numberOfOffersPerMonth: {
  //     '2024-01': 25,
  //     '2024-02': 28,
  //     '2024-03': 32,
  //   },
  //   numberOfNewOffersPerMonth: {
  //     '2024-01': 8,
  //     '2024-02': 10,
  //     '2024-03': 12,
  //   },
  //   numberOfUsedOffersPerMonth: {
  //     '2024-01': 4,
  //     '2024-02': 5,
  //     '2024-03': 6,
  //   },

  //   vtaRate: 20,
  //   batchId: 'BATCH123',
  // };

  const formatPrice = (price: number) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
    }).format(price);
  };

  return (
    <PageLayout
      header={
        <div className="border-b border-gray-100 p-6">
          <div className="flex gap-6">
            <div className="h-48 w-48 flex-shrink-0">
              <img
                src={product?.imageUrl}
                alt={product?.name}
                className="h-full w-full rounded-lg border border-gray-100 object-contain"
              />
            </div>
            <div className="flex-1">
              <div className="mb-2 flex items-center gap-2">
                <MarketplaceFlag marketplace={product?.amazonCountry || ''} />
                <h1 className="text-2xl font-semibold">{product?.name || ''}</h1>
              </div>

              <div className="mb-4 text-gray-600">{product?.description || ''}</div>

              <div className="mb-6 grid grid-cols-4 gap-4">
                <div className="flex items-center gap-2">
                  <Package className="text-gray-400" size={20} />
                  <div>
                    <div className="text-sm text-gray-500">ASIN</div>
                    <div className="font-medium">{product?.asin || ''}</div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Tag className="text-gray-400" size={20} />
                  <div>
                    <div className="text-sm text-gray-500">EAN</div>
                    <div className="font-medium">{product?.ean || ''}</div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Store className="text-gray-400" size={20} />
                  <div>
                    <div className="text-sm text-gray-500">{t('common.category')}</div>
                    <div className="font-medium">{product?.category || ''}</div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <Tag className="text-gray-400" size={20} />
                  <div>
                    <div className="text-sm text-gray-500">UPC</div>
                    <div className="font-medium">{product?.upc || '-'}</div>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <a
                  href={product?.amazonUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 rounded-lg bg-orange-500 px-4 py-2 text-white transition-colors hover:bg-orange-600"
                >
                  {t('productDetails.viewOnAmazon')}
                  <ExternalLink size={16} />
                </a>
                {product?.affiliateLink && (
                  <a
                    href={product?.affiliateLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 rounded-lg border border-gray-200 px-4 py-2 transition-colors hover:bg-gray-50"
                  >
                    {t('productDetails.affiliateLink')}
                    <ExternalLink size={16} />
                  </a>
                )}
                {product?.supplierUrl && (
                  <a
                    href={product?.supplierUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 rounded-lg border border-gray-200 px-4 py-2 transition-colors hover:bg-gray-50"
                  >
                    {t('productDetails.viewSupplier')}
                    <ExternalLink size={16} />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    >
      <div className="space-y-6">
        {/* Key Metrics */}
        <div className="grid grid-cols-4 gap-4">
          <StatCard
            icon={<DollarSign className="text-blue-500" size={20} />}
            label="productDetails.metrics.amazonPrice"
            value={formatPrice(product?.amazonPrice || 0)}
            suffix={`HT: ${formatPrice(product?.amazonPriceHT || 0)}`}
          />
          <StatCard
            icon={<DollarSign className="text-green-500" size={20} />}
            label="productDetails.metrics.supplierPrice"
            value={formatPrice(product?.supplierPrice || 0)}
            suffix={`HT: ${formatPrice(product?.supplierPriceHT || 0)}`}
          />
          <StatCard
            icon={<Percent className="text-orange-500" size={20} />}
            label="productDetails.metrics.roi"
            value={product?.roi || 0}
            suffix="%"
          />
          <StatCard
            icon={<BarChart2 className="text-purple-500" size={20} />}
            label="productDetails.metrics.salesRank"
            value={product?.salesRank?.toLocaleString() || 0}
          />
        </div>

        {/* Charts */}
        <div className="grid grid-cols-3 gap-6">
          <ProductChart
            title="productDetails.charts.estimatedSales"
            data={mockChartData}
            dataKey="estimatedSales"
            color="green"
          />
          <ProductChart
            title="productDetails.charts.numberOfOffers"
            data={mockChartData}
            dataKey="numberOfOffers"
            color="blue"
          />
          <ProductChart
            title="productDetails.charts.salesRank"
            data={mockChartData.map((d) => ({ ...d, salesRank: -d.salesRank }))}
            dataKey="salesRank"
            color="purple"
          />
        </div>

        {/* Detailed Information */}
        <div className="grid grid-cols-2 gap-6">
          <DetailSection
            title={t('productDetails.financial.title')}
            items={[
              {
                label: 'productDetails.financial.fbaFees',
                value: formatPrice(product?.fbaFees || 0),
              },
              {
                label: 'productDetails.financial.referralFees',
                value: formatPrice(product?.referralFees || 0),
              },
              {
                label: 'productDetails.financial.perItemFee',
                value: formatPrice(product?.perItemFee || 0),
              },
              {
                label: 'productDetails.financial.variableClosingFee',
                value: formatPrice(product?.variableClosingFee || 0),
              },
              {
                label: 'productDetails.financial.totalFees',
                value: formatPrice(product?.totalFeesEstimate || 0),
              },
              {
                label: 'productDetails.financial.profitPerUnit',
                value: formatPrice(product?.profit || 0),
                valueClassName: `font-medium ${
                  product?.profit && product?.profit > 0 ? 'text-green-600' : 'text-red-600'
                }`,
              },
              {
                label: 'productDetails.financial.monthlyProfit',
                value: formatPrice(product?.profitPerMonth || 0),
                valueClassName: `font-medium ${
                  product?.profitPerMonth && product?.profitPerMonth > 0
                    ? 'text-green-600'
                    : 'text-red-600'
                }`,
              },
              {
                label: 'productDetails.financial.vtaRate',
                value: `${product?.vtaRate || 20}%`,
              },
            ]}
          />

          <DetailSection
            title={t('productDetails.additional.title')}
            items={[
              {
                label: 'productDetails.additional.brand',
                value: product?.brandName || '',
              },
              {
                label: 'productDetails.additional.supplier',
                value: product?.supplierName || '',
              },
              {
                label: 'productDetails.additional.supplierCountry',
                value: product?.supplierCountry || '-',
              },
              {
                label: 'productDetails.additional.stockStatus',
                value: product?.stock
                  ? t('productDetails.additional.inStock')
                  : t('productDetails.additional.outOfStock'),
                valueClassName: `font-medium ${product?.stock ? 'text-green-600' : 'text-red-600'}`,
              },
              {
                label: 'productDetails.additional.estimatedSales',
                value: product?.estimatedSales || 0,
              },
              {
                label: 'productDetails.additional.fulfillmentType',
                value: product?.IsAmazonFulfilled ? 'FBA' : 'FBM',
                valueClassName: product?.IsAmazonFulfilled ? 'text-orange-600' : 'text-blue-600',
              },
              {
                label: 'productDetails.additional.expirationDated',
                value: product?.isExpirationDatedProduct ? t('common.yes') : t('common.no'),
              },
              {
                label: 'productDetails.additional.launchDate',
                value: product?.productSiteLaunchDate
                  ? format(new Date(product?.productSiteLaunchDate), 'PPP')
                  : '-',
              },
              {
                label: 'productDetails.additional.lastSalesCheck',
                value: product?.lastSalesCheck
                  ? format(new Date(product?.lastSalesCheck), 'PPP')
                  : '-',
              },
              {
                label: 'productDetails.additional.lastUpdated',
                value: format(new Date(product?.lastUpdateDate || new Date()), 'PPpp'),
              },
            ]}
          />
        </div>
      </div>
    </PageLayout>
  );
};
