import React from 'react';
import { LogoImage ,LogoImageR } from '../../assets/images'; 

interface LogoProps {
  collpase : boolean;
}
export const Logo: React.FC<LogoProps> = ({collpase}) => (
  <div className="flex items-center p-4 welcome-step">
    <img src={ collpase ? LogoImageR :LogoImage } alt="ReSell Corner" className="h-8" />
  </div>
);
