import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { TSavedSearch, TUserSettings } from '../../apiClient/userSettings/userSettings';
import * as apiClient from '../../apiClient';
import { RootState } from '..';

export const reducerName = 'userSettings';

interface UserSettingsState {
  savedSearches: {
    [key: string]: TSavedSearch;
  };
  savedAsins: string[];
  selectedMarketplaces: string[];
  savedSearchIds: string[];
  isLoading: boolean;
  error: string | null;
}

const defaultSavedSearch: TSavedSearch = {
  _id: '1',
  userId: '1',
  name: 'Default',
  conditions: {
    supplier: {
      $in: ['65bd5600bc6de92a651f048b'],
    },
    estimatedSales: {
      $gte: 1,
      $lte: 100,
    },
    roi: {
      $gte: 30,
      $lte: 100,
    },
  },
  dateOfCreation: new Date(),
};

const initialState: UserSettingsState = {
  savedSearches: { [defaultSavedSearch._id]: defaultSavedSearch },
  savedAsins: [],
  savedSearchIds: [defaultSavedSearch._id],
  isLoading: false,
  error: null,
  selectedMarketplaces: ['FR', 'DE', 'IT', 'ES', 'UK'],
};

export const saveSearch = createAsyncThunk(
  `${reducerName}/saveSearch`,
  async ({ name, conditions }: { name: string; conditions: Record<string, unknown> }) => {
    const response = await apiClient.userSettings.saveSearch(name, conditions);
    return response.data;
  }
);

export const fetchUserSettings = createAsyncThunk(`${reducerName}/fetchUserSettings`, async () => {
  const response = await apiClient.userSettings.getUserSettings();
  return response.data as TUserSettings;
});

export const deleteSavedSearch = createAsyncThunk(
  `${reducerName}/deleteSavedSearch`,
  async (searchId: string) => {
    await apiClient.userSettings.deleteSavedSearch(searchId);
    return searchId;
  }
);

export const saveAsin = createAsyncThunk(`${reducerName}/saveAsin`, async (asin: string) => {
  await apiClient.userSettings.saveAsin(asin);
  return asin;
});

export const unsaveAsin = createAsyncThunk(`${reducerName}/unsaveAsin`, async (asin: string) => {
  await apiClient.userSettings.unsaveAsin(asin);
  return asin;
});

const userSettingsSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedMarketplaces: (state, action: PayloadAction<string[]>) => {
      state.selectedMarketplaces = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveSearch.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(saveSearch.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        console.log(payload);
        state.savedSearches = payload.savedSearches.reduce(
          (acc, search: TSavedSearch) => {
            acc[search._id] = {
              ...search,
              conditions: JSON.parse(search.conditions as unknown as string),
            };
            return acc;
          },
          {} as UserSettingsState['savedSearches']
        );
        state.savedSearchIds = Object.keys(state.savedSearches);
      })
      .addCase(saveSearch.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to save search';
      })

      .addCase(fetchUserSettings.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.savedSearches = payload.savedSearches.reduce(
          (acc, search: TSavedSearch) => {
            acc[search._id] = {
              ...search,
              conditions: JSON.parse(search.conditions as unknown as string),
            };
            return acc;
          },
          {} as UserSettingsState['savedSearches']
        );
        state.savedSearchIds = payload.savedSearches.map((search) => search._id);
        state.savedAsins = payload.savedAsins;
      })
      .addCase(fetchUserSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch user settings';
      })

      .addCase(deleteSavedSearch.fulfilled, (state, { payload }: PayloadAction<string>) => {
        delete state.savedSearches[payload];
        state.savedSearchIds = state.savedSearchIds.filter((id) => id !== payload);
      })
      .addCase(saveAsin.fulfilled, (state, { payload }: PayloadAction<string>) => {
        if (!state.savedAsins.includes(payload)) {
          state.savedAsins.push(payload);
        }
      })
      .addCase(unsaveAsin.fulfilled, (state, { payload }: PayloadAction<string>) => {
        state.savedAsins = state.savedAsins.filter((id) => id !== payload);
      });
  },
});

// Selectors
const selectState = (state: RootState) => state[reducerName];
const selectSavedSearches = createSelector(selectState, (state) => ({
  ...state?.savedSearches,
  [defaultSavedSearch._id]: defaultSavedSearch,
}));
const selectSavedAsins = createSelector(selectState, (state) => state?.savedAsins);
const selectSavedSearchIds = createSelector(selectState, (state) => state?.savedSearchIds);
const selectIsLoading = createSelector(selectState, (state) => state?.isLoading);
const selectError = createSelector(selectState, (state) => state?.error);
const selectSelectedMarketplaces = createSelector(
  selectState,
  (state) => state?.selectedMarketplaces
);

export const selectors = {
  selectState,
  selectSavedSearches,
  selectSavedAsins,
  selectSavedSearchIds,
  selectIsLoading,
  selectError,
  selectSelectedMarketplaces,
};

export const { reset } = userSettingsSlice.actions;
export const actions = userSettingsSlice.actions;
export const reducer = userSettingsSlice.reducer;
