import React from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from '../common/Modal';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

interface PreviewData {
  [key: string]: string | number;
}

interface ScanPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: PreviewData[];
  columnDefs: ColDef<PreviewData>[];
}

export const ScanPreviewModal: React.FC<ScanPreviewModalProps> = ({
  isOpen,
  onClose,
  data,
  columnDefs,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('scans.preview')}
      size="xl"
      allowFullScreen
    >
      <div className="h-[calc(100vh-200px)] w-full">
        <div className="ag-theme-alpine h-full">
          <AgGridReact<PreviewData>
            rowData={data}
            columnDefs={columnDefs}
            defaultColDef={{
              flex: 1,
              minWidth: 100,
              resizable: true,
              sortable: true,
              filter: true
            }}
            pagination={true}
            paginationPageSize={10}
          />
        </div>
      </div>
    </Modal>
  );
}; 