import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import * as apiClient from '../apiClient';
import { useDispatch } from 'react-redux';
import * as Store from '../store';
import { AuthLayout } from '../components/Auth/AuthLayout';
import { useTranslation } from 'react-i18next';
import { TUser } from '../types/user';

interface EmailVerificationResponse {
  data: TUser;
  token: string;
}

export const VerifyEmail: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) {
        toast.error(t('auth.verifyEmail.invalidToken'));
        navigate('/login');
        return;
      }

      try {
        const response = await apiClient.auth.emailVerification(token);
        const { data: userData, token: authToken } =
          response as unknown as EmailVerificationResponse;
        dispatch(Store.auth.setUser({ user: userData, token: authToken }));
        toast.success(t('auth.verifyEmail.success'));
        navigate('/account?tab=subscription');
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
        } else {
          toast.error(t('auth.verifyEmail.error'));
        }
        navigate('/login');
      }
    };

    verifyEmail();
  }, [token, navigate, dispatch, t]);

  return (
    <AuthLayout>
      <div className="flex flex-col items-center justify-center space-y-4">
        <div className="text-lg font-medium">{t('auth.verifyEmail.verifying')}</div>
        <div className="text-sm text-gray-500">{t('auth.verifyEmail.wait')}</div>
      </div>
    </AuthLayout>
  );
};
