import ReactDOM from 'react-dom';
import React, { useState } from 'react';
import { IdentifierBadge } from './IdentifierBadge';
import { useNavigate } from 'react-router-dom';

interface AsinTooltipProps {
  asin: string;
}

export const AsinTooltip: React.FC<AsinTooltipProps> = ({ asin }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState<{ top: number; left: number } | null>(null);

  const handleMouseEnter = (e: React.MouseEvent) => {
    const { top, left } = e.currentTarget.getBoundingClientRect();
    setPosition({ top, left });
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <IdentifierBadge type="ASIN" value={asin} />
      {isOpen &&
        position &&
        ReactDOM.createPortal(
          <div
            className="rounded-lg bg-white p-3 text-center text-black shadow-lg"
            style={{
              position: 'fixed',
              top: `${position.top + 20}px`,
              left: `${position.left + 20}px`,
              zIndex: 9999,
              minWidth: '200px',
              borderRadius: '10px',
              padding: '10px',
            }}
          >
            <div className="text-center">
              <span className="text-sm">
                Lancer une recherche
                <br />
                inversée sur ce produit ?
              </span>
            </div>
            <div className="text-center">
              <span className="text-sm">ASIN : {asin}</span>
            </div>
            <button
              onClick={() => {
                navigate(`/reverse-search?asin=${asin}`);
              }}
              className="mt-2 rounded bg-orange-600 px-4 py-2 text-white hover:bg-orange-700"
            >
              Recherche
            </button>
          </div>,
          document.body
        )}
    </div>
  );
};
