import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Package, ArrowRight } from 'lucide-react';
import * as Store from '../../store';
import { formatFilterStateToSearchConditions } from '../../utils/filter';
import { useProductSearch } from '../../hooks/useProductSearch';
import { MarketplaceFlag } from '../ProductList/MarketplaceFlag';

export const RecentProducts: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const savedAsins = Store.useAppSelector(Store.userSettings.selectors.selectSavedAsins);
  const { data, handleSubmit } = useProductSearch();
  const prevAsinsRef = useRef<string[]>([]);

  useEffect(() => {
    if (
      savedAsins.length > 0 &&
      JSON.stringify(prevAsinsRef.current) !== JSON.stringify(savedAsins)
    ) {
      const searchConditions = formatFilterStateToSearchConditions({
        asinList: savedAsins,
      });
      handleSubmit(searchConditions);
      prevAsinsRef.current = savedAsins;
    }
  }, [savedAsins, handleSubmit]);

  const handleProductClick = (asin: string) => {
    // navigate(`/product/${asin}`);
    console.log({ asin });
  };

  const handleViewAll = () => {
    navigate('/saved-asins');
  };

  return (
    <div className="rounded-lg border border-gray-100 bg-white shadow-sm">
      <div className="border-b border-gray-100 p-6">
        <div className="mb-2 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Package size={20} className="text-gray-400" />
            <h2 className="text-lg font-medium">{t('dashboard.savedProducts.title')}</h2>
          </div>
          <button
            onClick={handleViewAll}
            className="flex items-center gap-1 text-sm text-orange-500 hover:text-orange-600"
          >
            {t('common.viewAll')} <ArrowRight size={16} />
          </button>
        </div>
        <p className="text-sm text-gray-500">{t('dashboard.savedProducts.description')}</p>
      </div>
      <div className="divide-y divide-gray-100">
        {data.docs.slice(0, 3).map((product) => (
          <button
            key={product._id}
            onClick={() => handleProductClick(product._id)}
            className="group flex w-full items-center justify-between p-4 text-left transition-colors hover:bg-gray-50"
          >
            <div className="flex items-center gap-4">
              <div className="flex h-12 w-12 items-center justify-center rounded-lg border border-gray-100 bg-gray-50 text-xl">
                <MarketplaceFlag marketplace={product.products[0].amazonCountry} />
              </div>
              <div>
                <div className="mb-1 font-medium transition-colors group-hover:text-orange-500">
                  {product.products[0].title}
                </div>
                <div className="text-sm text-gray-500">
                  ASIN: {product._id} • Price: {product.products[0].supplierPrice.toFixed(2)}€
                </div>
              </div>
            </div>
            <div className="text-right">
              <div className="font-medium text-green-500">
                {product.products?.[0].profit.toFixed(2)}€
              </div>
              <div className="text-sm text-gray-500">
                ROI: {product.products?.[0]?.roi?.toFixed(0)}%
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};
