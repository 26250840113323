import React, { useState } from 'react';
import { SlidersHorizontal } from 'lucide-react';
import { FilterSidebar } from './FilterSidebar';

export const SearchFilters: React.FC<{
  onSubmit: (values: Record<string, unknown>) => void;
  conditions: string;
}> = ({ onSubmit, conditions }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  // const [searchQuery, setSearchQuery] = useState('');

  return (
    <>
      <div className="search-bar-step mt-6 flex flex-col items-center gap-4 md:flex-row">
        <div className="relative flex-1">
          {/* <Search
            className="absolute left-3 top-1/2 -translate-y-1/2 transform text-gray-400"
            size={20}
          /> */}
          {/* <input
            type="text"
            placeholder="Mots-clés..."
            className="w-full rounded-lg border border-gray-200 py-2 pl-10 pr-4 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          /> */}
        </div>

        <button
          onClick={() => setIsFilterOpen(true)}
          className="flex items-center gap-2 rounded-lg border border-gray-200 px-4 py-2 hover:bg-gray-50"
        >
          <SlidersHorizontal size={20} className="text-gray-600" />
          <span>Filtres</span>
        </button>

        {/* <button
          onClick={() => {
            const query = {
              title: {
                $regex: searchQuery,
                $options: 'i',
              },
            };
            onSubmit(query);
          }}
          className="rounded-lg bg-orange-500 px-6 py-2 text-white transition-colors hover:bg-orange-600"
        >
          Rechercher
        </button> */}
      </div>

      <FilterSidebar
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        onSubmit={onSubmit}
        conditions={conditions}
      />
    </>
  );
};
