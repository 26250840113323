import { APIClient } from '../../services/client.service';

export interface TSavedSearch {
  _id: string;
  userId: string;
  name: string;
  conditions: Record<string, unknown>;
  dateOfCreation: Date;
  dateOfLastUpdate?: Date;
}

export interface TUserSettings {
  savedSearches: TSavedSearch[];
  savedAsins: string[];
}

class UserSettingsAPI extends APIClient<TSavedSearch | TUserSettings> {
    constructor() {
        super("user-settings");
    }

    saveSearch = (name: string, conditions: Record<string, unknown>) => {
        return this.post('search', { name, conditions }) as Promise<{ data: TUserSettings }>;
    };

    getUserSettings = () => {
        return this.get('') as Promise<{ data: TUserSettings }>;
    };

    deleteSavedSearch = (searchId: string) => {
        return this.delete(`search/${searchId}`) as Promise<{ data: TUserSettings }>;
    };

    saveAsin = (asin: string) => {
        return this.post('asin', { asin }) as Promise<{ data: TUserSettings }>;
    };

    unsaveAsin = (asin: string) => {
        return this.delete(`asin/${asin}`) as Promise<{ data: TUserSettings }>;
    };
}

export const userSettings = new UserSettingsAPI(); 