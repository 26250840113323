import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ChevronDown, User, Users, LogOut, ArrowLeft, MessageSquare } from 'lucide-react';
import { useUser } from '../../contexts/UserContext';
import * as Store from '../../store';
import { clearToken } from '../../services/token.service'; 
import { ProfileTooltip } from './ProfilTooltip';
interface UserMenuProps {
  onLogout: () => void;
  collapse  :boolean
}

export const UserMenu: React.FC<UserMenuProps> = ({ onLogout ,collapse }) => {
  const { t } = useTranslation();
  const dispatch = Store.useDispatch();
  const navigate = useNavigate();
  const { user, isAdmin, switchBackToAdmin, swichModeActive } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (!user) return null;

  const handleLogoutClick = () => {
    onLogout();
    setIsOpen(false);
    dispatch(Store.auth.logout());
    dispatch(Store.suppliers.reset());
    dispatch(Store.userSettings.reset());
    dispatch(Store.auth.reset());
    dispatch(Store.users.actions.reset());
    clearToken();
    navigate('/login');
  };

  return (
    <div className="relative border-t border-gray-200 p-4" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex w-full items-center gap-3 focus:outline-none"
      > 

      {collapse ?( <ProfileTooltip
            collapse={collapse}
            isAdmin={isAdmin}
            swichModeActive={swichModeActive}
            switchBackToAdmin={switchBackToAdmin}
            handleLogoutClick={handleLogoutClick}
            user={user} // Pass the user object
          />)  :
      (
        <> 
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-orange-500 text-white">
          {user.firstName.charAt(0)}
          {user.lastName.charAt(0)}
        </div>
        <div className="flex-1 text-left">
          <div className="text-sm font-medium">
            {user.firstName} {user.lastName}
          </div>
          <div className="text-xs text-gray-500">{user.email}</div>
        </div>
        </>
      ) }
       
        <ChevronDown
          size={20}
          className={`transform text-gray-400 transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
        />
      </button>

      { isOpen && !collapse && (
        <div className="absolute bottom-full left-0 right-0 z-20 mx-4 mb-2 overflow-hidden rounded-lg border border-gray-100 bg-white shadow-lg">
          <div className="py-1">
            <button
              onClick={() => {
                navigate('/account');
                setIsOpen(false);
              }}
              className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
            >
              <User size={16} />
              {t('user.editProfile')}
            </button>

            {isAdmin && (
              <button
                onClick={() => {
                  navigate('/admin/users');
                  setIsOpen(false);
                }}
                className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
              >
                <Users size={16} />
                {t('user.userList')}
              </button>
            )}

            {swichModeActive && (
              <>
                <button
                  onClick={() => {
                    switchBackToAdmin(() => {
                      navigate('/admin/users');
                      setIsOpen(false);
                    });
                  }}
                  className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
                >
                  <ArrowLeft size={16} />
                  {t('user.switchBackToAdmin')}
                </button>
                {isAdmin && (
                  <button
                    onClick={() => setIsOpen(false)}
                    className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
                  >
                    <MessageSquare size={16} />
                    {t('user.adminMessages')}
                  </button>
                )}
              </>
            )}

            <div className="my-1 h-px bg-gray-100" />

            <button
              onClick={handleLogoutClick}
              className="flex w-full items-center gap-2 px-4 py-2 text-left text-sm text-red-600 hover:bg-red-50"
            >
              <LogOut size={16} />
              {t('user.logout')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
