import { z } from 'zod';
import { APIClient } from '../../services/client.service';
import { AxiosRequestConfig } from 'axios';

export const scanSchema = z.object({
  _id: z.string(),
  fileName: z.string(),
  name: z.string(),
  orginalFileName: z.string(),
  columnIndexes: z.record(z.string(), z.number()),
  dateOfCreation: z.date(),
  userId: z.string(),
  status: z.string(),
  processecedFileName: z.string().nullable(),
  processingStartAt: z.date().nullable(),
  processingEndAt: z.date().nullable(),
  numberOfProducts: z.number().default(0),
  numberOfProductsProcessed: z.number().default(0),
  isUploaded: z.boolean().default(false),
  isDeleted: z.boolean().default(false),
  isProcessed: z.boolean().default(false),
  isCleaned: z.boolean().default(false),
  lastUpdateDate: z.date().default(new Date()),
  vtaRate: z.number().default(0),
  marketPlaceId: z.string().default('A13V1IB3VIYZZH'),
  marketPlaceIds: z.array(z.string()).default(['ALL']),
});

export type TScan = z.infer<typeof scanSchema>;

export class ScansApiClient extends APIClient<TScan> {
  constructor() {
    super('products/scans');
  }

  validate(data: unknown): TScan {
    return scanSchema.parse(data);
  }
  async fetchScans() {
    const response = await this.get(``);
    return response.data;
  }

  async fetchById(id: string) {
    const response = await this.get(`${id}`);
    return response.data;
  }

  async createScan(scan: FormData, options?: AxiosRequestConfig) {
    const response = await this.post('upload', scan, options);
    return response.data;
  }
}
