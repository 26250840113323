import React from 'react';
import { X, Maximize2, Minimize2 } from 'lucide-react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  allowFullScreen?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  footer,
  size = 'lg',
  allowFullScreen = false,
}) => {
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  if (!isOpen) return null;

  const sizeClasses = {
    sm: 'sm:max-w-sm',
    md: 'sm:max-w-xl',
    lg: 'sm:max-w-3xl',
    xl: 'sm:max-w-7xl',
  };

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={onClose} />
        </div>

        <div
          className={`inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full ${
            isFullScreen ? 'fixed inset-4' : sizeClasses[size]
          } sm:align-middle`}
        >
          <div className="flex items-center justify-between border-b px-4 py-3">
            <h3 className="text-lg font-medium text-gray-900">{title}</h3>
            <div className="flex items-center gap-2">
              {allowFullScreen && (
                <button
                  onClick={() => setIsFullScreen(!isFullScreen)}
                  className="rounded p-1 hover:bg-gray-100"
                >
                  {isFullScreen ? (
                    <Minimize2 className="h-5 w-5 text-gray-500" />
                  ) : (
                    <Maximize2 className="h-5 w-5 text-gray-500" />
                  )}
                </button>
              )}
              <button onClick={onClose} className="rounded p-1 hover:bg-gray-100">
                <X className="h-5 w-5 text-gray-500" />
              </button>
            </div>
          </div>

          <div className="px-4 py-3">{children}</div>

          {footer && (
            <div className="border-t bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
