import { APIClient } from '../../services/client.service';
import { z } from 'zod';

// Helper schema for ObjectId validation
const objectIdSchema = z.string().refine(
  (val) => {
    if (val.match(/^[0-9a-fA-F]{24}$/)) {
      return true;
    } else {
      return false;
    }
  },
  { message: 'Invalid ObjectId' }
);

// Delivery sub-schema
const deliverySchema = z.object({
  // Add delivery schema fields here based on your needs
  // Since deliverySchema wasn't provided, I'm leaving it as an empty object
});

// Product Schema
export const productSchema = z.object({
  asin: z.string().optional(),
  ean: z.string().optional(),
  upc: z.string().optional(),
  upcList: z.array(z.string()).optional(),
  eanList: z.array(z.string()).optional(),
  fbaFee: z.number().optional(),
  supplierCountry: z.string().optional(),
  amazonUrl: z.string().optional(),
  amazonPrice: z.number().optional(),
  amazonImageUrl: z.string().optional(),
  amazonImageUrls: z.array(z.string()).optional(),
  amazonCountry: z.string().optional(),
  category: z.string().optional(),
  id: z.string().optional(),
  oldId: z.string().optional(),
  affiliateLink: z.string().optional(),
  brandId: z.string().optional(),
  brandName: z.string().optional(),
  countryCode: z.string().optional(),
  currencyCode: z.string().optional(),
  deliveries: z.array(deliverySchema).optional(),
  gtin: z.string().optional(),
  imageUrl: z.string().optional(),
  link: z.string().optional(),
  name: z.string().optional(),
  price: z.number().optional(),
  productId: z.string().optional(),
  shopIcon: z.string().optional(),
  shopId: z.string().optional(),
  shopName: z.string().optional(),
  shopTechnicalName: z.string().optional(),
  stock: z.boolean().optional(),
  description: z.string().optional(),
  rankingsByCategoryId: z.record(z.any()).optional(),
  categories: z.record(z.any()).optional(),
  highestRank: z.number().optional(),
  numberOfUsedOffersPerMonth: z.record(z.any()).optional(),
  numberOfOffersPerMonth: z.record(z.any()).optional(),
  numberOfNewOffersPerMonth: z.record(z.any()).optional(),
  lastSalesCheck: z.date().optional(),
  isExpirationDatedProduct: z.boolean().optional(),
  sellerType: z.string(),
  titles: z.array(z.string()).optional(),
  modelName: z.string().optional(),
  estimatedSales: z.number().optional(),
  salesRank: z.number().optional(),
  marketplaceId: z.string().optional(),
  IsAmazonFulfilled: z.boolean().optional(),
  totalFeesEstimate: z.number().optional(),
  totalFeesEstimateCurrency: z.string().optional(),
  referralFees: z.number().optional(),
  referralFeesCurrency: z.string().optional(),
  variableClosingFee: z.number().optional(),
  variableClosingFeeCurrency: z.string().optional(),
  perItemFee: z.number().optional(),
  perItemFeeCurrency: z.string().optional(),
  fbaFees: z.number().optional(),
  fbaFeesCurrency: z.string().optional(),
  amazonPriceHT: z.number().optional(),
  supplierPriceHT: z.number().optional(),
  profit: z.number().optional(),
  profitMargin: z.number().optional(),
  roi: z.number().optional(),
  profitPerMonth: z.number().optional(),
  productSiteLaunchDate: z.date().optional(),
  lastUpdateDate: z.date().default(() => new Date()),
  supplier: objectIdSchema,
  supplierImgUrl: z.string().optional(),
  supplierPrice: z.number(),
  supplierProductName: z.string(),
  supplierUrl: z.string(),
  batchId: z.string().optional(),
  vtaRate: z.number().optional(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

// Type generation
export type Product = z.infer<typeof productSchema>;

// Partial type for updates
export type ProductUpdate = Partial<Product>;

// Type for creating a new product (required fields only)
export type ProductCreate = Pick<
  Product,
  'sellerType' | 'supplier' | 'supplierPrice' | 'supplierProductName' | 'supplierUrl'
> &
  Partial<
    Omit<
      Product,
      'sellerType' | 'supplier' | 'supplierPrice' | 'supplierProductName' | 'supplierUrl'
    >
  >;

// Validation functions
export const validateProduct = (data: unknown): Product => {
  return productSchema.parse(data);
};

export const validateProductUpdate = (data: unknown): ProductUpdate => {
  return productSchema.partial().parse(data);
};

export const validateProductCreate = (data: unknown): ProductCreate => {
  return productSchema
    .omit({
      createdAt: true,
      updatedAt: true,
    })
    .parse(data);
};
export class ProductsApiClient extends APIClient<Product> {
  constructor() {
    super('products');
  }

  public async searchProducts(body: {
    condition: object;
    paginationOption: {
      page: number;
      limit: number;
    };
    group: object;
    sort: object;
  }) {
    return this.post(`search`, body);
  }
}
