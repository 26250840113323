import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { useTranslation } from 'react-i18next';
import { AuthLayout } from '../components/Auth/AuthLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import * as apiClient from '../apiClient';

const resetPasswordSchema = z
  .object({
    password: z.string().min(6),
    confirmPassword: z.string().min(6),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  });

type ResetPasswordValues = z.infer<typeof resetPasswordSchema>;

export const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const id = searchParams.get('id');

  const handleSubmit = async (values: ResetPasswordValues) => {
    if (!token || !id) {
      toast.error(t('auth.resetPassword.invalidTokenOrId'));
      return;
    }

    try {
      await apiClient.auth.resetPassword({
        id: id as string,
        token,
        password: values.password,
      });
      toast.success(t('auth.resetPassword.success'));
      navigate('/login');
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
      console.error('Password reset error:', error);
    }
  };

  return (
    <AuthLayout>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={toFormikValidationSchema(resetPasswordSchema)}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="space-y-6">
            <div>
              <label className="mb-1 block text-sm font-medium text-gray-700">
                {t('auth.resetPassword.newPassword')} <span className="text-red-500">*</span>
              </label>
              <Field
                type="password"
                name="password"
                className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
              />
              <ErrorMessage name="password" component="div" className="mt-1 text-sm text-red-600" />
            </div>

            <div>
              <label className="mb-1 block text-sm font-medium text-gray-700">
                {t('auth.resetPassword.confirmPassword')} <span className="text-red-500">*</span>
              </label>
              <Field
                type="password"
                name="confirmPassword"
                className="w-full rounded-lg border border-gray-300 px-3 py-2 outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-200"
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                className="mt-1 text-sm text-red-600"
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full rounded-lg bg-orange-500 px-4 py-2 text-white hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-200 disabled:opacity-50"
            >
              {t('auth.resetPassword.submit')}
            </button>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  );
};
