import React from 'react';
import { Package, Rows3, Headphones, LayoutGrid, TextSearch, Store, Tag   } from 'lucide-react';
import { MarketplaceTooltip} from './MarketplaceTooltip'
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { MarketplaceSelector } from '../Marketplace/MarketplaceSelector';
import { useUser } from '../../contexts/UserContext';
import { Tooltip} from './Tooltip'
//import {  Tooltip } from "flowbite-react";
const NavItem: React.FC<{
  icon: React.ReactNode;
  label: string;
  to: string;
  isActive?: boolean;
  collapse: boolean;
}> = ({ icon, label, to, isActive, collapse }) => {
  const location = useLocation();
  const isCurrentActive = isActive ?? location.pathname === to;

  

  return (
    <Link
      to={to}
      className={`flex items-center gap-3 px-4 py-2 ${
        isCurrentActive ? 'text-orange-500' : 'text-gray-600'
      } mx-2 rounded-lg hover:bg-gray-50 relative`}
      
    >
    <Tooltip tooltipText={label}> <span className="text-orange">{icon}</span></Tooltip>  
      {!collapse && <span>{label}</span>}

     
    </Link>
  );
};

const NavGroup: React.FC<{ title: string; children: React.ReactNode , collapse :boolean }> = ({ title, children , collapse}) => {
  const { t } = useTranslation();
  return (
    <div className="mb-6">
      { !collapse && (
        <div className="mb-2 px-6">
        <span className="text-xs font-medium uppercase tracking-wider text-gray-400">
          {t(`sidebar.${title.toLowerCase()}`)}
        </span>
      </div>
      ) }
      <nav className="space-y-1">{children}</nav>
    </div>
  );
};

export const Navigation: React.FC<{ collapse: boolean }> = ({ collapse}) => {
  const { t } = useTranslation();
  const { isAdmin } = useUser();

  return (
    <div className="sidebar-step flex flex-1 flex-col pt-4">
      <div className="mb-6 px-4">
      {collapse ? <MarketplaceTooltip /> : <MarketplaceSelector />}
      </div>

      <NavGroup title="ACTIVITY" collapse ={collapse}>
        <NavItem icon={<LayoutGrid size={20} />} label={t('sidebar.dashboard')} to="/dashboard" collapse ={collapse} />
      </NavGroup>

      <NavGroup title="PRODUCTS" collapse ={collapse}>
        <NavItem icon={<Package size={20} />} label={t('sidebar.findProduct')} to="/"  collapse ={collapse}/>
        <NavItem
          icon={<TextSearch size={20} />}
          label={t('sidebar.reverseSearch')}
          to="/reverse-search"
          collapse ={collapse}
        />
        <NavItem icon={<Rows3 size={20} />} label={t('sidebar.scanList')} to="/scan-list" collapse ={collapse} />
      </NavGroup>

      <NavGroup title="SUPPLIERS" collapse ={collapse}>
        <NavItem icon={<Tag size={20} />} label={t('sidebar.suppliers')} to="/suppliers" collapse ={collapse} />
        {isAdmin && (
          <NavItem
            icon={<Store size={20} />}
            label={t('sidebar.affilizSuppliers')}
            to="/affiliz-suppliers" 
            collapse ={collapse}
          />
        )}
      </NavGroup>

      <NavGroup title="CONTACT" collapse ={collapse}>
        <NavItem icon={<Headphones size={20} />} label={t('sidebar.support')} to="/support" collapse ={collapse} />
      </NavGroup>
    </div>
  );
};
