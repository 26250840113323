import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import  * as apiClient from '../apiClient' 
import toastr from 'toastr'

export const SupportForm = () => {
  const options = [
    "Donner un feedback",
    "Signaler un problème",
    "Produit hors stock",
    "Calcul incorrect",
    "Autres"
  ];

  const validationSchema = Yup.object({
    option: Yup.string().required('Veuillez Choisir Une Option'),
    firstName: Yup.string().required('Le prénom est obligatoire'),
    lastName: Yup.string().required('Le nom est obligatoire'),
    email: Yup.string().email('Email invalide').required('L\'email est obligatoire'),
    message: Yup.string().required('Le message est obligatoire'),
  });


  const submitSupportForm = async (values: {email: string; message: string ; firstName: string ;lastName: string ;option: string; }) => {
    
    try { 
        const fullMessage = `${values.option}: ${values.message}`;
      await apiClient.auth.contactSupport({
        email: values.email,
        message: fullMessage,
        firstName: values.firstName,
        lastName: values.lastName, 
      }); 
      
      toastr.success('Message envoyé avec succès');
    } catch (error) { 
        console.error('Error:', error);
        toastr.error('Une erreur est survenue lors de l\'envoi du message');
    }
  };
  

  return (
    <div className="max-w-2xl mx-auto p-4 bg-white shadow-md rounded-md">
      <h1 className="text-2xl font-bold mb-6">Support</h1>
      <Formik
        initialValues={{firstName: '', lastName: '' , option: '',  email: '', message: '' }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
            console.log('Mes datas soumis ==>:', values);
            submitSupportForm(values).then(() => resetForm());
          }}
      >
        {({ isSubmitting }) => (
          <Form className="space-y-6">
            <div>
              <label htmlFor="option" className="block text-sm font-medium mb-2">Choisissez une option</label>
              <Field as="select" name="option" className="mt-1 block w-full border border-gray-300 focus:outline-none focus:ring-1 focus:ring-orange-500 rounded-md shadow-sm  hover:border-black py-2 px-2">
                <option value="">-- Sélectionnez --</option>
                {options.map((opt, index) => (
                  <option key={index} value={opt}>{opt}</option>
                ))}
              </Field>
              <ErrorMessage name="option" component="div" className="text-red-500 text-sm mt-1" />
            </div>
                <div>
                        <label htmlFor="firstName" className="block text-sm font-medium mb-2">Prénom</label>
                        <Field type="text" name="firstName" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-orange-500 hover:border-black py-2 px-2" />
                        <ErrorMessage name="firstName" component="div" className="text-red-500 text-sm mt-1" />
           </div>
            <div>
                    <label htmlFor="lastName" className="block text-sm font-medium mb-2">Nom</label>
                    <Field type="text" name="lastName" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-orange-500 hover:border-black py-2 px-2" />
                    <ErrorMessage name="lastName" component="div" className="text-red-500 text-sm mt-1" />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium mb-2">Email</label>
              <Field type="email" name="email" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-orange-500 hover:border-black py-2 px-2" />
              <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
            </div>
           
            <div>
              <label htmlFor="message" className="block text-sm font-medium mb-2">Message</label>
              <Field as="textarea" name="message" rows={4} className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-orange-500  hover:border-black py-2 px-2" />
              <ErrorMessage name="message" component="div" className="text-red-500 text-sm mt-1" />
            </div>
            <div>
              <button type="submit" disabled={isSubmitting} className="w-full bg-orange-500 text-white py-3 px-4 rounded-md shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500">
                Envoyer
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
