import { z } from 'zod';

export const UserRole = {
  ADMIN: 'ADMIN',
  USER: 'USER',
  TESTER: 'TESTER',
} as const;

export const Plan = {
  FREE: 'FREE',
  BASIC: 'BASIC',
  PREMIUM: 'PREMIUM',
} as const;

export const PlanStatus = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  CANCELED: 'canceled',
  TRIALING: 'trialing',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
} as const;

export type TUserRole = (typeof UserRole)[keyof typeof UserRole];
export type TPlan = (typeof Plan)[keyof typeof Plan];
export type TPlanStatus = (typeof PlanStatus)[keyof typeof PlanStatus];

export const userSchema = z.object({
  _id: z.string(),
  email: z.string().email(),
  password: z.string().optional(),
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  companyName: z.string().optional(),
  companyId: z.string().optional(),
  phoneNumber: z.string().optional(),
  address: z.string().optional(),
  city: z.string().optional(),
  zipCode: z.string().optional(),
  country: z.string().optional(),
  stripeCustomerId: z.string().optional(),
  createdAt: z.date(),
  roles: z.array(z.enum([UserRole.ADMIN, UserRole.USER, UserRole.TESTER])),
  planStatus: z.enum([
    PlanStatus.ACTIVE,
    PlanStatus.INACTIVE,
    PlanStatus.CANCELED,
    PlanStatus.TRIALING,
    PlanStatus.PAST_DUE,
    PlanStatus.UNPAID,
  ]),
  plan: z.enum([Plan.FREE, Plan.BASIC, Plan.PREMIUM]),
  planStartAt: z.date(),
  planEndAt: z.date(),
  isSellerApiConnected: z.boolean(),
  hasBasicPlan: z.boolean(),
  hasPremiumPlan: z.boolean(),
  didOnboarding: z.boolean(),
});

export type TUser = z.infer<typeof userSchema>;

export interface TUserContextType {
  user: TUser | null;
  setUser: (user: TUser | null) => void;
  isAdmin: boolean;
  switchToUser: (userId: string, callback?: () => void) => Promise<void>;
  switchBackToAdmin: (callback?: () => void) => Promise<void>;
  swichModeActive: boolean;
  hasBasicPlan: boolean;
  hasProPlan: boolean;
}

export interface Invoice {
  id: string;
  name: string;
  amount: number;
  createdAt: Date;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  amazonSellerId?: string;
  plan: TPlan;
  planStartAt: Date;
  planEndAt: Date;
  invoices: Invoice[];
  stripeCustomerId: string;
  planStatus: TPlanStatus;
  hasBasicPlan: boolean;
  hasPremiumPlan: boolean;
}
