import React from 'react';
import { useTranslation } from 'react-i18next';
import { TrendingUp } from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

interface ProductChartProps {
  title: string;
  data: any[];
  dataKey: string;
  color: string;
}

export const ProductChart: React.FC<ProductChartProps> = ({ title, data, dataKey, color }) => {
  const { t } = useTranslation();

  return (
    <div className="rounded-lg bg-white p-6 shadow-sm">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="font-semibold">{t(title)}</h2>
        <TrendingUp className={`text-${color}-500`} size={20} />
      </div>
      <div className="h-[200px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey={dataKey}
              stroke={`#${color === 'green' ? '10b981' : color === 'blue' ? '3b82f6' : 'f97316'}`}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
