import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from 'axios';
import { getToken } from './token.service';
import { API_URL } from '../constants/constants';

interface User {
  id: string;
  email: string;
  [key: string]: unknown;
}

// Add these type definitions
type ApiResponse<T = Record<string, unknown>> = {
  data: T;
  status: number;
  message?: string;
};

// default
axios.defaults.baseURL = API_URL || 'http://localhost:9000';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
// content type
// let authUser: any = (localStorage.getItem("authUser"));

// intercepting to capture errors
axios.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    const token = getToken();
    if (config.headers) {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
    }
    return config;
  },
  function (error: AxiosError) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response: AxiosResponse) {
    return response.data ? response.data : response;
  },
  function (error: AxiosError) {
    let message: string;
    switch (error?.response?.status) {
      case 500:
        message = 'Internal Server Error';
        break;
      case 401:
        message = 'Invalid credentials';
        break;
      case 404:
        message = 'Sorry! the data you are looking for could not be found';
        break;
      default:
        message =
          (error.response?.data as ApiResponse)?.message || error.message || 'Unknown error';
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

/**
 * API Client
 */

/**
 * API Client
 */
export type TParams<T> = Partial<T & { limit: number }>;

class APIClient<T> {
  private baseUrl: string;

  constructor(baseUrl: string = '') {
    this.baseUrl = baseUrl;
  }
  /**
   * Fetches data from given url
   */

  //  get = (this.getUrl(url), params) => {
  //   return axios.get(this.getUrl(url), params);
  // };
  getUrl = (url: string) => {
    return `${this.baseUrl ? `${this.baseUrl}/` : ''}${url}`;
  };

  get = (url: string, params?: TParams<T>): Promise<AxiosResponse<T | T[]>> => {
    const config: AxiosRequestConfig = { params };
    return axios.get(this.getUrl(url), config);
  };

  fetch = (url: string, params: TParams<T>) => {
    return this.get(this.getUrl(url), params);
  };

  findByConditions = (url: string, data: Record<string, unknown>) => {
    return axios.post(this.getUrl(url), data);
  };

  /**
   * post given data to url
   */
  create = (url: string, data: Partial<T>) => {
    return axios.post(this.getUrl(url), data);
  };

  post = (
    url: string,
    data: Partial<T> | object,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> => {
    return axios.post(this.getUrl(url), data, config);
  };

  /**
   * Updates data
   */
  update = (url: string, data: Partial<T>) => {
    return axios.patch(this.getUrl(url), data);
  };

  put = (url: string, data: Partial<T>) => {
    return axios.put(this.getUrl(url), data);
  };

  /**
   * Delete
   */
  delete = (url: string, config?: AxiosRequestConfig) => {
    return axios.delete(this.getUrl(url), config);
  };
}

const getLoggedinUser = (): User | null => {
  const user = localStorage.getItem('authUser');
  if (!user) {
    return null;
  }
  return JSON.parse(user) as User;
};

export { APIClient, setAuthorization, getLoggedinUser };
